import {Router} from "@angular/router";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Template, TemplateConfigs} from "@app/shared/interfaces";
import {NzUploadComponent, NzUploadFile} from "ng-zorro-antd/upload";
import {cloneObject, extractColorNumber, getFileExtension} from "@app/shared/helpers";
import {Component, DestroyRef, ElementRef, inject, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  AnalyticsService,
  AppStateService,
  AuthService,
  TemplateService,
  UploadExistingCvService
} from "@app/shared/services";

@Component({
  selector: 'sf-tm-buttons',
  templateUrl: './tm-buttons.component.html',
  styleUrl: './tm-buttons.component.scss',
  standalone: true,
  imports: [
    NzUploadComponent,
    NzSpinComponent,
    TranslateModule
  ]
})
export class TmButtonsComponent {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  public readonly authService = inject(AuthService);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly uploadExistingCvService = inject(UploadExistingCvService);

  @Input({required: true}) template!: Template;

  @ViewChild('UploadCVCheckModalRef', {static: true}) public uploadCVCheckModalRef!: TemplateRef<ElementRef<HTMLDivElement>>;

  constructor() {
  }

  /** Template Upload Logic Start */

  protected beforeUploadCurrent = (template: Template) => {
    return (file: NzUploadFile) => {
      // Add file validation here

      this.analyticsService.track("TMButtons Upload Modal opened", {
        fileType: file.type
      });

      if (this.authService.isAuthenticated) {
        const fileName = file.name;
        const fileType = file.type; // Mime type e.g. 'application/pdf' or 'text/plain'
        const fileExtension = getFileExtension(fileName);

        switch (true) {
          case fileType === 'application/pdf' || fileExtension === 'pdf': {
            try {
              this.uploadExistingCvService.openUploadCvBanner(this.uploadCVCheckModalRef);
              this.getPdfUrlFromFile(file, template);
            } catch (err) {
              this.uploadExistingCvService.uploadCvFailed();
            }
            break;
          }
          case fileType === 'text/plain' || fileExtension === 'txt': {
            try {
              this.uploadExistingCvService.openUploadCvBanner(this.uploadCVCheckModalRef);
              this.onTxtFileSelected(file, template);
            } catch (err) {
              this.uploadExistingCvService.uploadCvFailed();
            }
            break;
          }
          case fileType === 'application/msword' || fileExtension === 'doc':
          case fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileExtension === 'docx': {
            try {
              this.uploadExistingCvService.openUploadCvBanner(this.uploadCVCheckModalRef);
              this.on_DOCX_FileSelected(file, template);
            } catch (err) {
              this.uploadExistingCvService.uploadCvFailed();
            }
            break
          }
          default: {
            this.nzMessageService.error(
              this.translateService.instant('project_messages.unsupported_file_type')
            );
          }
        }

      } else {
        this.router.navigate(['auth'], {
          queryParams: {returnUrl: 'cv-templates'}
        });
      }

      return false;
    }

  };

  private getPdfUrlFromFile(file: NzUploadFile, template: Template) {
    const templateCopy = this.setTmActions(file, template);

    this.uploadExistingCvService.getPdfUrlFromFile(file)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.uploadExistingCvService.applyUploadedCv(res.uploadedCV, templateCopy.settings as TemplateConfigs);
          this.onAddNewTemplate_for_upload(templateCopy, res.pagesCount);
        },
        error: () => {
          this.uploadExistingCvService.uploadCvFailed();
        }
      });
  }

  protected onAddNewTemplate_for_upload(template: Template, pagesCount: number) {
    this.analyticsService.track("Create New From Uploaded CV", {
      userId: this.appStateService.user?.id,
      templateId: template.templateId
    });

    this.templateService.addTemplate_for_upload(template)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.router.navigate(['documents', res.documentId], {queryParams: {pagesCount}});
          this.uploadExistingCvService.uploadFinished();
        },
        error: () => {
          this.uploadExistingCvService.uploadCvFailed();
        }
      });
  }

  /** Txt file selected */
  private onTxtFileSelected(file: NzUploadFile, template: Template) {
    const templateCopy = this.setTmActions(file, template);

    this.upload_txt_docx(file);

    this.uploadExistingCvService.on_Txt_FileSelected(file)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.uploadExistingCvService.applyUploadedCv(res.uploadedCV, templateCopy.settings as TemplateConfigs);
          this.onAddNewTemplate_for_upload(templateCopy, res.pagesCount);
        },
        error: () => {
          this.uploadExistingCvService.uploadCvFailed();
        }
      });
  }

  /** .docx file */
  private on_DOCX_FileSelected(file: NzUploadFile, template: Template) {
    const templateCopy = this.setTmActions(file, template);

    this.upload_txt_docx(file);

    this.uploadExistingCvService.on_DOCX_FileSelected(file)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.uploadExistingCvService.applyUploadedCv(res.uploadedCV, templateCopy.settings as TemplateConfigs);
          this.onAddNewTemplate_for_upload(templateCopy, res.pagesCount);
        },
        error: () => {
          this.uploadExistingCvService.uploadCvFailed();
        }
      });
  }

  private setTmActions(file: NzUploadFile, template: Template) {
    const templateCopy = cloneObject(template);

    templateCopy.settings.settings.theme.colorId = this.appStateService.getTmColors(template)[(extractColorNumber(template.src)! - 1) || 0].id;
    templateCopy.title = file.name.replace('.pdf', '');

    this.uploadExistingCvService.changeLoaderMessages();

    return templateCopy;
  }

  private upload_txt_docx(file: NzUploadFile) {
    this.uploadExistingCvService.getPdfUrlFromFile(file)
      .subscribe();
  }

  /** Template Upload Logic End */
}

