import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFilter',
  standalone: true
})
export class AppFilterPipe implements PipeTransform {

  transform<T = any[]>(arr: T[], val: string, filterBy = 'name'): T[] {
    return (arr as any[]).filter((item) => item[filterBy].includes(val));
  }

}
