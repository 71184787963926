import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  inject,
  Injectable,
  Injector
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OldFactoryService {
  private readonly injector = inject(Injector);
  private readonly applicationRef = inject(ApplicationRef);
  private readonly componentFactoryResolver = inject(ComponentFactoryResolver);

  constructor() {
  }

  public create(component: any, configs?: any) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    this.attachConfig(configs, componentRef);

    this.applicationRef.attachView(componentRef.hostView);

    const componentDomElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

    return {componentRef, domElem: componentDomElem, instance: componentRef.instance}
  }

  private attachConfig(configs: any, componentRef: ComponentRef<any> | null): void {
    const inputs = configs?.inputs || {};
    const outputs = configs?.outputs || {};
    // @ts-ignore
    Object.keys(inputs).forEach((key: string) => componentRef.instance[key] = inputs[key]);
    // @ts-ignore
    Object.keys(outputs).forEach((key: string) => componentRef.instance[key] = outputs[key]);
  }

  public destroyComponent(componentRef: ComponentRef<any> | null): void {
    if (componentRef) {
      this.applicationRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }
  }

}
