// Generated by CoffeeScript 1.12.7
(function () {
  var XMLNode,
    XMLText,
    extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    hasProp = {}.hasOwnProperty;
  XMLNode = require('./XMLNode');
  module.exports = XMLText = function (superClass) {
    extend(XMLText, superClass);
    function XMLText(parent, text) {
      XMLText.__super__.constructor.call(this, parent);
      if (text == null) {
        throw new Error("Missing element text. " + this.debugInfo());
      }
      this.value = this.stringify.eleText(text);
    }
    XMLText.prototype.clone = function () {
      return Object.create(this);
    };
    XMLText.prototype.toString = function (options) {
      return this.options.writer.set(options).text(this);
    };
    return XMLText;
  }(XMLNode);
}).call(this);