import {SingleTemplateThemeColor} from "@app/shared/interfaces";

export const template1Themes: SingleTemplateThemeColor = {
  theme1: {
    id: 1,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#4B57DC',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#4B57DC',
    subTitleFontEditModeColor: '#4B57DC',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#4B57DC',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#2A3958',
      fontColor: '#ffffff',
      borderColor: '#2A3958',
    },

    sidebar: {
      bgColor: '#2A3958',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#2A3958',
        borderRightColor: '#2A3958',
        borderTopColor: '#2A3958',
        borderBottomColor: '#2A3958',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#A4ABF0',
      sectionSubtitleEditModeFontColor: '#2A3958',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#A4ABF0'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4B57DC',
        borderColor: '#4B57DC',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4B57DC',
        borderColor: '#4B57DC',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4B57DC',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(194,197,203,.2)',
      btnBgColor: '#4B57DC',
      btnBorderColor: '#4B57DC'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A3958',
    demoColor2: '#4B57DC',

    linkColor: '#4B57DC'
  },
  theme2: {
    id: 2,
    paid: true,


    fontColor: '#303030',
    emptyImgColor: '#69748B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#69748B',
    subTitleFontEditModeColor: '#69748B',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#69748B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#374259',
      fontColor: '#fff',
      borderColor: '#374259',
    },

    sidebar: {
      bgColor: '#374259',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#374259',
        borderRightColor: '#374259',
        borderTopColor: '#374259',
        borderBottomColor: '#374259',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#CDD8EF',
      sectionSubtitleEditModeFontColor: '#374259',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#CDD8EF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#69748B',
        borderColor: '#69748B',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#69748B',
        borderColor: '#69748B',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#69748B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#69748B',
          borderColor: '#69748B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#69748B',
          borderColor: '#69748B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#69748B',
        borderColor: '#69748B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(105,116,139,.2)',
      btnBgColor: '#69748B',
      btnBorderColor: '#69748B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#69748B'
  },
  theme3: {
    id: 3,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#647982',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#647982',
    subTitleFontEditModeColor: '#647982',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#647982',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#324750',
      fontColor: '#fff',
      borderColor: '#324750',
    },

    sidebar: {
      bgColor: '#324750',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#324750',
        borderRightColor: '#324750',
        borderTopColor: '#324750',
        borderBottomColor: '#324750',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#BED3DC',
      sectionSubtitleEditModeFontColor: '#324750',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#BED3DC'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#647982',
        borderColor: '#647982',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#647982',
        borderColor: '#647982',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#647982',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#647982',
        borderColor: '#647982',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(100,121,130,.2)',
      btnBgColor: '#647982',
      btnBorderColor: '#647982'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#808B8F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#808B8F',
    subTitleFontEditModeColor: '#808B8F',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#808B8F',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#4E595D',
      fontColor: '#fff',
      borderColor: '#4E595D',
    },

    sidebar: {
      bgColor: '#4E595D',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#4E595D',
        borderRightColor: '#4E595D',
        borderTopColor: '#4E595D',
        borderBottomColor: '#4E595D',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E4EFF3',
      sectionSubtitleEditModeFontColor: '#4E595D',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#E4EFF3'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#808B8F',
        borderColor: '#808B8F',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#808B8F',
        borderColor: '#808B8F',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#808B8F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#808B8F',
          borderColor: '#808B8F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#808B8F',
          borderColor: '#808B8F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#808B8F',
        borderColor: '#808B8F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(128,139,143,.2)',
      btnBgColor: '#808B8F',
      btnBorderColor: '#808B8F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#808B8F'
  },
  theme5: {
    id: 5,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#828293',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#828293',
    subTitleFontEditModeColor: '#828293',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#828293',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#505061',
      fontColor: '#fff',
      borderColor: '#505061',
    },

    sidebar: {
      bgColor: '#505061',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#505061',
        borderRightColor: '#505061',
        borderTopColor: '#505061',
        borderBottomColor: '#505061',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E6E6F7',
      sectionSubtitleEditModeFontColor: '#505061',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#E6E6F7'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#828293',
        borderColor: '#828293',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#828293',
        borderColor: '#828293',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#828293',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#828293',
        borderColor: '#828293',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(130,130,147,.2)',
      btnBgColor: '#828293',
      btnBorderColor: '#828293'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#505061',
    demoColor2: '#828293',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#A49393',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#A49393',
    subTitleFontEditModeColor: '#A49393',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#A49393',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#5B5054',
      fontColor: '#fff',
      borderColor: '#5B5054',
    },

    sidebar: {
      bgColor: '#5B5054',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#5B5054',
        borderRightColor: '#5B5054',
        borderTopColor: '#5B5054',
        borderBottomColor: '#5B5054',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E3C4E3',
      sectionSubtitleEditModeFontColor: '#5B5054',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#E3C4E3'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#A49393',
        borderColor: '#A49393',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#A49393',
        borderColor: '#A49393',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#A49393',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#A49393',
          borderColor: '#A49393',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#A49393',
          borderColor: '#A49393',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#A49393',
        borderColor: '#A49393',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(164,147,147,.2)',
      btnBgColor: '#A49393',
      btnBorderColor: '#A49393'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#A49393'
  },
  theme7: {
    id: 7,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#43779F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#43779F',
    subTitleFontEditModeColor: '#43779F',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#43779F',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#1B4F77',
      fontColor: '#fff',
      borderColor: '#1B4F77',
    },

    sidebar: {
      bgColor: '#1B4F77',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#1B4F77',
        borderRightColor: '#1B4F77',
        borderTopColor: '#1B4F77',
        borderBottomColor: '#1B4F77',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#B1E5FF',
      sectionSubtitleEditModeFontColor: '#1B4F77',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#B1E5FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#43779F',
        borderColor: '#43779F',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#43779F',
        borderColor: '#43779F',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#43779F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(67,119,159,.2)',
      btnBgColor: '#43779F',
      btnBorderColor: '#43779F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1B4F77',
    demoColor2: '#43779F',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#2983BA',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#2983BA',
    subTitleFontEditModeColor: '#2983BA',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#2983BA',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#005188',
      fontColor: '#fff',
      borderColor: '#005188',
    },

    sidebar: {
      bgColor: '#005188',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#005188',
        borderRightColor: '#005188',
        borderTopColor: '#005188',
        borderBottomColor: '#005188',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#97F1FF',
      sectionSubtitleEditModeFontColor: '#005188',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#97F1FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#2983BA',
        borderColor: '#2983BA',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2983BA',
        borderColor: '#2983BA',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2983BA',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2983BA',
          borderColor: '#2983BA',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2983BA',
          borderColor: '#2983BA',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#2983BA',
        borderColor: '#2983BA',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(41,131,186,.2)',
      btnBgColor: '#2983BA',
      btnBorderColor: '#2983BA'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#2983BA'
  },
  theme9: {
    id: 9,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#6C6EA5',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#6C6EA5',
    subTitleFontEditModeColor: '#6C6EA5',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#6C6EA5',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#3A3C73',
      fontColor: '#fff',
      borderColor: '#3A3C73',
    },

    sidebar: {
      bgColor: '#3A3C73',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#3A3C73',
        borderRightColor: '#3A3C73',
        borderTopColor: '#3A3C73',
        borderBottomColor: '#3A3C73',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#C6C8FF',
      sectionSubtitleEditModeFontColor: '#3A3C73',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#C6C8FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#6C6EA5',
        borderColor: '#6C6EA5',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#6C6EA5',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#6C6EA5',
          borderColor: '#6C6EA5',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#6C6EA5',
          borderColor: '#6C6EA5',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(108,110,165,.2)',
      btnBgColor: '#6C6EA5',
      btnBorderColor: '#6C6EA5'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#6C6EA5'
  },
  theme10: {
    id: 10,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5A79C7',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#5A79C7',
    subTitleFontEditModeColor: '#5A79C7',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#5A79C7',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#39559A',
      fontColor: '#fff',
      borderColor: '#39559A',
    },

    sidebar: {
      bgColor: '#39559A',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#39559A',
        borderRightColor: '#39559A',
        borderTopColor: '#39559A',
        borderBottomColor: '#39559A',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#D2F1FF',
      sectionSubtitleEditModeFontColor: '#39559A',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#D2F1FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#5A79C7',
        borderColor: '#5A79C7',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5A79C7',
        borderColor: '#5A79C7',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5A79C7',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(90,121,199,.2)',
      btnBgColor: '#5A79C7',
      btnBorderColor: '#5A79C7'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#39559A',
    demoColor2: '#5A79C7',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#516D92',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#516D92',
    subTitleFontEditModeColor: '#516D92',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#516D92',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#1F3B60',
      fontColor: '#fff',
      borderColor: '#1F3B60',
    },

    sidebar: {
      bgColor: '#1F3B60',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#1F3B60',
        borderRightColor: '#1F3B60',
        borderTopColor: '#1F3B60',
        borderBottomColor: '#1F3B60',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#B5D1F6',
      sectionSubtitleEditModeFontColor: '#1F3B60',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#B5D1F6'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#516D92',
        borderColor: '#516D92',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#516D92',
        borderColor: '#516D92',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#516D92',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(81,109,146,.2)',
      btnBgColor: '#516D92',
      btnBorderColor: '#516D92'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1F3B60',
    demoColor2: '#516D92',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3C7680',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#3C7680',
    subTitleFontEditModeColor: '#3C7680',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#3C7680',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#0A444E',
      fontColor: '#fff',
      borderColor: '#0A444E',
    },

    sidebar: {
      bgColor: '#0A444E',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#0A444E',
        borderRightColor: '#0A444E',
        borderTopColor: '#0A444E',
        borderBottomColor: '#0A444E',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#A0DAE4',
      sectionSubtitleEditModeFontColor: '#0A444E',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#A0DAE4'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#3C7680',
        borderColor: '#3C7680',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3C7680',
        borderColor: '#3C7680',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3C7680',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(60,118,128,.2)',
      btnBgColor: '#3C7680',
      btnBorderColor: '#3C7680'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#0A444E',
    demoColor2: '#3C7680',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#527F7A',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#527F7A',
    subTitleFontEditModeColor: '#527F7A',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#527F7A',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#2A5752',
      fontColor: '#fff',
      borderColor: '#2A5752',
    },

    sidebar: {
      bgColor: '#2A5752',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#2A5752',
        borderRightColor: '#2A5752',
        borderTopColor: '#2A5752',
        borderBottomColor: '#2A5752',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#C0EDE8',
      sectionSubtitleEditModeFontColor: '#2A5752',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#C0EDE8'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#527F7A',
        borderColor: '#527F7A',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#527F7A',
        borderColor: '#527F7A',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#527F7A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#527F7A',
          borderColor: '#527F7A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#527F7A',
          borderColor: '#527F7A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#527F7A',
        borderColor: '#527F7A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(82,127,122,.2)',
      btnBgColor: '#527F7A',
      btnBorderColor: '#527F7A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#527F7A'
  },
  theme14: {
    id: 14,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#519097',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#519097',
    subTitleFontEditModeColor: '#519097',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#519097',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#29686F',
      fontColor: '#fff',
      borderColor: '#29686F',
    },

    sidebar: {
      bgColor: '#29686F',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#29686F',
        borderRightColor: '#29686F',
        borderTopColor: '#29686F',
        borderBottomColor: '#29686F',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#C9FFFF',
      sectionSubtitleEditModeFontColor: '#29686F',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#C9FFFF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#519097',
        borderColor: '#519097',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#519097',
        borderColor: '#519097',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#519097',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#519097',
        borderColor: '#519097',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(81,144,151,.2)',
      btnBgColor: '#519097',
      btnBorderColor: '#519097'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#29686F',
    demoColor2: '#519097',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#728686',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#728686',
    subTitleFontEditModeColor: '#728686',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#728686',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#405454',
      fontColor: '#fff',
      borderColor: '#405454',
    },

    sidebar: {
      bgColor: '#405454',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#405454',
        borderRightColor: '#405454',
        borderTopColor: '#405454',
        borderBottomColor: '#405454',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#D6EAEA',
      sectionSubtitleEditModeFontColor: '#405454',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#D6EAEA'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#728686',
        borderColor: '#728686',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#728686',
        borderColor: '#728686',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#728686',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#728686',
        borderColor: '#728686',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(114,134,134,.2)',
      btnBgColor: '#728686',
      btnBorderColor: '#728686'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#405454',
    demoColor2: '#728686',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#7E8A7B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#7E8A7B',
    subTitleFontEditModeColor: '#7E8A7B',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#7E8A7B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#606C5D',
      fontColor: '#fff',
      borderColor: '#606C5D',
    },

    sidebar: {
      bgColor: '#606C5D',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#606C5D',
        borderRightColor: '#606C5D',
        borderTopColor: '#606C5D',
        borderBottomColor: '#606C5D',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#ECF8E9',
      sectionSubtitleEditModeFontColor: '#606C5D',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#ECF8E9'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#7E8A7B',
        borderColor: '#7E8A7B',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#7E8A7B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#7E8A7B',
          borderColor: '#7E8A7B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#7E8A7B',
          borderColor: '#7E8A7B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(126,138,123,.2)',
      btnBgColor: '#7E8A7B',
      btnBorderColor: '#7E8A7B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B',

    linkColor: '#7E8A7B'
  },
  theme17: {
    id: 17,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#ACCEC8',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#ACCEC8',
    subTitleFontEditModeColor: '#ACCEC8',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#ACCEC8',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#2B2E35',
      fontColor: '#fff',
      borderColor: '#2B2E35',
    },

    sidebar: {
      bgColor: '#2B2E35',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#2B2E35',
        borderRightColor: '#2B2E35',
        borderTopColor: '#2B2E35',
        borderBottomColor: '#2B2E35',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#ECF8E9',
      sectionSubtitleEditModeFontColor: '#2B2E35',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#ECF8E9'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#ACCEC8',
        borderColor: '#ACCEC8',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#ACCEC8',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#ACCEC8',
          borderColor: '#ACCEC8',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#ACCEC8',
          borderColor: '#ACCEC8',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(172,206,200,.2)',
      btnBgColor: '#ACCEC8',
      btnBorderColor: '#ACCEC8'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2B2E35',
    demoColor2: '#ACCEC8',

    linkColor: '#ACCEC8'
  },
  theme18: {
    id: 18,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#908071',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#908071',
    subTitleFontEditModeColor: '#908071',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#908071',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#5E4E3F',
      fontColor: '#fff',
      borderColor: '#5E4E3F',
    },

    sidebar: {
      bgColor: '#5E4E3F',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#5E4E3F',
        borderRightColor: '#5E4E3F',
        borderTopColor: '#5E4E3F',
        borderBottomColor: '#5E4E3F',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#F4E4D5',
      sectionSubtitleEditModeFontColor: '#5E4E3F',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#F4E4D5'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#908071',
        borderColor: '#908071',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#908071',
        borderColor: '#908071',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#908071',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#908071',
        borderColor: '#908071',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(144,128,113,.2)',
      btnBgColor: '#908071',
      btnBorderColor: '#908071'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5E4E3F',
    demoColor2: '#908071',

    linkColor: '#908071'
  },
  theme19: {
    id: 19,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8E6A64',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#8E6A64',
    subTitleFontEditModeColor: '#8E6A64',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8E6A64',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#66423C',
      fontColor: '#fff',
      borderColor: '#66423C',
    },

    sidebar: {
      bgColor: '#66423C',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#66423C',
        borderRightColor: '#66423C',
        borderTopColor: '#66423C',
        borderBottomColor: '#66423C',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#FFE2DC',
      sectionSubtitleEditModeFontColor: '#66423C',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#FFE2DC'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8E6A64',
        borderColor: '#8E6A64',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8E6A64',
        borderColor: '#8E6A64',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8E6A64',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(142,106,100,.2)',
      btnBgColor: '#8E6A64',
      btnBorderColor: '#8E6A64'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#66423C',
    demoColor2: '#8E6A64',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#7F536B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#7F536B',
    subTitleFontEditModeColor: '#7F536B',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#7F536B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#572B43',
      fontColor: '#fff',
      borderColor: '#572B43',
    },

    sidebar: {
      bgColor: '#572B43',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#572B43',
        borderRightColor: '#572B43',
        borderTopColor: '#572B43',
        borderBottomColor: '#572B43',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#EDC1D9',
      sectionSubtitleEditModeFontColor: '#572B43',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#EDC1D9'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7F536B',
        borderColor: '#7F536B',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#7F536B',
        borderColor: '#7F536B',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#7F536B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#7F536B',
          borderColor: '#7F536B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#7F536B',
          borderColor: '#7F536B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7F536B',
        borderColor: '#7F536B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(127,83,107,.2)',
      btnBgColor: '#7F536B',
      btnBorderColor: '#7F536B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#7F536B'
  },
  theme21: {
    id: 21,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#626262',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#626262',
    subTitleFontEditModeColor: '#626262',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#626262',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#303030',
      fontColor: '#fff',
      borderColor: '#303030',
    },

    sidebar: {
      bgColor: '#303030',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#303030',
        borderRightColor: '#303030',
        borderTopColor: '#303030',
        borderBottomColor: '#303030',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#C6C6C6',
      sectionSubtitleEditModeFontColor: '#303030',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#C6C6C6'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#626262',
        borderColor: '#626262',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#626262',
        borderColor: '#626262',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#626262',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#626262',
        borderColor: '#626262',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(98,98,98,.2)',
      btnBgColor: '#626262',
      btnBorderColor: '#626262'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#626262',

    linkColor: '#626262'
  },
  theme22: {
    id: 22,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#BE6A9A',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#BE6A9A',
    subTitleFontEditModeColor: '#BE6A9A',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#BE6A9A',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#8C3868',
      fontColor: '#fff',
      borderColor: '#8C3868',
    },

    sidebar: {
      bgColor: '#8C3868',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#8C3868',
        borderRightColor: '#8C3868',
        borderTopColor: '#8C3868',
        borderBottomColor: '#8C3868',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#FFE2FF',
      sectionSubtitleEditModeFontColor: '#8C3868',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#FFE2FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#BE6A9A',
        borderColor: '#BE6A9A',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#BE6A9A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(190,106,154,.2)',
      btnBgColor: '#BE6A9A',
      btnBorderColor: '#BE6A9A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C3868',
    demoColor2: '#BE6A9A',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#9D6C88',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#9D6C88',
    subTitleFontEditModeColor: '#9D6C88',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#9D6C88',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#6B3A56',
      fontColor: '#fff',
      borderColor: '#6B3A56',
    },

    sidebar: {
      bgColor: '#6B3A56',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#6B3A56',
        borderRightColor: '#6B3A56',
        borderTopColor: '#6B3A56',
        borderBottomColor: '#6B3A56',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#FFDAF6',
      sectionSubtitleEditModeFontColor: '#6B3A56',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#FFDAF6'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9D6C88',
        borderColor: '#9D6C88',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#9D6C88',
        borderColor: '#9D6C88',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#9D6C88',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#9D6C88',
          borderColor: '#9D6C88',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#9D6C88',
          borderColor: '#9D6C88',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9D6C88',
        borderColor: '#9D6C88',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(157,108,136,.2)',
      btnBgColor: '#9D6C88',
      btnBorderColor: '#9D6C88'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#9D6C88'
  },
  theme24: {
    id: 24,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#E0589E',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#E0589E',
    subTitleFontEditModeColor: '#E0589E',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#E0589E',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#B05CAC',
      fontColor: '#fff',
      borderColor: '#B05CAC',
    },

    sidebar: {
      bgColor: '#B05CAC',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#B05CAC',
        borderRightColor: '#B05CAC',
        borderTopColor: '#B05CAC',
        borderBottomColor: '#B05CAC',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#ffc1ec',
      sectionSubtitleEditModeFontColor: '#B05CAC',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#8C6194'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#E0589E',
        borderColor: '#E0589E',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#E0589E',
        borderColor: '#E0589E',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#E0589E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#E0589E',
          borderColor: '#E0589E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#E0589E',
          borderColor: '#E0589E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#E0589E',
        borderColor: '#E0589E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(224,88,158,.2)',
      btnBgColor: '#E0589E',
      btnBorderColor: '#E0589E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#B05CAC',
    demoColor2: '#E0589E',

    linkColor: '#E0589E'
  },
  theme25: {
    id: 25,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8C6194',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#8C6194',
    subTitleFontEditModeColor: '#8C6194',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8C6194',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#4C3551',
      fontColor: '#fff',
      borderColor: '#4C3551',
    },

    sidebar: {
      bgColor: '#4C3551',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#4C3551',
        borderRightColor: '#4C3551',
        borderTopColor: '#4C3551',
        borderBottomColor: '#4C3551',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#B05CAC',
      sectionSubtitleEditModeFontColor: '#4C3551',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#B05CAC'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8C6194',
        borderColor: '#8C6194',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8C6194',
        borderColor: '#8C6194',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8C6194',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(140,97,148,.2)',
      btnBgColor: '#8C6194',
      btnBorderColor: '#8C6194'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4C3551',
    demoColor2: '#8C6194',

    linkColor: '#8C6194'
  },
  theme26: {
    id: 26,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#9B89BD',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#9B89BD',
    subTitleFontEditModeColor: '#9B89BD',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#9B89BD',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#736195',
      fontColor: '#fff',
      borderColor: '#736195',
    },

    sidebar: {
      bgColor: '#736195',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#736195',
        borderRightColor: '#736195',
        borderTopColor: '#736195',
        borderBottomColor: '#736195',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#FFF7FF',
      sectionSubtitleEditModeFontColor: '#736195',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#FFF7FF'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9B89BD',
        borderColor: '#9B89BD',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#9B89BD',
        borderColor: '#9B89BD',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#9B89BD',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(155,137,189,.2)',
      btnBgColor: '#9B89BD',
      btnBorderColor: '#9B89BD'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#736195',
    demoColor2: '#9B89BD',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#995D93',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#995D93',
    subTitleFontEditModeColor: '#995D93',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#995D93',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#672B61',
      fontColor: '#fff',
      borderColor: '#672B61',
    },

    sidebar: {
      bgColor: '#672B61',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#672B61',
        borderRightColor: '#672B61',
        borderTopColor: '#672B61',
        borderBottomColor: '#672B61',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#FDC1F7',
      sectionSubtitleEditModeFontColor: '#672B61',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#FDC1F7'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#995D93',
        borderColor: '#995D93',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#995D93',
        borderColor: '#995D93',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#995D93',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(153,93,147,.2)',
      btnBgColor: '#995D93',
      btnBorderColor: '#995D93'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#672B61',
    demoColor2: '#995D93',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8A6B9E',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#8A6B9E',
    subTitleFontEditModeColor: '#8A6B9E',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8A6B9E',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#58396C',
      fontColor: '#fff',
      borderColor: '#58396C',
    },

    sidebar: {
      bgColor: '#58396C',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#58396C',
        borderRightColor: '#58396C',
        borderTopColor: '#58396C',
        borderBottomColor: '#58396C',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E5C6F9',
      sectionSubtitleEditModeFontColor: '#58396C',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#E5C6F9'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8A6B9E',
        borderColor: '#8A6B9E',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8A6B9E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(138,107,158,.2)',
      btnBgColor: '#8A6B9E',
      btnBorderColor: '#8A6B9E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#58396C',
    demoColor2: '#8A6B9E',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#65758D',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    subTitleFontColor: '#65758D',
    subTitleFontEditModeColor: '#65758D',
    subTitleBGColor: 'transparent',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#65758D',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#3D4D65',
      fontColor: '#fff',
      borderColor: '#3D4D65',
    },

    sidebar: {
      bgColor: '#3D4D65',
      fontColor: '#ffffff',
      emptyImgColor: '#ffffff',

      border: {
        borderLeftColor: '#3D4D65',
        borderRightColor: '#3D4D65',
        borderTopColor: '#3D4D65',
        borderBottomColor: '#3D4D65',
      },

      sectionMainTitleFontColor: '#ffffff',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#ffffff',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#D3E3FB',
      sectionSubtitleEditModeFontColor: '#3D4D65',

      topPartBgColor: 'transparent',

      dateColor: '#d0d3db',
      countryColor: '#d0d3db',

      linkColor: '#D3E3FB'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#65758D',
        borderColor: '#65758D',
        color: '#fff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#65758D',
        borderColor: '#65758D',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#65758D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(101,117,141,.2)',
      btnBgColor: '#65758D',
      btnBorderColor: '#65758D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3D4D65',
    demoColor2: '#65758D',

    linkColor: '#65758D'
  },
};
