export enum PaidFeatures {
  PREMIUM_TEMPLATE = 'premium-template',
  PHOTO_STYLE = 'photo-style',
  FONT = 'font',
  THEME_COLOR = 'theme-color',
  INFOGRAPHICS = 'infographics',
  CREATIVE_BACKGROUND = 'creative-background',
  REPLACED_TOP_PART = 'replaced-top-part',
  BRANDING = 'branding',
  COVER_LETTER = 'cover-letter',
  MORE_THEN_ONE_PAGE = 'more-then-one-page',
}
