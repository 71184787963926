import {FormControl, FormGroup, Validators} from "@angular/forms";

export const passwordMatchValidator = (name = 'password') => {
  return (form: FormGroup): { mismatch: boolean } | null => {
    return form.get(name)?.value === form.get('rp_password')?.value ? null : {mismatch: true};
  };
}

export const oneOfRequired = (controlNames: string[] = [], labelName = '') => {
  return (group: FormGroup) => {
    const isOneOfExist = controlNames.some((name: string) => group.get(name)?.value);

    if (!isOneOfExist) {
      return {
        invalidControl: {
          text: 'You must select at least one ' + labelName
        }
      };
    }

    return null;
  };
}

export const oneOfRequiredExclude = (excludedControlName: string) => {
  return (formGroup: FormGroup) => {
    const excludedControl = formGroup.get(excludedControlName);
    const otherControls = Object.values(formGroup.controls).filter(c => c !== excludedControl);
    const hasValue = otherControls.some(c => c.value);
    return hasValue ? null : {requiredAtLeastOne: true};
  }
}

export const requireOneControl = () => {
  return (formGroup: FormGroup) => {
    if (!Object.values(formGroup.controls).some((c) => c.value)) {
      return {
        invalidControl: {
          text: 'at least one of the items is required'
        }
      }
    }
    return null;
  }
}

export const myCustomValidator = (dependsOnControl: string) => {
  return (control: FormControl) => {
    const controlToValidate = control?.parent?.get(dependsOnControl);

    if (control?.value) {
      controlToValidate?.setValidators([Validators.required]);
    } else {
      controlToValidate?.clearValidators();
    }

    controlToValidate?.updateValueAndValidity();
  };
}
