// Generated by CoffeeScript 1.12.7
(function () {
  var XMLDocument,
    XMLNode,
    XMLStringWriter,
    XMLStringifier,
    isPlainObject,
    extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    hasProp = {}.hasOwnProperty;
  isPlainObject = require('./Utility').isPlainObject;
  XMLNode = require('./XMLNode');
  XMLStringifier = require('./XMLStringifier');
  XMLStringWriter = require('./XMLStringWriter');
  module.exports = XMLDocument = function (superClass) {
    extend(XMLDocument, superClass);
    function XMLDocument(options) {
      XMLDocument.__super__.constructor.call(this, null);
      this.name = "?xml";
      options || (options = {});
      if (!options.writer) {
        options.writer = new XMLStringWriter();
      }
      this.options = options;
      this.stringify = new XMLStringifier(options);
      this.isDocument = true;
    }
    XMLDocument.prototype.end = function (writer) {
      var writerOptions;
      if (!writer) {
        writer = this.options.writer;
      } else if (isPlainObject(writer)) {
        writerOptions = writer;
        writer = this.options.writer.set(writerOptions);
      }
      return writer.document(this);
    };
    XMLDocument.prototype.toString = function (options) {
      return this.options.writer.set(options).document(this);
    };
    return XMLDocument;
  }(XMLNode);
}).call(this);