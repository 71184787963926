// Generated by CoffeeScript 1.12.7
(function () {
  var XMLCData,
    XMLComment,
    XMLDeclaration,
    XMLDocType,
    XMLDummy,
    XMLElement,
    XMLNode,
    XMLProcessingInstruction,
    XMLRaw,
    XMLText,
    getValue,
    isEmpty,
    isFunction,
    isObject,
    ref,
    hasProp = {}.hasOwnProperty;
  ref = require('./Utility'), isObject = ref.isObject, isFunction = ref.isFunction, isEmpty = ref.isEmpty, getValue = ref.getValue;
  XMLElement = null;
  XMLCData = null;
  XMLComment = null;
  XMLDeclaration = null;
  XMLDocType = null;
  XMLRaw = null;
  XMLText = null;
  XMLProcessingInstruction = null;
  XMLDummy = null;
  module.exports = XMLNode = function () {
    function XMLNode(parent) {
      this.parent = parent;
      if (this.parent) {
        this.options = this.parent.options;
        this.stringify = this.parent.stringify;
      }
      this.children = [];
      if (!XMLElement) {
        XMLElement = require('./XMLElement');
        XMLCData = require('./XMLCData');
        XMLComment = require('./XMLComment');
        XMLDeclaration = require('./XMLDeclaration');
        XMLDocType = require('./XMLDocType');
        XMLRaw = require('./XMLRaw');
        XMLText = require('./XMLText');
        XMLProcessingInstruction = require('./XMLProcessingInstruction');
        XMLDummy = require('./XMLDummy');
      }
    }
    XMLNode.prototype.element = function (name, attributes, text) {
      var childNode, item, j, k, key, lastChild, len, len1, ref1, ref2, val;
      lastChild = null;
      if (attributes === null && text == null) {
        ref1 = [{}, null], attributes = ref1[0], text = ref1[1];
      }
      if (attributes == null) {
        attributes = {};
      }
      attributes = getValue(attributes);
      if (!isObject(attributes)) {
        ref2 = [attributes, text], text = ref2[0], attributes = ref2[1];
      }
      if (name != null) {
        name = getValue(name);
      }
      if (Array.isArray(name)) {
        for (j = 0, len = name.length; j < len; j++) {
          item = name[j];
          lastChild = this.element(item);
        }
      } else if (isFunction(name)) {
        lastChild = this.element(name.apply());
      } else if (isObject(name)) {
        for (key in name) {
          if (!hasProp.call(name, key)) continue;
          val = name[key];
          if (isFunction(val)) {
            val = val.apply();
          }
          if (isObject(val) && isEmpty(val)) {
            val = null;
          }
          if (!this.options.ignoreDecorators && this.stringify.convertAttKey && key.indexOf(this.stringify.convertAttKey) === 0) {
            lastChild = this.attribute(key.substr(this.stringify.convertAttKey.length), val);
          } else if (!this.options.separateArrayItems && Array.isArray(val)) {
            for (k = 0, len1 = val.length; k < len1; k++) {
              item = val[k];
              childNode = {};
              childNode[key] = item;
              lastChild = this.element(childNode);
            }
          } else if (isObject(val)) {
            lastChild = this.element(key);
            lastChild.element(val);
          } else {
            lastChild = this.element(key, val);
          }
        }
      } else if (this.options.skipNullNodes && text === null) {
        lastChild = this.dummy();
      } else {
        if (!this.options.ignoreDecorators && this.stringify.convertTextKey && name.indexOf(this.stringify.convertTextKey) === 0) {
          lastChild = this.text(text);
        } else if (!this.options.ignoreDecorators && this.stringify.convertCDataKey && name.indexOf(this.stringify.convertCDataKey) === 0) {
          lastChild = this.cdata(text);
        } else if (!this.options.ignoreDecorators && this.stringify.convertCommentKey && name.indexOf(this.stringify.convertCommentKey) === 0) {
          lastChild = this.comment(text);
        } else if (!this.options.ignoreDecorators && this.stringify.convertRawKey && name.indexOf(this.stringify.convertRawKey) === 0) {
          lastChild = this.raw(text);
        } else if (!this.options.ignoreDecorators && this.stringify.convertPIKey && name.indexOf(this.stringify.convertPIKey) === 0) {
          lastChild = this.instruction(name.substr(this.stringify.convertPIKey.length), text);
        } else {
          lastChild = this.node(name, attributes, text);
        }
      }
      if (lastChild == null) {
        throw new Error("Could not create any elements with: " + name + ". " + this.debugInfo());
      }
      return lastChild;
    };
    XMLNode.prototype.insertBefore = function (name, attributes, text) {
      var child, i, removed;
      if (this.isRoot) {
        throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
      }
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i);
      child = this.parent.element(name, attributes, text);
      Array.prototype.push.apply(this.parent.children, removed);
      return child;
    };
    XMLNode.prototype.insertAfter = function (name, attributes, text) {
      var child, i, removed;
      if (this.isRoot) {
        throw new Error("Cannot insert elements at root level. " + this.debugInfo(name));
      }
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i + 1);
      child = this.parent.element(name, attributes, text);
      Array.prototype.push.apply(this.parent.children, removed);
      return child;
    };
    XMLNode.prototype.remove = function () {
      var i, ref1;
      if (this.isRoot) {
        throw new Error("Cannot remove the root element. " + this.debugInfo());
      }
      i = this.parent.children.indexOf(this);
      [].splice.apply(this.parent.children, [i, i - i + 1].concat(ref1 = [])), ref1;
      return this.parent;
    };
    XMLNode.prototype.node = function (name, attributes, text) {
      var child, ref1;
      if (name != null) {
        name = getValue(name);
      }
      attributes || (attributes = {});
      attributes = getValue(attributes);
      if (!isObject(attributes)) {
        ref1 = [attributes, text], text = ref1[0], attributes = ref1[1];
      }
      child = new XMLElement(this, name, attributes);
      if (text != null) {
        child.text(text);
      }
      this.children.push(child);
      return child;
    };
    XMLNode.prototype.text = function (value) {
      var child;
      child = new XMLText(this, value);
      this.children.push(child);
      return this;
    };
    XMLNode.prototype.cdata = function (value) {
      var child;
      child = new XMLCData(this, value);
      this.children.push(child);
      return this;
    };
    XMLNode.prototype.comment = function (value) {
      var child;
      child = new XMLComment(this, value);
      this.children.push(child);
      return this;
    };
    XMLNode.prototype.commentBefore = function (value) {
      var child, i, removed;
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i);
      child = this.parent.comment(value);
      Array.prototype.push.apply(this.parent.children, removed);
      return this;
    };
    XMLNode.prototype.commentAfter = function (value) {
      var child, i, removed;
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i + 1);
      child = this.parent.comment(value);
      Array.prototype.push.apply(this.parent.children, removed);
      return this;
    };
    XMLNode.prototype.raw = function (value) {
      var child;
      child = new XMLRaw(this, value);
      this.children.push(child);
      return this;
    };
    XMLNode.prototype.dummy = function () {
      var child;
      child = new XMLDummy(this);
      this.children.push(child);
      return child;
    };
    XMLNode.prototype.instruction = function (target, value) {
      var insTarget, insValue, instruction, j, len;
      if (target != null) {
        target = getValue(target);
      }
      if (value != null) {
        value = getValue(value);
      }
      if (Array.isArray(target)) {
        for (j = 0, len = target.length; j < len; j++) {
          insTarget = target[j];
          this.instruction(insTarget);
        }
      } else if (isObject(target)) {
        for (insTarget in target) {
          if (!hasProp.call(target, insTarget)) continue;
          insValue = target[insTarget];
          this.instruction(insTarget, insValue);
        }
      } else {
        if (isFunction(value)) {
          value = value.apply();
        }
        instruction = new XMLProcessingInstruction(this, target, value);
        this.children.push(instruction);
      }
      return this;
    };
    XMLNode.prototype.instructionBefore = function (target, value) {
      var child, i, removed;
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i);
      child = this.parent.instruction(target, value);
      Array.prototype.push.apply(this.parent.children, removed);
      return this;
    };
    XMLNode.prototype.instructionAfter = function (target, value) {
      var child, i, removed;
      i = this.parent.children.indexOf(this);
      removed = this.parent.children.splice(i + 1);
      child = this.parent.instruction(target, value);
      Array.prototype.push.apply(this.parent.children, removed);
      return this;
    };
    XMLNode.prototype.declaration = function (version, encoding, standalone) {
      var doc, xmldec;
      doc = this.document();
      xmldec = new XMLDeclaration(doc, version, encoding, standalone);
      if (doc.children[0] instanceof XMLDeclaration) {
        doc.children[0] = xmldec;
      } else {
        doc.children.unshift(xmldec);
      }
      return doc.root() || doc;
    };
    XMLNode.prototype.doctype = function (pubID, sysID) {
      var child, doc, doctype, i, j, k, len, len1, ref1, ref2;
      doc = this.document();
      doctype = new XMLDocType(doc, pubID, sysID);
      ref1 = doc.children;
      for (i = j = 0, len = ref1.length; j < len; i = ++j) {
        child = ref1[i];
        if (child instanceof XMLDocType) {
          doc.children[i] = doctype;
          return doctype;
        }
      }
      ref2 = doc.children;
      for (i = k = 0, len1 = ref2.length; k < len1; i = ++k) {
        child = ref2[i];
        if (child.isRoot) {
          doc.children.splice(i, 0, doctype);
          return doctype;
        }
      }
      doc.children.push(doctype);
      return doctype;
    };
    XMLNode.prototype.up = function () {
      if (this.isRoot) {
        throw new Error("The root node has no parent. Use doc() if you need to get the document object.");
      }
      return this.parent;
    };
    XMLNode.prototype.root = function () {
      var node;
      node = this;
      while (node) {
        if (node.isDocument) {
          return node.rootObject;
        } else if (node.isRoot) {
          return node;
        } else {
          node = node.parent;
        }
      }
    };
    XMLNode.prototype.document = function () {
      var node;
      node = this;
      while (node) {
        if (node.isDocument) {
          return node;
        } else {
          node = node.parent;
        }
      }
    };
    XMLNode.prototype.end = function (options) {
      return this.document().end(options);
    };
    XMLNode.prototype.prev = function () {
      var i;
      i = this.parent.children.indexOf(this);
      while (i > 0 && this.parent.children[i - 1].isDummy) {
        i = i - 1;
      }
      if (i < 1) {
        throw new Error("Already at the first node. " + this.debugInfo());
      }
      return this.parent.children[i - 1];
    };
    XMLNode.prototype.next = function () {
      var i;
      i = this.parent.children.indexOf(this);
      while (i < this.parent.children.length - 1 && this.parent.children[i + 1].isDummy) {
        i = i + 1;
      }
      if (i === -1 || i === this.parent.children.length - 1) {
        throw new Error("Already at the last node. " + this.debugInfo());
      }
      return this.parent.children[i + 1];
    };
    XMLNode.prototype.importDocument = function (doc) {
      var clonedRoot;
      clonedRoot = doc.root().clone();
      clonedRoot.parent = this;
      clonedRoot.isRoot = false;
      this.children.push(clonedRoot);
      return this;
    };
    XMLNode.prototype.debugInfo = function (name) {
      var ref1, ref2;
      name = name || this.name;
      if (name == null && !((ref1 = this.parent) != null ? ref1.name : void 0)) {
        return "";
      } else if (name == null) {
        return "parent: <" + this.parent.name + ">";
      } else if (!((ref2 = this.parent) != null ? ref2.name : void 0)) {
        return "node: <" + name + ">";
      } else {
        return "node: <" + name + ">, parent: <" + this.parent.name + ">";
      }
    };
    XMLNode.prototype.ele = function (name, attributes, text) {
      return this.element(name, attributes, text);
    };
    XMLNode.prototype.nod = function (name, attributes, text) {
      return this.node(name, attributes, text);
    };
    XMLNode.prototype.txt = function (value) {
      return this.text(value);
    };
    XMLNode.prototype.dat = function (value) {
      return this.cdata(value);
    };
    XMLNode.prototype.com = function (value) {
      return this.comment(value);
    };
    XMLNode.prototype.ins = function (target, value) {
      return this.instruction(target, value);
    };
    XMLNode.prototype.doc = function () {
      return this.document();
    };
    XMLNode.prototype.dec = function (version, encoding, standalone) {
      return this.declaration(version, encoding, standalone);
    };
    XMLNode.prototype.dtd = function (pubID, sysID) {
      return this.doctype(pubID, sysID);
    };
    XMLNode.prototype.e = function (name, attributes, text) {
      return this.element(name, attributes, text);
    };
    XMLNode.prototype.n = function (name, attributes, text) {
      return this.node(name, attributes, text);
    };
    XMLNode.prototype.t = function (value) {
      return this.text(value);
    };
    XMLNode.prototype.d = function (value) {
      return this.cdata(value);
    };
    XMLNode.prototype.c = function (value) {
      return this.comment(value);
    };
    XMLNode.prototype.r = function (value) {
      return this.raw(value);
    };
    XMLNode.prototype.i = function (target, value) {
      return this.instruction(target, value);
    };
    XMLNode.prototype.u = function () {
      return this.up();
    };
    XMLNode.prototype.importXMLBuilder = function (doc) {
      return this.importDocument(doc);
    };
    return XMLNode;
  }();
}).call(this);