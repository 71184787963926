import {environment} from "@env";
import {FormsModule} from '@angular/forms';
import {AppStateService} from '../../services';
import {Language} from "@app/shared/interfaces";
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NgForOf, NgOptimizedImage} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {projectMessages} from "@app/shared/constants/project-messages";
import {DropdownComponent} from "@app/shared/ui-kits/dropdown/dropdown.component";
import {ProjectSocialIconsComponent} from "@app/shared/ui-kits/project-social-icons/project-social-icons.component";

@Component({
  selector: 'sf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    NgForOf,
    RouterLink,
    FormsModule,
    NzIconModule,
    NzSelectModule,
    NgOptimizedImage,
    RouterLinkActive,
    DropdownComponent,
    ProjectSocialIconsComponent,
    TranslateModule,
  ],
  standalone: true,
})
export class FooterComponent {
  public readonly appStateService = inject(AppStateService);
  public readonly translateService = inject(TranslateService);
  public readonly changeDetectorRef = inject(ChangeDetectorRef);

  protected readonly projectMessages = projectMessages;

  protected readonly env = environment.env;

  public changeLanguage(event: Language) {
    this.translateService.use(event.key);
    this.appStateService.setLng(event);
    this.changeDetectorRef.markForCheck();
  }

}
