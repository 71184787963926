export const moveObjectsInArray = <T>(array: T[], fromIndex: number, toIndex: number) => {
  // Ensure the fromIndex and toIndex are valid
  if (
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    toIndex >= array.length
  ) {
    console.error('Invalid index');
    return array; // Return the original array if the indices are invalid
  }

  // Remove the object from the fromIndex
  const objectToMove = array.splice(fromIndex, 1)[0];

  // Insert the object at the toIndex
  array.splice(toIndex, 0, objectToMove);

  return array;
}
