<div class="payment-main-container">
  <i class="ph ph-x" (click)="onCLose()"></i>

  <div class="payment-info-part">

    <h2>{{ 'pricing_page.order_summary' | translate }}</h2>

    <div class="top-part-info">
      <img [ngSrc]="'assets/img/pink-logo.svg'" alt="selfcv" width="65" height="64">

      <div class="top-part-content">
        <!--<span>Premium Plan</span>-->

        <span>{{ billing.description | translate }} x {{ billing.currencyCode }} {{ billing.monthlyPrice }}</span>

        <!--@switch (paymentIndex) {
          @case (0) {
            <span>{{ billing.product.name }}</span>
          }
          @case (1) {
            <span>{{ billing.product.name }}</span>
          }
          @case (2) {
            <span>{{ billing.product.name }}</span>
            &lt;!&ndash;            <span>{{ billing.product_title }} x {{ (billing.price.gross / 12) | toFixed }} $</span>&ndash;&gt;
          }
        }-->

      </div>
    </div>

    <div class="plan-desc">
      <h4>{{ billing.description | translate }} {{ 'pricing_page.plan' | translate }}</h4>
      <strong>{{ 'pricing_page.plan_includes' | translate }}</strong>
      <ul>
        @for (item of appStateService.premiumBillingPlanIncludes; track $index) {
          <li class="mb-2">
            {{ item.title | translate }}
          </li>
        }
      </ul>
    </div>

    <div class="pay-banners">
      <!--<div class="pay-banner-item">
        <span>VAT</span>
        <span>0.00 {{ billing.currency }}</span>
      </div>-->

      <div class="pay-banner-item">
        <span>{{ 'pricing_page.total' | translate }}</span>
        <span class="active">{{ billing.currencyCode }} {{ billing.totalPrice }}</span>
      </div>
    </div>

  </div>

  <div class="checkout-container-wrapper">
    @if (false) {
      <div class="checkout-container"></div>
    } @else {
      <div class="payment-form-container">

        <h2>{{ 'pricing_page.tell_us' | translate }}</h2>

        <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onSubmit()">
          <div class="sf-input-wr mb-10">
            <input
              class="sf-input small"
              type="text"
              [placeholder]="'placeholder.full_name' | translate"
              formControlName="userName">
          </div>

          <div class="sf-input-wr mb-10">
            <input
              class="sf-input small"
              type="text"
              [placeholder]="'placeholder.email' | translate"
              formControlName="email">
          </div>

          <div class="sf-input-wr mb-10">
            <sf-dropdown
              additionalClassName="payment-countries-dr"
              [items]="filteredCountries"
              [unSelectable]="false"
              [placeHolder]="'placeholder.select_country' | translate"
              (select$)="formGroup.get('country')?.setValue($event.code)"
              [selectedItem]="currentCountry"/>

            <div class="sf-err-wr" *ngIf="form.submitted && formGroup.controls['country'].errors as error">
              <div class="sf-input-err" *ngIf="error['required']">
                <i class="ph ph-warning"></i>
                {{ 'validation.country_required' | translate }}
              </div>

              <!--<div class="sf-input-err" *ngIf="error['pattern']">
                <i class="ph ph-warning"></i>
                ZIP code must be 5 digits or 5 digits followed by a hyphen and 4 digits (e.g., 12345 or 12345-6789).
              </div>-->
            </div>
          </div>

          @if (PADDLE_ZIP_CODE_COUNTRIES.includes(appStateService.pricePreview?.data?.address?.countryCode || '')) {
            <div class="sf-input-wr mb-10">
              <input
                class="sf-input small"
                type="text"
                [placeholder]="'placeholder.ZIP_code' | translate"
                formControlName="zipCode"
                sfTrim
                [class.error]="form.submitted && formGroup.controls['zipCode'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && formGroup.controls['zipCode'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.ZIP_required' | translate }}
                </div>

                <!--<div class="sf-input-err" *ngIf="error['pattern']">
                  <i class="ph ph-warning"></i>
                  ZIP code must be 5 digits or 5 digits followed by a hyphen and 4 digits (e.g., 12345 or 12345-6789).
                </div>-->
              </div>
            </div>
          }

          <!-- Coupon code here -->
          <!--<div class="coupon-code-wrapper">
            @if (discount_code) {
              <div class="valid-coupon">
                <div class="discount-wr">
                  <i class="ph ph-check-circle"></i>
                  {{ discount_code }}
                  <i
                    class="ph ph-copy"
                    nz-tooltip="Copy into clipboard"
                    [nzTooltipMouseEnterDelay]="1"
                    (click)="copyDiscountIntoClipboard()"
                  ></i>
                </div>
                <i class="ph ph-trash delete-discount"></i>
              </div>
            } @else {
              <div class="question-txt" (click)="couponInputState = !couponInputState">Have a coupon code?</div>

              @if (couponInputState) {
                <div class="sf-input-wr mt-6 coupon-input">
                  <input class="sf-input small" type="text" placeholder="Coupon" #CouponInputRef>
                  <button
                    [class.loading]="couponBtnLoadingState"
                    class="sf-btn-primary sf-btn-fifth"
                    type="button"
                    [class.disabled]="!CouponInputRef.value"
                    (click)="applyCoupon(CouponInputRef)">
                    Apply
                  </button>
                </div>
              }
            }
          </div>-->

          <button class="sf-btn-fifth w-full" type="submit" [class.loading]="nextBtnLoadingState">
            {{ 'pricing_page.next' | translate }}
          </button>
        </form>
      </div>
    }

    @if (goToDashboardBtnState) {
      <a class="sf-btn-primary" routerLink="/dashboard">{{ 'pricing_page.go_dashboard' | translate }}</a>
    }

  </div>

</div>

