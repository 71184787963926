interface NavMenu {
  route: string;
  text: string;
  icon: string;
  external?: boolean;
}

export const NAV_MENU: Readonly<NavMenu[]> = [
  // {route: '/resume-templates', text: 'Resume Templates', icon: 'ph ph-read-cv-logo'},
  {route: '/cv-templates', text: 'header.templates', icon: 'ph ph-read-cv-logo'},
  {route: '/cover-letters', text: 'header.cover_letters', icon: 'ph ph-envelope'},
  {route: '/pricing', text: 'header.pricing', icon: 'ph ph-hand-coins'},
  {route: 'https://www.selfcv.com/career-blog/', text: 'header.blog', icon: 'ph ph-arrow-square-out', external: true},
];
