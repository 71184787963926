import {Language} from "@app/shared/interfaces";

export const LANGUAGES: Language[] = [
  {
    icon: 'en',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/en.svg" alt="selfcv">English</div>`,
    key: 'en'
  },
  {
    icon: 'fr',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/fr.svg" alt="selfcv">Français</div>`,
    key: 'fr'
  },
  {
    icon: 'de',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/de.svg" alt="selfcv">Deutsch</div>`,
    key: 'de'
  },
  {
    icon: 'ru',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/ru.svg" alt="selfcv">Русский</div>`,
    key: 'ru'
  },
  {
    icon: 'sp',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/sp.svg" alt="selfcv">Español</div>`,
    key: 'sp'
  },
  {
    icon: 'indi',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/indi.svg" alt="selfcv">भारतीय</div>`,
    key: 'indi'
  },
  {
    icon: 'china',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/china.svg" alt="selfcv">中國人</div>`,
    key: 'china'
  },
  {
    icon: 'tr',
    name: `<div class="sf-lng-item"><img src="assets/img/languages/tr.svg" alt="selfcv">Türkçe</div>`,
    key: 'tr'
  }
];
