export const projectMessages = {
  getTmUrl: 'project_messages.template_url_copied',
  copyClpFailed: 'project_messages.failed_to_copy',
  emailVerified: 'project_messages.email_verified',
  tokenNotExist: 'project_messages.token_not_exist',
  tooLongText: 'project_messages.too_long_text',
  tooBigSize: 'project_messages.too_big_size',
  allRights: `Copyright ©${new Date().getFullYear()} All rights reserved`,
  pureTmActions: "project_messages.pure_tm_actions"
}
