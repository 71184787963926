import {TmFont} from "@app/shared/interfaces";

export const TM_FONTS: Readonly<TmFont>[] = [
  {name: 'Poppins', fontFamily: 'Poppins', paid: false},
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Roboto</div>',
    fontFamily: 'Roboto',
    paid: false
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Overpass</div>',
    fontFamily: 'Overpass',
    paid: false
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Ubuntu <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Ubuntu',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Lato <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Lato',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Hind <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Hind',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Raleway <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Raleway',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Quicksand <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Quicksand',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Merriweather Sans <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Merriweather Sans',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Cormorant Garamond Sans <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Cormorant Garamond',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Lora <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Lora',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Rokkitt <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Rokkitt',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Merriweather<i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Merriweather',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Lusitana <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Lusitana',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Rubik <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Rubik',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Bitter <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Bitter',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Exo 2 <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Exo 2',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Open Sans <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Open Sans',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Chivo <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Chivo',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Oswald <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Oswald',
    paid: true
  },
  {
    name: '<div class="d-flex ai-c jc-sb w-full">Montserrat <i class="ph ph-star mr-10 color-4b57dc" title="Paid"></i></div>',
    fontFamily: 'Montserrat',
    paid: true
  },
];
