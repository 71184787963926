import {Params} from '@angular/router';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sfFilter',
  standalone: true
})
export class SfFilterPipe implements PipeTransform {

  public transform<T = Params | string>(arr: T[], key: string, arrayKeyName = 'name'): T[] {

    const myArr = arr || [];

    if (!key) {
      return myArr;
    }

    if (myArr.length) {
      const [firstItem] = arr;
      if (typeof firstItem === 'string') {
        // @ts-ignore
        return myArr.filter((item: string) => item.toLowerCase().includes(key.toLowerCase()));
      }
    }

    return myArr.filter((item: T) => item[arrayKeyName as keyof T]?.toString().toLowerCase().includes(key.toLowerCase()));
  }

}

