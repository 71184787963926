module.exports = {
  failure: function (errors, remaining) {
    if (errors.length < 1) {
      throw new Error("Failure must have errors");
    }
    return new Result({
      status: "failure",
      remaining: remaining,
      errors: errors
    });
  },
  error: function (errors, remaining) {
    if (errors.length < 1) {
      throw new Error("Failure must have errors");
    }
    return new Result({
      status: "error",
      remaining: remaining,
      errors: errors
    });
  },
  success: function (value, remaining, source) {
    return new Result({
      status: "success",
      value: value,
      source: source,
      remaining: remaining,
      errors: []
    });
  },
  cut: function (remaining) {
    return new Result({
      status: "cut",
      remaining: remaining,
      errors: []
    });
  }
};
var Result = function (options) {
  this._value = options.value;
  this._status = options.status;
  this._hasValue = options.value !== undefined;
  this._remaining = options.remaining;
  this._source = options.source;
  this._errors = options.errors;
};
Result.prototype.map = function (func) {
  if (this._hasValue) {
    return new Result({
      value: func(this._value, this._source),
      status: this._status,
      remaining: this._remaining,
      source: this._source,
      errors: this._errors
    });
  } else {
    return this;
  }
};
Result.prototype.changeRemaining = function (remaining) {
  return new Result({
    value: this._value,
    status: this._status,
    remaining: remaining,
    source: this._source,
    errors: this._errors
  });
};
Result.prototype.isSuccess = function () {
  return this._status === "success" || this._status === "cut";
};
Result.prototype.isFailure = function () {
  return this._status === "failure";
};
Result.prototype.isError = function () {
  return this._status === "error";
};
Result.prototype.isCut = function () {
  return this._status === "cut";
};
Result.prototype.value = function () {
  return this._value;
};
Result.prototype.remaining = function () {
  return this._remaining;
};
Result.prototype.source = function () {
  return this._source;
};
Result.prototype.errors = function () {
  return this._errors;
};