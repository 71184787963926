import {fromEvent, of, Subject, timer} from 'rxjs';
import {delayWhen, filter, map, takeUntil} from 'rxjs/operators';
import {Directive, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[sfClickOutside]',
  standalone: true
})
export class ClickOutsideDirective implements OnInit, OnDestroy {

  private readonly el = inject(ElementRef);
  @Input() public delay = 0;
  @Input() public enabled = true;
  @Input() public sfCapture = false;
  @Input() public eventName = 'click';
  @Input() public exclude = 'ignore-click-outside';
  @Output() public sfClickOutside = new EventEmitter<void>();
  private readonly destroy$ = new Subject<void>();

  public ngOnInit() {
    fromEvent<PointerEvent>(document, this.eventName, {capture: this.sfCapture})
      .pipe(
        filter(() => this.enabled),

        map((res: any) => res.target),

        filter((target: HTMLElement) => this.isElEnabled(target)),

        delayWhen(() => this.delay ? timer(this.delay) : of(null)),

        filter((target: HTMLElement) => !this.el.nativeElement.contains(target)),

        takeUntil(this.destroy$)
      )
      .subscribe(() => this.sfClickOutside.emit());
  }

  private isElEnabled(target: HTMLElement): boolean {
    return !this.exclude
      .split(' ')
      .some((item: string) => target instanceof HTMLElement && target.className.includes(item));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
