import {Subject} from "rxjs";
import {TemplateTypes} from "@app/shared/enums";
import {inject, Injectable} from '@angular/core';
import {TemplateConfigs} from "@app/shared/interfaces";
import {OneRowTmSplitLogicService} from "@app/shared/services/template-logic/one-row-tm-split-logic.service";
import {TwoRowTmSplitLogicService} from "@app/shared/services/template-logic/two-row-tm-split-logic.service";
import {SidebarTmSplitLogicService} from "@app/shared/services/template-logic/sidebar-tm-split-logic.service";

@Injectable({
  providedIn: 'root'
})
export class TemplateLogicService {

  private readonly oneRowTmSplitLogicService = inject(OneRowTmSplitLogicService);
  private readonly twoRowTmSplitLogicService = inject(TwoRowTmSplitLogicService);
  private readonly sidebarTypeTmSplitLogicTsService = inject(SidebarTmSplitLogicService);

  public readonly splitPages$ = new Subject<TemplateConfigs>();
  public readonly descDeleteSubject = new Subject<string>();

  constructor() {
  }

  public splitToPages(template: TemplateConfigs) {
    this.splitPages$.next(template);
  }

  public splitTemplateToPages(template: TemplateConfigs) {
    if (!template) {
      return;
    }

    switch (template.type) {
      case TemplateTypes.ONE_ROW: {
        this.oneRowTmSplitLogicService.oneRowSplitPages(template);
        break;
      }
      case TemplateTypes.SIDEBAR: {
        this.sidebarTypeTmSplitLogicTsService.sidebarSplitPages(template);
        break;
      }
      case TemplateTypes.TWO_ROWS: {
        this.twoRowTmSplitLogicService.twoRowSplitPages(template);
        break;
      }
    }
  }

}
