import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter1: CoverLetter = {
  templateId: 1,
  forTemplate: 1,
  templateTopPartType: 1,
  ...cloneObject(coverLetterCommonConfigs)
};

