import {SingleTemplateThemeColor} from "@app/shared/interfaces";

//@ts-ignore
export const template6Themes: SingleTemplateThemeColor = {
  theme1: {
    id: 1,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#303030',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#303030',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#ffffff',
    },

    sidebar: {
      bgColor: '#FCFCFC',
      fontColor: '#303030',
      emptyImgColor: '#303030',

      border: {
        borderLeftColor: '#FCFCFC',
        borderRightColor: '#FCFCFC',
        borderTopColor: '#FCFCFC',
        borderBottomColor: '#FCFCFC',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#303030',
      sectionSubtitleEditModeFontColor: '#303030',

      topPartBgColor: '#fff',

      dateColor: '#979797',
      countryColor: '#979797',

      linkColor: '#303030'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4B57DC',
        borderColor: '#4B57DC',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4B57DC',
        borderColor: '#4B57DC',
        bgColor: '#ffffff',
      }
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4B57DC',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ffffff',
        borderColor: '#ffffff',
      }
    },

    emptySection: {
      bgColor: 'rgba(194,197,203,.2)',
      btnBgColor: '#4B57DC',
      btnBorderColor: '#4B57DC'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#303030',

    linkColor: '#303030'
  },
};
