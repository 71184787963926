"use strict";

module.exports = function (Promise, INTERNAL, tryConvertToPromise, debug) {
  var calledBind = false;
  var rejectThis = function (_, e) {
    this._reject(e);
  };
  var targetRejected = function (e, context) {
    context.promiseRejectionQueued = true;
    context.bindingPromise._then(rejectThis, rejectThis, null, this, e);
  };
  var bindingResolved = function (thisArg, context) {
    if ((this._bitField & 50397184) === 0) {
      this._resolveCallback(context.target);
    }
  };
  var bindingRejected = function (e, context) {
    if (!context.promiseRejectionQueued) this._reject(e);
  };
  Promise.prototype.bind = function (thisArg) {
    if (!calledBind) {
      calledBind = true;
      Promise.prototype._propagateFrom = debug.propagateFromFunction();
      Promise.prototype._boundValue = debug.boundValueFunction();
    }
    var maybePromise = tryConvertToPromise(thisArg);
    var ret = new Promise(INTERNAL);
    ret._propagateFrom(this, 1);
    var target = this._target();
    ret._setBoundTo(maybePromise);
    if (maybePromise instanceof Promise) {
      var context = {
        promiseRejectionQueued: false,
        promise: ret,
        target: target,
        bindingPromise: maybePromise
      };
      target._then(INTERNAL, targetRejected, undefined, ret, context);
      maybePromise._then(bindingResolved, bindingRejected, undefined, ret, context);
      ret._setOnCancel(maybePromise);
    } else {
      ret._resolveCallback(target);
    }
    return ret;
  };
  Promise.prototype._setBoundTo = function (obj) {
    if (obj !== undefined) {
      this._bitField = this._bitField | 2097152;
      this._boundTo = obj;
    } else {
      this._bitField = this._bitField & ~2097152;
    }
  };
  Promise.prototype._isBound = function () {
    return (this._bitField & 2097152) === 2097152;
  };
  Promise.bind = function (thisArg, value) {
    return Promise.resolve(value).bind(thisArg);
  };
};