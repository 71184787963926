export const getElHeightWithMargins = (element: HTMLElement) => {

  if (!element) {
    return 0;
  }

  // Get the computed style of the element
  const computedStyle = window.getComputedStyle(element);

  // Calculate the height including margins
  const heightWithMargins = (parseFloat(computedStyle.height) || element.offsetHeight) +
    parseFloat(computedStyle.marginTop) +
    parseFloat(computedStyle.marginBottom);

  return heightWithMargins;
}

