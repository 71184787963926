import {SingleTemplateThemeColor} from "@app/shared/interfaces";

/** This one has background: light sidebar */
export const template3Themes: SingleTemplateThemeColor = {
  theme1: {
    id: 1,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#2A3958',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#4B57DC',
    subTitleFontEditModeColor: '#4B57DC',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2A3958',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#4B57DC',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#2A3958',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#4B57DC',
      sectionSubtitleEditModeFontColor: '#2A3958',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#4B57DC'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4B57DC',
        borderColor: '#4B57DC',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2A3958',
        borderColor: '#2A3958',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2A3958',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2A3958',
          borderColor: '#2A3958',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2A3958',
          borderColor: '#2A3958',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#2A3958',
        borderColor: '#2A3958',
      }
    },
    emptySection: {
      bgColor: 'rgba(194,197,203,.2)',
      btnBgColor: '#4B57DC',
      btnBorderColor: '#4B57DC'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A3958',
    demoColor2: '#4B57DC',

    linkColor: '#4B57DC'
  }, // template 1 color
  theme2: {
    id: 2,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#374259',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#69748B',
    subTitleFontEditModeColor: '#69748B',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#374259',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#69748B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#374259',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#69748B',
      sectionSubtitleEditModeFontColor: '#374259',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#69748B'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#69748B',
        borderColor: '#69748B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#374259',
        borderColor: '#374259',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#374259',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#374259',
          borderColor: '#374259',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#374259',
          borderColor: '#374259',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#69748B',
        borderColor: '#69748B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#374259',
        borderColor: '#374259',
      }
    },
    emptySection: {
      bgColor: 'rgba(105,116,139,.2)',
      btnBgColor: '#69748B',
      btnBorderColor: '#69748B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#69748B'
  },
  theme3: {
    id: 3,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#324750',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#647982',
    subTitleFontEditModeColor: '#647982',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#324750',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#647982',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#324750',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#647982',
      sectionSubtitleEditModeFontColor: '#324750',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#647982'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#647982',
        borderColor: '#647982',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#324750',
        borderColor: '#324750',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#324750',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#324750',
          borderColor: '#324750',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#324750',
          borderColor: '#324750',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#647982',
        borderColor: '#647982',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#324750',
        borderColor: '#324750',
      }
    },
    emptySection: {
      bgColor: 'rgba(100,121,130,.2)',
      btnBgColor: '#647982',
      btnBorderColor: '#647982'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#4E595D',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#808B8F',
    subTitleFontEditModeColor: '#808B8F',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#4E595D',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#808B8F',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#4E595D',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#808B8F',
      sectionSubtitleEditModeFontColor: '#4E595D',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#808B8F'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#808B8F',
        borderColor: '#808B8F',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4E595D',
        borderColor: '#4E595D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4E595D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4E595D',
          borderColor: '#4E595D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4E595D',
          borderColor: '#4E595D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#808B8F',
        borderColor: '#808B8F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#4E595D',
        borderColor: '#4E595D',
      }
    },
    emptySection: {
      bgColor: 'rgba(128,139,143,.2)',
      btnBgColor: '#808B8F',
      btnBorderColor: '#808B8F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#808B8F'
  },
  theme5: {
    id: 5,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#505061',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#828293',
    subTitleFontEditModeColor: '#828293',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#505061',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#828293',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#505061',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#828293',
      sectionSubtitleEditModeFontColor: '#505061',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#828293'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#828293',
        borderColor: '#828293',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#505061',
        borderColor: '#505061',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#505061',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#505061',
          borderColor: '#505061',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#505061',
          borderColor: '#505061',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#828293',
        borderColor: '#828293',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#505061',
        borderColor: '#505061',
      }
    },
    emptySection: {
      bgColor: 'rgba(130,130,147,.2)',
      btnBgColor: '#828293',
      btnBorderColor: '#828293'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#505061',
    demoColor2: '#828293',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5B5054',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#A49393',
    subTitleFontEditModeColor: '#A49393',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#5B5054',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#A49393',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#5B5054',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#A49393',
      sectionSubtitleEditModeFontColor: '#5B5054',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#A49393'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#A49393',
        borderColor: '#A49393',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5B5054',
        borderColor: '#5B5054',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5B5054',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5B5054',
          borderColor: '#5B5054',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5B5054',
          borderColor: '#5B5054',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#A49393',
        borderColor: '#A49393',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#5B5054',
        borderColor: '#5B5054',
      }
    },
    emptySection: {
      bgColor: 'rgba(164,147,147,.2)',
      btnBgColor: '#A49393',
      btnBorderColor: '#A49393'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#A49393'
  }, // template 5 color
  theme7: {
    id: 7,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#1B4F77',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#43779F',
    subTitleFontEditModeColor: '#43779F',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#1B4F77',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#43779F',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#1B4F77',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#43779F',
      sectionSubtitleEditModeFontColor: '#1B4F77',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#43779F'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#43779F',
        borderColor: '#43779F',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#1B4F77',
        borderColor: '#1B4F77',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#1B4F77',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#1B4F77',
          borderColor: '#1B4F77',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#1B4F77',
          borderColor: '#1B4F77',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#1B4F77',
        borderColor: '#1B4F77',
      }
    },
    emptySection: {
      bgColor: 'rgba(67,119,159,.2)',
      btnBgColor: '#43779F',
      btnBorderColor: '#43779F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1B4F77',
    demoColor2: '#43779F',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#005188',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#2983BA',
    subTitleFontEditModeColor: '#2983BA',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#005188',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#2983BA',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#005188',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#2983BA',
      sectionSubtitleEditModeFontColor: '#005188',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#2983BA'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#2983BA',
        borderColor: '#2983BA',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#005188',
        borderColor: '#005188',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#005188',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#005188',
          borderColor: '#005188',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#005188',
          borderColor: '#005188',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#2983BA',
        borderColor: '#2983BA',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#005188',
        borderColor: '#005188',
      }
    },
    emptySection: {
      bgColor: 'rgba(41,131,186,.2)',
      btnBgColor: '#2983BA',
      btnBorderColor: '#2983BA'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#2983BA'
  },
  theme9: {
    id: 9,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3A3C73',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#6C6EA5',
    subTitleFontEditModeColor: '#6C6EA5',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#3A3C73',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#6C6EA5',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#3A3C73',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#6C6EA5',
      sectionSubtitleEditModeFontColor: '#3A3C73',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#6C6EA5'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3A3C73',
        borderColor: '#3A3C73',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3A3C73',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3A3C73',
          borderColor: '#3A3C73',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3A3C73',
          borderColor: '#3A3C73',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#3A3C73',
        borderColor: '#3A3C73',
      }
    },
    emptySection: {
      bgColor: 'rgba(108,110,165,.2)',
      btnBgColor: '#6C6EA5',
      btnBorderColor: '#6C6EA5'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#6C6EA5'
  },
  theme10: {
    id: 10,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#39559A',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#5A79C7',
    subTitleFontEditModeColor: '#5A79C7',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#39559A',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#5A79C7',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#39559A',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#5A79C7',
      sectionSubtitleEditModeFontColor: '#39559A',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#5A79C7'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#5A79C7',
        borderColor: '#5A79C7',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#39559A',
        borderColor: '#39559A',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#39559A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#39559A',
          borderColor: '#39559A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#39559A',
          borderColor: '#39559A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#39559A',
        borderColor: '#39559A',
      }
    },
    emptySection: {
      bgColor: 'rgba(90,121,199,.2)',
      btnBgColor: '#5A79C7',
      btnBorderColor: '#5A79C7'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#39559A',
    demoColor2: '#5A79C7',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#1F3B60',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#516D92',
    subTitleFontEditModeColor: '#516D92',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#1F3B60',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#516D92',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#1F3B60',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#516D92',
      sectionSubtitleEditModeFontColor: '#1F3B60',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#516D92'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#516D92',
        borderColor: '#516D92',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#1F3B60',
        borderColor: '#1F3B60',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#1F3B60',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#1F3B60',
          borderColor: '#1F3B60',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#1F3B60',
          borderColor: '#1F3B60',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#1F3B60',
        borderColor: '#1F3B60',
      }
    },
    emptySection: {
      bgColor: 'rgba(81,109,146,.2)',
      btnBgColor: '#516D92',
      btnBorderColor: '#516D92'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1F3B60',
    demoColor2: '#516D92',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#0A444E',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#3C7680',
    subTitleFontEditModeColor: '#3C7680',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#0A444E',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#3C7680',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#0A444E',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#3C7680',
      sectionSubtitleEditModeFontColor: '#0A444E',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#3C7680'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#3C7680',
        borderColor: '#3C7680',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#0A444E',
        borderColor: '#0A444E',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#0A444E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#0A444E',
          borderColor: '#0A444E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#0A444E',
          borderColor: '#0A444E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#0A444E',
        borderColor: '#0A444E',
      }
    },
    emptySection: {
      bgColor: 'rgba(60,118,128,.2)',
      btnBgColor: '#3C7680',
      btnBorderColor: '#3C7680'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#0A444E',
    demoColor2: '#3C7680',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#2A5752',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#527F7A',
    subTitleFontEditModeColor: '#527F7A',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2A5752',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#527F7A',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#2A5752',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#527F7A',
      sectionSubtitleEditModeFontColor: '#2A5752',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#527F7A'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#527F7A',
        borderColor: '#527F7A',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2A5752',
        borderColor: '#2A5752',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2A5752',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2A5752',
          borderColor: '#2A5752',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2A5752',
          borderColor: '#2A5752',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#527F7A',
        borderColor: '#527F7A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#2A5752',
        borderColor: '#2A5752',
      }
    },
    emptySection: {
      bgColor: 'rgba(82,127,122,.2)',
      btnBgColor: '#527F7A',
      btnBorderColor: '#527F7A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#527F7A'
  },
  theme14: {
    id: 14,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#29686F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#519097',
    subTitleFontEditModeColor: '#519097',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#29686F',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#519097',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#29686F',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#519097',
      sectionSubtitleEditModeFontColor: '#29686F',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#519097'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#519097',
        borderColor: '#519097',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#29686F',
        borderColor: '#29686F',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#29686F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#29686F',
          borderColor: '#29686F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#29686F',
          borderColor: '#29686F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#519097',
        borderColor: '#519097',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#29686F',
        borderColor: '#29686F',
      }
    },
    emptySection: {
      bgColor: 'rgba(81,144,151,.2)',
      btnBgColor: '#519097',
      btnBorderColor: '#519097'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#29686F',
    demoColor2: '#519097',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#405454',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#728686',
    subTitleFontEditModeColor: '#728686',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#405454',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#728686',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#405454',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#728686',
      sectionSubtitleEditModeFontColor: '#405454',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#728686'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#728686',
        borderColor: '#728686',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#405454',
        borderColor: '#405454',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#405454',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#405454',
          borderColor: '#405454',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#405454',
          borderColor: '#405454',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#728686',
        borderColor: '#728686',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#405454',
        borderColor: '#405454',
      }
    },
    emptySection: {
      bgColor: 'rgba(114,134,134,.2)',
      btnBgColor: '#728686',
      btnBorderColor: '#728686'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#405454',
    demoColor2: '#728686',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#606C5D',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#7E8A7B',
    subTitleFontEditModeColor: '#7E8A7B',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#606C5D',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#7E8A7B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#606C5D',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#7E8A7B',
      sectionSubtitleEditModeFontColor: '#606C5D',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#7E8A7B'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#606C5D',
        borderColor: '#606C5D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#606C5D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#606C5D',
          borderColor: '#606C5D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#606C5D',
          borderColor: '#606C5D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#606C5D',
        borderColor: '#606C5D',
      }
    },
    emptySection: {
      bgColor: 'rgba(126,138,123,.2)',
      btnBgColor: '#7E8A7B',
      btnBorderColor: '#7E8A7B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B',

    linkColor: '#7E8A7B'
  },
  theme17: {
    id: 17,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#2B2E35',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#ACCEC8',
    subTitleFontEditModeColor: '#ACCEC8',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2B2E35',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#ACCEC8',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#2B2E35',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#ACCEC8',
      sectionSubtitleEditModeFontColor: '#2B2E35',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#ACCEC8'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2B2E35',
        borderColor: '#2B2E35',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2B2E35',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2B2E35',
          borderColor: '#2B2E35',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2B2E35',
          borderColor: '#2B2E35',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#2B2E35',
        borderColor: '#2B2E35',
      }
    },
    emptySection: {
      bgColor: 'rgba(172,206,200,.2)',
      btnBgColor: '#ACCEC8',
      btnBorderColor: '#ACCEC8'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2B2E35',
    demoColor2: '#ACCEC8',

    linkColor: '#ACCEC8'
  },  // template 2 color
  theme18: {
    id: 18,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5E4E3F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#908071',
    subTitleFontEditModeColor: '#908071',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#5E4E3F',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#908071',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#5E4E3F',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#908071',
      sectionSubtitleEditModeFontColor: '#5E4E3F',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#908071'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#908071',
        borderColor: '#908071',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5E4E3F',
        borderColor: '#5E4E3F',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5E4E3F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5E4E3F',
          borderColor: '#5E4E3F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5E4E3F',
          borderColor: '#5E4E3F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#908071',
        borderColor: '#908071',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#5E4E3F',
        borderColor: '#5E4E3F',
      }
    },
    emptySection: {
      bgColor: 'rgba(144,128,113,.2)',
      btnBgColor: '#908071',
      btnBorderColor: '#908071'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5E4E3F',
    demoColor2: '#908071',

    linkColor: '#908071'
  },
  theme19: {
    id: 19,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#66423C',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8E6A64',
    subTitleFontEditModeColor: '#8E6A64',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#66423C',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8E6A64',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#66423C',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8E6A64',
      sectionSubtitleEditModeFontColor: '#66423C',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#8E6A64'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8E6A64',
        borderColor: '#8E6A64',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#66423C',
        borderColor: '#66423C',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#66423C',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#66423C',
          borderColor: '#66423C',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#66423C',
          borderColor: '#66423C',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#66423C',
        borderColor: '#66423C',
      }
    },
    emptySection: {
      bgColor: 'rgba(142,106,100,.2)',
      btnBgColor: '#8E6A64',
      btnBorderColor: '#8E6A64'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#66423C',
    demoColor2: '#8E6A64',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#572B43',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#7F536B',
    subTitleFontEditModeColor: '#7F536B',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#572B43',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#7F536B',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#572B43',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#7F536B',
      sectionSubtitleEditModeFontColor: '#572B43',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#7F536B'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7F536B',
        borderColor: '#7F536B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#572B43',
        borderColor: '#572B43',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#572B43',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#572B43',
          borderColor: '#572B43',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#572B43',
          borderColor: '#572B43',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7F536B',
        borderColor: '#7F536B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#572B43',
        borderColor: '#572B43',
      }
    },
    emptySection: {
      bgColor: 'rgba(127,83,107,.2)',
      btnBgColor: '#7F536B',
      btnBorderColor: '#7F536B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#7F536B'
  },
  theme21: {
    id: 21,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#303030',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#626262',
    subTitleFontEditModeColor: '#626262',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#303030',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#626262',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#303030',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#626262',
      sectionSubtitleEditModeFontColor: '#303030',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#626262'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#626262',
        borderColor: '#626262',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#303030',
        borderColor: '#303030',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#303030',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#303030',
          borderColor: '#303030',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#303030',
          borderColor: '#303030',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#626262',
        borderColor: '#626262',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#303030',
        borderColor: '#303030',
      }
    },
    emptySection: {
      bgColor: 'rgba(98,98,98,.2)',
      btnBgColor: '#626262',
      btnBorderColor: '#626262'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#626262',

    linkColor: '#626262'
  }, // black color
  theme22: {
    id: 22,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8C3868',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#BE6A9A',
    subTitleFontEditModeColor: '#BE6A9A',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#8C3868',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#BE6A9A',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#8C3868',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#BE6A9A',
      sectionSubtitleEditModeFontColor: '#8C3868',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#BE6A9A'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8C3868',
        borderColor: '#8C3868',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8C3868',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8C3868',
          borderColor: '#8C3868',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8C3868',
          borderColor: '#8C3868',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8C3868',
        borderColor: '#8C3868',
      }
    },
    emptySection: {
      bgColor: 'rgba(190,106,154,.2)',
      btnBgColor: '#BE6A9A',
      btnBorderColor: '#BE6A9A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C3868',
    demoColor2: '#BE6A9A',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#6B3A56',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#9D6C88',
    subTitleFontEditModeColor: '#9D6C88',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#6B3A56',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#9D6C88',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#6B3A56',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#9D6C88',
      sectionSubtitleEditModeFontColor: '#6B3A56',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#9D6C88'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9D6C88',
        borderColor: '#9D6C88',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#6B3A56',
        borderColor: '#6B3A56',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#6B3A56',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#6B3A56',
          borderColor: '#6B3A56',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#6B3A56',
          borderColor: '#6B3A56',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9D6C88',
        borderColor: '#9D6C88',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#6B3A56',
        borderColor: '#6B3A56',
      }
    },
    emptySection: {
      bgColor: 'rgba(157,108,136,.2)',
      btnBgColor: '#9D6C88',
      btnBorderColor: '#9D6C88'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#9D6C88'
  },
  theme24: {
    id: 24,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#B05CAC',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#E0589E',
    subTitleFontEditModeColor: '#E0589E',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#B05CAC',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#E0589E',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#B05CAC',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E0589E',
      sectionSubtitleEditModeFontColor: '#B05CAC',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#E0589E'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#E0589E',
        borderColor: '#E0589E',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#B05CAC',
        borderColor: '#B05CAC',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#B05CAC',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#B05CAC',
          borderColor: '#B05CAC',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#B05CAC',
          borderColor: '#B05CAC',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#E0589E',
        borderColor: '#E0589E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#B05CAC',
        borderColor: '#B05CAC',
      }
    },
    emptySection: {
      bgColor: 'rgba(157,108,136,.2)',
      btnBgColor: '#E0589E',
      btnBorderColor: '#E0589E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#B05CAC',
    demoColor2: '#E0589E',

    linkColor: '#E0589E'
  },  // template 4 color
  theme25: {
    id: 25,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8C6194',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8C6194',
    subTitleFontEditModeColor: '#8C6194',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#4C3551',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8C6194',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#4C3551',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8C6194',
      sectionSubtitleEditModeFontColor: '#4C3551',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#8C6194'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8C6194',
        borderColor: '#8C6194',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4C3551',
        borderColor: '#4C3551',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4C3551',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4C3551',
          borderColor: '#4C3551',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4C3551',
          borderColor: '#4C3551',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#4C3551',
        borderColor: '#4C3551',
      }
    },
    emptySection: {
      bgColor: 'rgba(140, 97, 148,.2)',
      btnBgColor: '#8C6194',
      btnBorderColor: '#8C6194'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4C3551',
    demoColor2: '#8C6194',

    linkColor: '#8C6194'
  },
  theme26: {
    id: 26,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#736195',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#9B89BD',
    subTitleFontEditModeColor: '#9B89BD',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#736195',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#9B89BD',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#736195',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#9B89BD',
      sectionSubtitleEditModeFontColor: '#736195',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#9B89BD'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9B89BD',
        borderColor: '#9B89BD',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#736195',
        borderColor: '#736195',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#736195',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#736195',
          borderColor: '#736195',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#736195',
          borderColor: '#736195',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#736195',
        borderColor: '#736195',
      }
    },
    emptySection: {
      bgColor: 'rgba(155,137,189,.2)',
      btnBgColor: '#9B89BD',
      btnBorderColor: '#9B89BD'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#736195',
    demoColor2: '#9B89BD',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#672B61',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#995D93',
    subTitleFontEditModeColor: '#995D93',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#672B61',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#995D93',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#672B61',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#995D93',
      sectionSubtitleEditModeFontColor: '#672B61',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#995D93'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#995D93',
        borderColor: '#995D93',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#672B61',
        borderColor: '#672B61',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#672B61',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#672B61',
          borderColor: '#672B61',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#672B61',
          borderColor: '#672B61',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#672B61',
        borderColor: '#672B61',
      }
    },
    emptySection: {
      bgColor: 'rgba(153,93,147,.2)',
      btnBgColor: '#995D93',
      btnBorderColor: '#995D93'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#672B61',
    demoColor2: '#995D93',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#58396C',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8A6B9E',
    subTitleFontEditModeColor: '#8A6B9E',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#58396C',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8A6B9E',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#58396C',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8A6B9E',
      sectionSubtitleEditModeFontColor: '#58396C',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#8A6B9E'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#58396C',
        borderColor: '#58396C',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#58396C',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#58396C',
          borderColor: '#58396C',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#58396C',
          borderColor: '#58396C',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#58396C',
        borderColor: '#58396C',
      }
    },
    emptySection: {
      bgColor: 'rgba(138,107,158,.2)',
      btnBgColor: '#8A6B9E',
      btnBorderColor: '#8A6B9E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#58396C',
    demoColor2: '#8A6B9E',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3D4D65',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#65758D',
    subTitleFontEditModeColor: '#65758D',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#3D4D65',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#65758D',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#F9FAFB',
      fontColor: '#000',
      emptyImgColor: '#3D4D65',

      border: {
        borderLeftColor: '#F9FAFB',
        borderRightColor: '#F9FAFB',
        borderTopColor: '#F9FAFB',
        borderBottomColor: '#F9FAFB',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#65758D',
      sectionSubtitleEditModeFontColor: '#3D4D65',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#65758D'
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#65758D',
        borderColor: '#65758D',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3D4D65',
        borderColor: '#3D4D65',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3D4D65',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3D4D65',
          borderColor: '#3D4D65',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3D4D65',
          borderColor: '#3D4D65',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#3D4D65',
        borderColor: '#3D4D65',
      }
    },
    emptySection: {
      bgColor: 'rgba(101,117,141,.2)',
      btnBgColor: '#65758D',
      btnBorderColor: '#65758D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3D4D65',
    demoColor2: '#65758D',

    linkColor: '#65758D'
  },
}
