var documents = require("./documents");
function convertElementToRawText(element) {
  if (element.type === "text") {
    return element.value;
  } else if (element.type === documents.types.tab) {
    return "\t";
  } else {
    var tail = element.type === "paragraph" ? "\n\n" : "";
    return (element.children || []).map(convertElementToRawText).join("") + tail;
  }
}
exports.convertElementToRawText = convertElementToRawText;