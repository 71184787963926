import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter4: CoverLetter = {
  templateId: 4,
  forTemplate: 4,
  templateTopPartType: 7,
  ...cloneObject(coverLetterCommonConfigs)
};

