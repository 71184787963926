import {Component, inject} from '@angular/core';
import {copyToClipboard} from "@app/shared/helpers";
import {AppStateService} from "@app/shared/services";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'sf-coupon-banner',
  standalone: true,
  imports: [
    NzTooltipDirective,
    TranslateModule
  ],
  templateUrl: './coupon-banner.component.html',
  styleUrl: './coupon-banner.component.scss'
})
export class CouponBannerComponent {
  public readonly appStateService = inject(AppStateService);

  public couponCopiedState = false;

  constructor() {
  }

  protected copyCoupon() {
    copyToClipboard(this.appStateService.user!.referAndEarn.coupon);
    this.couponCopiedState = true;

    setTimeout(() => this.couponCopiedState = false, 3000);
  }

}

