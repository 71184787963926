import { HttpContext, HttpContextToken } from "@angular/common/http";

interface HttpContextDefaults {
  notifyError: boolean;
  notifySuccess: boolean;
  errorLog: boolean;
  cache: boolean;
  skipPrefix: boolean;
  skipAuth: boolean;
  messageSuccess: boolean
  messageError: boolean;
}

export const httpContextDefaults = new HttpContextToken<Partial<HttpContextDefaults>>(() => ({
  notifyError: true,
  notifySuccess: false,
  errorLog: true,
  skipPrefix: false,
  cache: false,
  skipAuth: false
}));

export const setContext = (params: Partial<HttpContextDefaults> = {}) => {
  return new HttpContext().set(httpContextDefaults, {
    ...params
  });
}


