import {TmClassnames} from "@app/shared/enums";
import {inject, Injectable} from '@angular/core';
import {AppStateService} from "@app/shared/services";
import {allSectionsDefaults, defaultEmptyPage} from "@app/shared/constants";
import {cloneObject, getElHeightWithMargins, isListSection} from "@app/shared/helpers";
import {TemplateConfigs, TemplateSection, TemplateSectionItem} from "@app/shared/interfaces";

const pagePaddingTop = 50;

@Injectable({
  providedIn: 'root'
})
export class SidebarTmSplitLogicService {
  private readonly appStateService = inject(AppStateService);

  constructor() {
  }

  public sidebarSplitPages(template: TemplateConfigs) {
    this.rightSideSplit(template);
    this.sidebarSideSplit(template);
  }

  /** Sidebar Right Split Start */

  private rightSideSplit(template: TemplateConfigs) {
    const templateEl = document.querySelector('.template-main-container')!;
    const pageELList = templateEl.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);
    const sf_line_el = templateEl.querySelector(`.right-side`)?.querySelector('.sf-line') as HTMLElement;

    const lineElHeight = getElHeightWithMargins(sf_line_el);

    pageELList.forEach((pageEL, index) => {
      const pageElRightSideSizes = pageEL.querySelector('.right-side')?.getBoundingClientRect();

      const pageRightSideBodyEl = pageEL.querySelector('.right-side-body');

      if (!pageRightSideBodyEl) {
        return;
      }

      const pageBodyElSizes = pageRightSideBodyEl.getBoundingClientRect();

      let size = pageElRightSideSizes!.height;

      if (index === 0) {
        size = size - (pageBodyElSizes!.top - pageElRightSideSizes!.top) // + sidebarTop2ElHeight;
      } else {
        size = size - pagePaddingTop;
      }

      if (pageBodyElSizes.bottom > pageEL.getBoundingClientRect().bottom) {
        this.addRightSideSectionLogic(template, index);
      } else {

        if (pageELList[index + 1]) {

          console.log('The case smaller, page index ->', index);

          const page = template.pages.items[index + 1];

          if (!page) {
            return;
          }

          const nextPageEl = pageELList[index + 1];
          const nextPageElBody = nextPageEl.querySelector(`.right-side .right-side-body`);

          const pageFirstSection = page.sidebarRightSection.items[0];

          if (!pageFirstSection) {
            return;
          }

          const prevPage = template.pages.items[index];
          const prevPageLastSection = prevPage.sidebarRightSection.items[prevPage.sidebarRightSection.items.length - 1];

          const firstSecEL = nextPageElBody?.children[0];

          if (!firstSecEL) {
            return;
          }

          const firstSecELSizes = firstSecEL!.getBoundingClientRect();

          if (isListSection(pageFirstSection)) {

            console.log('list sidebarRightSection case');

            if ((pageBodyElSizes!.height + firstSecELSizes.height) + lineElHeight <= size) {

              console.log('go back list', pageFirstSection);

              page.sidebarRightSection.items.shift();
              prevPage.sidebarRightSection.items.push(pageFirstSection);
              this.appStateService.saveChanges();

              if (page.sidebarRightSection.items.length === 0 && page.sidebarSection.items.length === 0) {
                template.pages.items.splice(index + 1, 1);
                this.appStateService.saveChanges();
              }
            }
          } else {

            console.log('NON list sidebarRightSection case');

            if (pageFirstSection.type === prevPageLastSection?.type) {
              console.log('Same type', pageFirstSection.type);

              const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
              const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

              if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + 12 <= size) {
                const first = pageFirstSection.items.shift() as TemplateSectionItem;

                prevPageLastSection.items = [...prevPageLastSection.items, first];

                if (pageFirstSection.items.length === 0) {
                  page.sidebarRightSection.items.splice(0, 1);
                  this.appStateService.saveChanges();
                }

                if (page.sidebarRightSection.items.length === 0 && page.sidebarSection.items.length === 0) {
                  template.pages.items.splice(index + 1, 1);
                  this.appStateService.saveChanges();
                }

              }
            } else {

              console.log('Dif Types');

              if (pageFirstSection.items.length === 1) {

                console.log('length is 1');
                const lastSecBodyEL = nextPageEl.querySelector(`.right-side .right-side-body`);
                const lastSecEL = lastSecBodyEL?.children[0];
                const lastSecELSizes = lastSecEL!.getBoundingClientRect();

                if ((pageBodyElSizes!.height + lastSecELSizes.height) + lineElHeight <= size) {

                  const first = page.sidebarRightSection.items.shift() as TemplateSection;

                  prevPage.sidebarRightSection.items.push(first);
                  this.appStateService.saveChanges();

                  if (page.sidebarRightSection.items.length === 0 && page.sidebarSection.items.length === 0) {
                    template.pages.items.splice(index + 1, 1);
                    this.appStateService.saveChanges();
                  }
                }
              } else {

                console.log('Length is bigger');

                const workExpPartTitleEL: HTMLDivElement | null = firstSecEL!.querySelector('.work-experience-part .w-e-t-w-t');
                const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
                const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

                const blockTitle = workExpPartTitleEL?.clientHeight || 44;

                console.log('blockTitle', blockTitle);

                if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + blockTitle + lineElHeight <= size) {
                  const first = pageFirstSection.items.shift() as TemplateSectionItem;

                  const newSection = cloneObject(allSectionsDefaults[pageFirstSection.type]);
                  newSection.items = [first];

                  prevPage.sidebarRightSection.items.push(newSection);
                  this.appStateService.saveChanges();
                }

              }

            }

          }

        }

      }


      console.log('============================================');
    });
  }

  private addRightSideSectionLogic(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const lastSection = page?.sidebarRightSection.items[page.sidebarRightSection.items.length - 1];

    if (!lastSection) {
      return;
    }

    if (isListSection(lastSection)) {
      this.addRightSideListSection(template, index);
    } else {
      this.addRightSideNotListSection(template, index);
    }
  }

  private addRightSideListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.sidebarRightSection.items.pop() as TemplateSection;

    if (!nextPage) {
      const defaultPage = cloneObject(defaultEmptyPage);
      defaultPage.sidebarRightSection.items = [lastSection];

      template.pages.items.push(defaultPage);
      this.appStateService.saveChanges();

      this.appStateService.saveChanges();
    } else {
      nextPage.sidebarRightSection.items.unshift(lastSection);
      this.appStateService.saveChanges();
    }
  }

  private addRightSideNotListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];

    if (!page) {
      return;
    }

    const nextPage = template.pages.items[index + 1];

    const lastSection = page.sidebarRightSection.items[page.sidebarRightSection.items.length - 1];
    const lastSectionLastItem = lastSection.items[lastSection.items.length - 1];

    if (!nextPage) {
      const newPage = cloneObject(defaultEmptyPage);

      if (lastSection.items.length === 1) {
        newPage.sidebarRightSection.items = [lastSection];
        page.sidebarRightSection.items.pop();
      } else {
        const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
        newSection.items = [lastSectionLastItem];
        newSection.title = lastSection.title;

        newPage.sidebarRightSection.items = [newSection];
        lastSection.items.pop();
      }

      template.pages.items.push(newPage);
      this.appStateService.saveChanges();

    } else {

      if (nextPage.sidebarRightSection.items.length === 0) {
        if (lastSection.items.length === 1) {
          nextPage.sidebarRightSection.items = [lastSection];
          page.sidebarRightSection.items.pop();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.sidebarRightSection.items = [newSection];
          lastSection.items.pop();
        }
        return;
      }

      const nextPageFirstSection = nextPage.sidebarRightSection.items[0];

      if (!nextPageFirstSection) {
        return;
      }

      if (lastSection.items.length === 1) {

        if (nextPageFirstSection.type === lastSection.type) {

          nextPageFirstSection.items.unshift(lastSectionLastItem);
          page.sidebarRightSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          nextPage.sidebarRightSection.items.unshift(lastSection);
          page.sidebarRightSection.items.pop();
          this.appStateService.saveChanges();
        }

      } else {
        if (nextPageFirstSection.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.sidebarRightSection.items.unshift(newSection);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        }
      }

    }

  }

  /** Sidebar Right Split End */

  /** Sidebar Split Start */

  private sidebarSideSplit(template: TemplateConfigs) {
    const templateEl = document.querySelector('.template-main-container');
    const pageELList = templateEl?.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);
    const sf_line_el = templateEl?.querySelector(`.left-side`)?.querySelector('.sf-line') as HTMLElement;

    const lineElHeight = getElHeightWithMargins(sf_line_el);

    pageELList?.forEach((pageEL, index) => {
      const pageElSizes = pageEL.querySelector('.left-side')?.getBoundingClientRect();

      const pageBodyEl = pageEL.querySelector('.left-side-content');
      const pageBodyElSizes = pageBodyEl?.getBoundingClientRect();

      if (!pageBodyEl) {
        return;
      }

      let size = pageElSizes!.height; // 1348

      if (index === 0) {
        size = size - (pageBodyElSizes!.top - pageElSizes!.top);
      } else {
        size = size - pagePaddingTop;
      }

      if (pageBodyElSizes!.bottom > pageEL.getBoundingClientRect().bottom) {
        this.addSidebarSectionLogic(template, index);
      } else {

        if (pageELList[index + 1]) {

          console.log('The case smaller, page index ->', index);

          const page = template.pages.items[index + 1];

          if (!page) {
            return;
          }

          const nextPageEl = pageELList[index + 1];
          const nextPageElBody = nextPageEl.querySelector(`.left-side .left-side-content`);

          const pageFirstSection = page.sidebarSection.items[0];

          if (!pageFirstSection) {
            if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
              template.pages.items.splice(index + 1, 1);
              this.appStateService.saveChanges();
            }
            return;
          }

          const prevPage = template.pages.items[index];
          const prevPageLastSection = prevPage.sidebarSection.items[prevPage.sidebarSection.items.length - 1];

          const firstSecEL = nextPageElBody?.children[0];

          if (!firstSecEL) {
            return;
          }

          const firstSecELSizes = firstSecEL.getBoundingClientRect();

          if (isListSection(pageFirstSection)) {

            console.log('list sidebarSection case');

            if ((pageBodyElSizes!.height + firstSecELSizes.height) + lineElHeight <= size) {

              page.sidebarSection.items.shift();
              prevPage.sidebarSection.items.push(pageFirstSection);
              this.appStateService.saveChanges();

              if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
                template.pages.items.splice(index + 1, 1);
                this.appStateService.saveChanges();
              }
            }
          } else {

            console.log('NON list sidebarSection case');

            if (pageFirstSection.type === prevPageLastSection?.type) {
              const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
              const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

              console.log('same type');

              if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + 12 <= size) {
                const first = pageFirstSection.items.shift() as TemplateSectionItem;

                prevPageLastSection.items = [...prevPageLastSection.items, first];

                if (pageFirstSection.items.length === 0) {
                  page.sidebarSection.items.splice(0, 1);
                  this.appStateService.saveChanges();
                }

                if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
                  template.pages.items.splice(index + 1, 1);
                  this.appStateService.saveChanges();
                }

              }
            } else {

              console.log('Dif Types');

              if (pageFirstSection.items.length === 1) {

                console.log('length is 1');
                const lastSecBodyEL = nextPageEl.querySelector(`.left-side .left-side-content`);
                const lastSecEL = lastSecBodyEL?.children[0];
                const lastSecELSizes = lastSecEL!.getBoundingClientRect();

                if ((pageBodyElSizes!.height + lastSecELSizes.height) + lineElHeight <= size) {

                  const first = page.sidebarSection.items.shift() as TemplateSection;

                  prevPage.sidebarSection.items.push(first);
                  this.appStateService.saveChanges();

                  if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
                    template.pages.items.splice(index + 1, 1);
                    this.appStateService.saveChanges();
                  }
                }
              } else {

                console.log('Length is bigger');

                const workExpPartTitleEL: HTMLDivElement | null = firstSecEL!.querySelector('.work-experience-part .w-e-t-w-t');
                const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
                const workExpFirstChildElSizes = workExpFirstChildEl.getBoundingClientRect();

                const blockTitle = workExpPartTitleEL?.clientHeight || 44;

                console.log('blockTitle', blockTitle);

                if ((pageBodyElSizes!.height + workExpFirstChildElSizes.height) + blockTitle + lineElHeight <= size) {
                  const first = pageFirstSection.items.shift() as TemplateSectionItem;

                  const newSection = cloneObject(allSectionsDefaults[pageFirstSection.type]);
                  newSection.items = [first];

                  prevPage.sidebarSection.items.push(newSection);
                  this.appStateService.saveChanges();
                }

              }

            }

          }

        }

      }

    });
  }

  private addSidebarSectionLogic(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const lastSection = page?.sidebarSection.items[page.sidebarSection.items.length - 1];

    if (!lastSection) {
      return;
    }

    if (isListSection(lastSection)) {
      this.addSidebarListSection(template, index);
    } else {
      this.addSidebarNotListSection(template, index);
    }
  }

  private addSidebarListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.sidebarSection.items.pop() as TemplateSection;

    if (!nextPage) {
      const defaultPage = cloneObject(defaultEmptyPage);
      defaultPage.sidebarSection.items = [lastSection];

      template.pages.items.push(defaultPage);
      this.appStateService.saveChanges();
    } else {
      nextPage.sidebarSection.items.unshift(lastSection);
      this.appStateService.saveChanges();
    }
  }

  private addSidebarNotListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.sidebarSection.items[page.sidebarSection.items.length - 1];
    const lastSectionLastItem = lastSection.items[lastSection.items.length - 1];

    if (!nextPage) {
      const newPage = cloneObject(defaultEmptyPage);

      if (lastSection.items.length === 1) {
        newPage.sidebarSection.items = [lastSection];
        page.sidebarSection.items.pop();
      } else {
        const newSection = cloneObject(allSectionsDefaults[lastSection.type]);

        newSection.items = [lastSectionLastItem];
        newPage.sidebarSection.items = [newSection];
        newSection.title = lastSection.title;

        lastSection.items.pop();
      }

      template.pages.items.push(newPage);
      this.appStateService.saveChanges();

    } else {

      if (nextPage.sidebarSection.items.length === 0) {
        if (lastSection.items.length === 1) {
          nextPage.sidebarSection.items = [lastSection];
          page.sidebarSection.items.pop();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);

          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.sidebarSection.items = [newSection];
          lastSection.items.pop();
        }
        return;
      }

      const nextPageFirstSection = nextPage.sidebarSection.items[0];

      if (lastSection.items.length === 1) {

        if (nextPageFirstSection.type === lastSection.type) {

          nextPageFirstSection.items.unshift(lastSectionLastItem);
          page.sidebarSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          nextPage.sidebarSection.items.unshift(lastSection);
          page.sidebarSection.items.pop();
          this.appStateService.saveChanges();
        }

      } else {
        if (nextPageFirstSection.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.sidebarSection.items.unshift(newSection);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        }
      }

    }

  }

  /** Sidebar Split End */

}
