import {NgIf} from "@angular/common";
import {NzRateModule} from "ng-zorro-antd/rate";
import {NzIconModule} from "ng-zorro-antd/icon";
import {Feedback} from "@app/shared/interfaces";
import {NzButtonModule} from "ng-zorro-antd/button";
import {AppStateService} from "@app/shared/services";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {SfTrimDirective} from "@app/shared/directives";
import {oneOfRequiredExclude} from "@app/shared/helpers";
import {NzUploadComponent, NzUploadFile} from "ng-zorro-antd/upload";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sf-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss'],
  imports: [
    NgIf,
    NzRateModule,
    NzIconModule,
    NzDividerModule,
    SfTrimDirective,
    ReactiveFormsModule,
    NzButtonModule,
    NzUploadComponent,
  ],
  standalone: true
})
export class SendFeedbackComponent implements OnInit {
  private readonly fb: FormBuilder = inject(FormBuilder);
  public readonly appStateService = inject(AppStateService);

  public fileList: NzUploadFile[] = [];
  public fileSizeError = false;

  @Input() public btnLoaderState = false
  @Output() public close$ = new EventEmitter<void>();
  @Output() public save$ = new EventEmitter<Feedback>();

  public feedbackForm = this.fb.group({
      stars: [0],
      email: [this.appStateService.user?.email, [Validators.email, Validators.required]],
      feedback: [null],
    },
    {validator: oneOfRequiredExclude('email')}
  );

  ngOnInit() {
  }

  public onCloseModal(): void {
    this.close$.emit();
  }

  public onSubmit() {
    if (this.feedbackForm.valid) {
      const feedbackRequest: Feedback = {
        ...this.feedbackForm.value,
        files: this.fileList
      };
      this.save$.emit(feedbackRequest);
    }
  }

  public beforeUpload = (file: NzUploadFile) => {
    const fileSize = file.size || 0;
    const maxSize = (1024 * 1024) * 5; // 5MB

    if (fileSize <= maxSize) {
      this.fileList = this.fileList.concat(file);
      this.fileSizeError = false;
    } else {
      this.fileSizeError = true;
    }

    return false;
  };

}
