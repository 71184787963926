import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter2: CoverLetter = {
  templateId: 2,
  forTemplate: 2,
  templateTopPartType: 2,
  ...cloneObject(coverLetterCommonConfigs)
};

