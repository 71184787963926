import {SingleCoverLetterThemeColor} from "@app/shared/interfaces";

// @ts-ignore
export const coverLetter6Themes: SingleCoverLetterThemeColor = {
  theme1: {
    id: 1,

    fontColor: '#303030',
    emptyImgColor: '#303030',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#ffffff',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#303030',

    linkColor: '#303030'
  }
}
