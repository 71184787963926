"use strict";

module.exports = function (Promise, INTERNAL, tryConvertToPromise, apiRejection, Proxyable) {
  var util = require("./util");
  var isArray = util.isArray;
  function toResolutionValue(val) {
    switch (val) {
      case -2:
        return [];
      case -3:
        return {};
    }
  }
  function PromiseArray(values) {
    var promise = this._promise = new Promise(INTERNAL);
    if (values instanceof Promise) {
      promise._propagateFrom(values, 3);
    }
    promise._setOnCancel(this);
    this._values = values;
    this._length = 0;
    this._totalResolved = 0;
    this._init(undefined, -2);
  }
  util.inherits(PromiseArray, Proxyable);
  PromiseArray.prototype.length = function () {
    return this._length;
  };
  PromiseArray.prototype.promise = function () {
    return this._promise;
  };
  PromiseArray.prototype._init = function init(_, resolveValueIfEmpty) {
    var values = tryConvertToPromise(this._values, this._promise);
    if (values instanceof Promise) {
      values = values._target();
      var bitField = values._bitField;
      ;
      this._values = values;
      if ((bitField & 50397184) === 0) {
        this._promise._setAsyncGuaranteed();
        return values._then(init, this._reject, undefined, this, resolveValueIfEmpty);
      } else if ((bitField & 33554432) !== 0) {
        values = values._value();
      } else if ((bitField & 16777216) !== 0) {
        return this._reject(values._reason());
      } else {
        return this._cancel();
      }
    }
    values = util.asArray(values);
    if (values === null) {
      var err = apiRejection("expecting an array or an iterable object but got " + util.classString(values)).reason();
      this._promise._rejectCallback(err, false);
      return;
    }
    if (values.length === 0) {
      if (resolveValueIfEmpty === -5) {
        this._resolveEmptyArray();
      } else {
        this._resolve(toResolutionValue(resolveValueIfEmpty));
      }
      return;
    }
    this._iterate(values);
  };
  PromiseArray.prototype._iterate = function (values) {
    var len = this.getActualLength(values.length);
    this._length = len;
    this._values = this.shouldCopyValues() ? new Array(len) : this._values;
    var result = this._promise;
    var isResolved = false;
    var bitField = null;
    for (var i = 0; i < len; ++i) {
      var maybePromise = tryConvertToPromise(values[i], result);
      if (maybePromise instanceof Promise) {
        maybePromise = maybePromise._target();
        bitField = maybePromise._bitField;
      } else {
        bitField = null;
      }
      if (isResolved) {
        if (bitField !== null) {
          maybePromise.suppressUnhandledRejections();
        }
      } else if (bitField !== null) {
        if ((bitField & 50397184) === 0) {
          maybePromise._proxy(this, i);
          this._values[i] = maybePromise;
        } else if ((bitField & 33554432) !== 0) {
          isResolved = this._promiseFulfilled(maybePromise._value(), i);
        } else if ((bitField & 16777216) !== 0) {
          isResolved = this._promiseRejected(maybePromise._reason(), i);
        } else {
          isResolved = this._promiseCancelled(i);
        }
      } else {
        isResolved = this._promiseFulfilled(maybePromise, i);
      }
    }
    if (!isResolved) result._setAsyncGuaranteed();
  };
  PromiseArray.prototype._isResolved = function () {
    return this._values === null;
  };
  PromiseArray.prototype._resolve = function (value) {
    this._values = null;
    this._promise._fulfill(value);
  };
  PromiseArray.prototype._cancel = function () {
    if (this._isResolved() || !this._promise._isCancellable()) return;
    this._values = null;
    this._promise._cancel();
  };
  PromiseArray.prototype._reject = function (reason) {
    this._values = null;
    this._promise._rejectCallback(reason, false);
  };
  PromiseArray.prototype._promiseFulfilled = function (value, index) {
    this._values[index] = value;
    var totalResolved = ++this._totalResolved;
    if (totalResolved >= this._length) {
      this._resolve(this._values);
      return true;
    }
    return false;
  };
  PromiseArray.prototype._promiseCancelled = function () {
    this._cancel();
    return true;
  };
  PromiseArray.prototype._promiseRejected = function (reason) {
    this._totalResolved++;
    this._reject(reason);
    return true;
  };
  PromiseArray.prototype._resultCancelled = function () {
    if (this._isResolved()) return;
    var values = this._values;
    this._cancel();
    if (values instanceof Promise) {
      values.cancel();
    } else {
      for (var i = 0; i < values.length; ++i) {
        if (values[i] instanceof Promise) {
          values[i].cancel();
        }
      }
    }
  };
  PromiseArray.prototype.shouldCopyValues = function () {
    return true;
  };
  PromiseArray.prototype.getActualLength = function (len) {
    return len;
  };
  return PromiseArray;
};