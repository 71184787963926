import {Template} from "@app/shared/interfaces";
import {Pipe, PipeTransform} from '@angular/core';
import {isTmHasPaidFeature} from "@app/shared/helpers";
import {AppStateService} from "@app/shared/services";

@Pipe({
  name: 'isTmHasPaidFeature',
  standalone: true
})
export class IsTmHasPaidFeaturePipe implements PipeTransform {

  transform(template: Template, appStateService: AppStateService) {
    return isTmHasPaidFeature(template, appStateService);
  }

}
