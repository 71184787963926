import { HttpClient } from "@angular/common/http";
import {inject, Injectable} from '@angular/core';
import {setContext} from "@app/shared/constants";
import {
  AmeriaBankInit,
  AmeriaBankInitRes,
  AmeriaPaymentCancel,
  AmeriaPaymentConfirmRes,
  AmeriaTransaction,
  Billing,
  BillingTransaction,
  PaymentData,
  UserLocation,
  UserSubscription
} from "@app/shared/interfaces";
import {switchMap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private readonly httpClient = inject(HttpClient);

  constructor() {
  }

  public ameriaPaymentInit(body: AmeriaBankInit) {
    return this.httpClient.post<AmeriaBankInitRes>('api/v1/payments/init', body);
  }

  public confirmAmeriaPayment(paymentId: string) {
    return this.httpClient.post<AmeriaPaymentConfirmRes>('api/v1/payments/confirm', {paymentId});
  }

  public getAmeriaTransactions() {
    return this.httpClient.get<AmeriaTransaction[]>('api/v1/payments/transactions');
  }

  public ameriaPaymentCancel(paymentId: string) {
    return this.httpClient.post<AmeriaPaymentCancel>('api/v1/payments/cancel', {paymentId});
  }

  private getIpAddress() {
    return this.httpClient.get('https://api.ipify.org/', {
      responseType: 'text',
      context: setContext({
        skipPrefix: true,
        skipAuth: true
      })
    });
  }

  public getUserLocation() {
    return this.getIpAddress()
      .pipe(
        switchMap((ip) => {
          return this.httpClient.get<UserLocation>(
            `https://ipinfo.io/${ip}?token=ad94a59ba46c8c`,
            {
              context: setContext({
                skipPrefix: true,
                skipAuth: true
              })
            });
        })
      );
  }

  public getBillingTransactions() {
    return this.httpClient.get<BillingTransaction[]>('api/v1/payments/transactions');
  }

  public getPaymentPrices() {
    return this.httpClient.jsonp<PaymentData>(
      'https://sandbox-checkout.paddle.com/api/2.0/prices?product_ids=51538,51923,51925',
      'callback',
    );
  }

  public getAPIAddress() {
    return this.httpClient.get(
      'https://api.ipify.org/',
      {
        responseType: 'text',
        context: setContext({skipPrefix: true, skipAuth: true})
      }
    );
  }

  public getCountyCode() {
    return this.httpClient.get(
      'https://api.ipify.org/country',
      {
        responseType: 'text',
        context: setContext({skipPrefix: true, skipAuth: true})
      }
    );
  }

  public getReferralBonus() {
    return this.httpClient.post('api/v1/payments/referral-bonus', {});
  }

  public applyCoupon(body: { coupon: string }) {
    return this.httpClient.post<{ discount_code: string }>('api/v1/coupon/apply', body); // discount_code: "3BS1BTK6EN"
  }

  public getSubscriptions() {
    return this.httpClient.get<UserSubscription>('api/v1/payments/subscription');
  }

  public cancelSubscription(subscriptionId: number) {
    return this.httpClient.post<UserSubscription>('api/v1/payments/cancel-subscription', {
      subscriptionId
    });
  }

  public changePlan(plan: Billing, subscription: UserSubscription) {
    return this.httpClient.post(
      'api/v1/payments/change-plan',
      {
        subscriptionId: subscription.subscription_id,
        newPlanId: plan.plan_id,
        activeSubscription: !subscription.paused_at,
        currentPlanId: subscription.plan_id
      }
    );
  }

  public reSubscribe(subscription: UserSubscription) {
    return this.httpClient.post<UserSubscription>(
      'api/v1/payments/change-plan',
      {
        subscriptionId: subscription.subscription_id,
        newPlanId: subscription.plan_id,
        activeSubscription: false,
      }
    );
  }

}
