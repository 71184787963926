import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter5: CoverLetter = {
  templateId: 5,
  forTemplate: 5,
  templateTopPartType: 3,
  ...cloneObject(coverLetterCommonConfigs)
};

