import {Router} from "@angular/router";

export const removeQueryParams = (router: Router) => {
  const currentUrl = router.url.split('?')[0];

  // Navigate to the current route without query params
  router.navigate([currentUrl], {queryParams: {}});
}

export const parseQueryParams = (queryString: string): any => {
  // Remove the leading '?' if it exists
  const query = queryString.startsWith('?') ? queryString.slice(1) : queryString;

  // Split the query string into key-value pairs
  const pairs = query.split('&');

  // Reduce the pairs into an object
  const params = pairs.reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    // @ts-ignore
    acc[decodeURIComponent(key)] = decodeURIComponent(value || '');
    return acc;
  }, {});

  return params;
}

