import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[sfCtrlA]',
  standalone: true
})
export class CtrlADirective {

  @Output() sfCtrlA = new EventEmitter<KeyboardEvent>();

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
      this.sfCtrlA.emit(event);
    }
  }

}
