import html2canvas from "html2canvas";
import {canvasDownload} from "@app/shared/helpers";
import {inject, Injectable, Type} from '@angular/core';
import {DocumentTypes, TemplateTypes} from "@app/shared/enums";
import {Template, TemplateConfigs} from "@app/shared/interfaces";
import {AppStateService} from "@app/shared/services/app-state.service";
import {OldFactoryService} from "@app/shared/services/old-factory.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardLogicService {
  private readonly appStateService = inject(AppStateService);
  private readonly oldFactoryService = inject(OldFactoryService);

  constructor() {
  }

  public downloadAsImage(component: Type<any>, template: Template, callBack?: () => void) {

    const {domElem, componentRef} = this.oldFactoryService
      .create(component, {inputs: {template: template}});

    const tmEl = domElem.firstChild as HTMLDivElement;

    document.body.appendChild(tmEl);

    setTimeout(() => {
      this.removeEmptyElements(tmEl);

      switch (template.documentType) {
        case DocumentTypes.CV: {
          const pages = tmEl.querySelectorAll('.template-page-parent');

          pages.forEach((page, index) => {
            setTimeout(() => {

              const user = this.appStateService.user;
              const imgName = `${user?.firstName}.${user?.lastName}_CV${pages.length > 1 ? '_page' + index + 1 : ''}-selfcv.png`;

              if (index === 0) {
                const summarySfLine = page.querySelector('.summary-sf-line') as HTMLDivElement;

                if (summarySfLine) {
                  summarySfLine.style.marginBottom = '0';
                  summarySfLine.style.paddingBottom = '16px';
                }
              }

              // @ts-ignore
              html2canvas(page, {
                scale: 2,
                x: 0,
                y: 0,
                useCORS: true
              })
                .then(canvas => {
                  canvasDownload(canvas, imgName);
                  tmEl.remove();
                  this.oldFactoryService.destroyComponent(componentRef);
                })
                .finally(() => callBack?.());
            }, 100);
          });

          break;
        }
        case DocumentTypes.COVER: {
          setTimeout(() => {

            const user = this.appStateService.user;
            const imgName = `${user?.firstName}.${user?.lastName}_cover-letter-selfcv.png`;

            // @ts-ignore
            html2canvas(tmEl, {scale: 2, useCORS: true})
              .then(canvas => {
                canvasDownload(canvas, imgName);
                tmEl.remove();
                this.oldFactoryService.destroyComponent(componentRef);
              })
              .finally(() => callBack?.());
          }, 100);
          break;
        }
      }
    });
  }

  private removeEmptyImg(template: Template, comDom: HTMLElement) {
    const emptyImgEl = comDom.querySelector('.empty-image');
    if (emptyImgEl) {
      comDom.querySelector('.img-part-wrapper')?.remove();

      if ((template.settings as TemplateConfigs)?.type === TemplateTypes.SIDEBAR) {
        if ((!template.settings.img.state || [4].includes(template.templateId))) {
          const onlyFirstSidebar = comDom.querySelector('.left-side-content') as HTMLDivElement;
          if (onlyFirstSidebar) {
            onlyFirstSidebar.style.paddingTop = '36x';
          }
        }
      }
    }
  }

  private removeEmptyElements(templateEl: HTMLDivElement) {

    const childElements = Array.from(templateEl.children);

    console.log(templateEl);

    templateEl.querySelector('.sidebar-reverse-wr')?.remove();


    childElements.forEach((el) => {
      (el as HTMLDivElement).style.borderRadius = '0';
      const sidebarEL = el.querySelector('.left-side') as HTMLDivElement;

      if (sidebarEL) {
        sidebarEL.style.borderRadius = '0';
      }
    });

    childElements
      .filter((el) => el.classList.contains('pages-space'))
      .forEach((el) => el?.remove());

    childElements.forEach((el) => el.querySelector('.empty-section')?.remove());
  }

}

