export const canvasDownload = (canvas: HTMLCanvasElement, name = 'image.png',  type = 'image/png') => {
  const imgData = canvas.toDataURL(type);

  const downloadLink = document.createElement('a');
  downloadLink.href = imgData;
  downloadLink.download = `${name}`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
