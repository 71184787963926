import {NgControl} from '@angular/forms';
import {Directive, HostListener, inject} from '@angular/core';

@Directive({
  selector: '[sfTrim]',
  standalone: true
})
export class SfTrimDirective {
  private readonly ngControl = inject(NgControl);

  @HostListener('blur')
  private onBlur(): void {
    let value = this.ngControl.value;

    if (value) {
      if (typeof value === "string") {
        value = value.trim();

        this.ngControl.control?.setValue(value);
        this.ngControl.valueAccessor?.writeValue(value);
      }
      return value
    } else {
      this.ngControl.control?.setValue('');
      this.ngControl.valueAccessor?.writeValue('');
    }
  }

}
