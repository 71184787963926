export const phosphorIcons: Readonly<{ name: string }[]> = [
  {
    name: "ph-address-book"
  },
  {
    name: "ph-air-traffic-control"
  },
  {
    name: "ph-airplane-in-flight"
  },
  {
    name: "ph-airplane-landing"
  },
  {
    name: "ph-airplane-takeoff"
  },
  {
    name: "ph-airplane-tilt"
  },
  {
    name: "ph-airplane"
  },
  {
    name: "ph-airplay"
  },
  {
    name: "ph-alarm"
  },
  {
    name: "ph-alien"
  },
  {
    name: "ph-align-bottom-simple"
  },
  {
    name: "ph-align-bottom"
  },
  {
    name: "ph-align-center-horizontal-simple"
  },
  {
    name: "ph-align-center-horizontal"
  },
  {
    name: "ph-align-center-vertical-simple"
  },
  {
    name: "ph-align-center-vertical"
  },
  {
    name: "ph-align-left-simple"
  },
  {
    name: "ph-align-left"
  },
  {
    name: "ph-align-right-simple"
  },
  {
    name: "ph-align-right"
  },
  {
    name: "ph-align-top-simple"
  },
  {
    name: "ph-align-top"
  },
  {
    name: "ph-amazon-logo"
  },
  {
    name: "ph-anchor-simple"
  },
  {
    name: "ph-anchor"
  },
  {
    name: "ph-android-logo"
  },
  {
    name: "ph-angular-logo"
  },
  {
    name: "ph-aperture"
  },
  {
    name: "ph-app-store-logo"
  },
  {
    name: "ph-app-window"
  },
  {
    name: "ph-apple-logo"
  },
  {
    name: "ph-apple-podcasts-logo"
  },
  {
    name: "ph-archive-box"
  },
  {
    name: "ph-archive-tray"
  },
  {
    name: "ph-archive"
  },
  {
    name: "ph-armchair"
  },
  {
    name: "ph-arrow-arc-left"
  },
  {
    name: "ph-arrow-arc-right"
  },
  {
    name: "ph-arrow-bend-double-up-left"
  },
  {
    name: "ph-arrow-bend-double-up-right"
  },
  {
    name: "ph-arrow-bend-down-left"
  },
  {
    name: "ph-arrow-bend-down-right"
  },
  {
    name: "ph-arrow-bend-left-down"
  },
  {
    name: "ph-arrow-bend-left-up"
  },
  {
    name: "ph-arrow-bend-right-down"
  },
  {
    name: "ph-arrow-bend-right-up"
  },
  {
    name: "ph-arrow-bend-up-left"
  },
  {
    name: "ph-arrow-bend-up-right"
  },
  {
    name: "ph-arrow-circle-down-left"
  },
  {
    name: "ph-arrow-circle-down-right"
  },
  {
    name: "ph-arrow-circle-down"
  },
  {
    name: "ph-arrow-circle-left"
  },
  {
    name: "ph-arrow-circle-right"
  },
  {
    name: "ph-arrow-circle-up-left"
  },
  {
    name: "ph-arrow-circle-up-right"
  },
  {
    name: "ph-arrow-circle-up"
  },
  {
    name: "ph-arrow-clockwise"
  },
  {
    name: "ph-arrow-counter-clockwise"
  },
  {
    name: "ph-arrow-down-left"
  },
  {
    name: "ph-arrow-down-right"
  },
  {
    name: "ph-arrow-down"
  },
  {
    name: "ph-arrow-elbow-down-left"
  },
  {
    name: "ph-arrow-elbow-down-right"
  },
  {
    name: "ph-arrow-elbow-left-down"
  },
  {
    name: "ph-arrow-elbow-left-up"
  },
  {
    name: "ph-arrow-elbow-left"
  },
  {
    name: "ph-arrow-elbow-right-down"
  },
  {
    name: "ph-arrow-elbow-right-up"
  },
  {
    name: "ph-arrow-elbow-right"
  },
  {
    name: "ph-arrow-elbow-up-left"
  },
  {
    name: "ph-arrow-elbow-up-right"
  },
  {
    name: "ph-arrow-fat-down"
  },
  {
    name: "ph-arrow-fat-left"
  },
  {
    name: "ph-arrow-fat-line-down"
  },
  {
    name: "ph-arrow-fat-line-left"
  },
  {
    name: "ph-arrow-fat-line-right"
  },
  {
    name: "ph-arrow-fat-line-up"
  },
  {
    name: "ph-arrow-fat-lines-down"
  },
  {
    name: "ph-arrow-fat-lines-left"
  },
  {
    name: "ph-arrow-fat-lines-right"
  },
  {
    name: "ph-arrow-fat-lines-up"
  },
  {
    name: "ph-arrow-fat-right"
  },
  {
    name: "ph-arrow-fat-up"
  },
  {
    name: "ph-arrow-left"
  },
  {
    name: "ph-arrow-line-down-left"
  },
  {
    name: "ph-arrow-line-down-right"
  },
  {
    name: "ph-arrow-line-down"
  },
  {
    name: "ph-arrow-line-left"
  },
  {
    name: "ph-arrow-line-right"
  },
  {
    name: "ph-arrow-line-up-left"
  },
  {
    name: "ph-arrow-line-up-right"
  },
  {
    name: "ph-arrow-line-up"
  },
  {
    name: "ph-arrow-right"
  },
  {
    name: "ph-arrow-square-down-left"
  },
  {
    name: "ph-arrow-square-down-right"
  },
  {
    name: "ph-arrow-square-down"
  },
  {
    name: "ph-arrow-square-in"
  },
  {
    name: "ph-arrow-square-left"
  },
  {
    name: "ph-arrow-square-out"
  },
  {
    name: "ph-arrow-square-right"
  },
  {
    name: "ph-arrow-square-up-left"
  },
  {
    name: "ph-arrow-square-up-right"
  },
  {
    name: "ph-arrow-square-up"
  },
  {
    name: "ph-arrow-u-down-left"
  },
  {
    name: "ph-arrow-u-down-right"
  },
  {
    name: "ph-arrow-u-left-down"
  },
  {
    name: "ph-arrow-u-left-up"
  },
  {
    name: "ph-arrow-u-right-down"
  },
  {
    name: "ph-arrow-u-right-up"
  },
  {
    name: "ph-arrow-u-up-left"
  },
  {
    name: "ph-arrow-u-up-right"
  },
  {
    name: "ph-arrow-up-left"
  },
  {
    name: "ph-arrow-up-right"
  },
  {
    name: "ph-arrow-up"
  },
  {
    name: "ph-arrows-clockwise"
  },
  {
    name: "ph-arrows-counter-clockwise"
  },
  {
    name: "ph-arrows-down-up"
  },
  {
    name: "ph-arrows-horizontal"
  },
  {
    name: "ph-arrows-in-cardinal"
  },
  {
    name: "ph-arrows-in-line-horizontal"
  },
  {
    name: "ph-arrows-in-line-vertical"
  },
  {
    name: "ph-arrows-in-simple"
  },
  {
    name: "ph-arrows-in"
  },
  {
    name: "ph-arrows-left-right"
  },
  {
    name: "ph-arrows-merge"
  },
  {
    name: "ph-arrows-out-cardinal"
  },
  {
    name: "ph-arrows-out-line-horizontal"
  },
  {
    name: "ph-arrows-out-line-vertical"
  },
  {
    name: "ph-arrows-out-simple"
  },
  {
    name: "ph-arrows-out"
  },
  {
    name: "ph-arrows-split"
  },
  {
    name: "ph-arrows-vertical"
  },
  {
    name: "ph-article-medium"
  },
  {
    name: "ph-article-ny-times"
  },
  {
    name: "ph-article"
  },
  {
    name: "ph-asterisk-simple"
  },
  {
    name: "ph-asterisk"
  },
  {
    name: "ph-at"
  },
  {
    name: "ph-atom"
  },
  {
    name: "ph-baby"
  },
  {
    name: "ph-backpack"
  },
  {
    name: "ph-backspace"
  },
  {
    name: "ph-bag-simple"
  },
  {
    name: "ph-bag"
  },
  {
    name: "ph-balloon"
  },
  {
    name: "ph-bandaids"
  },
  {
    name: "ph-bank"
  },
  {
    name: "ph-barbell"
  },
  {
    name: "ph-barcode"
  },
  {
    name: "ph-barricade"
  },
  {
    name: "ph-baseball-cap"
  },
  {
    name: "ph-baseball"
  },
  {
    name: "ph-basket"
  },
  {
    name: "ph-basketball"
  },
  {
    name: "ph-bathtub"
  },
  {
    name: "ph-battery-charging-vertical"
  },
  {
    name: "ph-battery-charging"
  },
  {
    name: "ph-battery-empty"
  },
  {
    name: "ph-battery-full"
  },
  {
    name: "ph-battery-high"
  },
  {
    name: "ph-battery-low"
  },
  {
    name: "ph-battery-medium"
  },
  {
    name: "ph-battery-plus-vertical"
  },
  {
    name: "ph-battery-plus"
  },
  {
    name: "ph-battery-vertical-empty"
  },
  {
    name: "ph-battery-vertical-full"
  },
  {
    name: "ph-battery-vertical-high"
  },
  {
    name: "ph-battery-vertical-low"
  },
  {
    name: "ph-battery-vertical-medium"
  },
  {
    name: "ph-battery-warning-vertical"
  },
  {
    name: "ph-battery-warning"
  },
  {
    name: "ph-bed"
  },
  {
    name: "ph-beer-bottle"
  },
  {
    name: "ph-beer-stein"
  },
  {
    name: "ph-behance-logo"
  },
  {
    name: "ph-bell-ringing"
  },
  {
    name: "ph-bell-simple-ringing"
  },
  {
    name: "ph-bell-simple-slash"
  },
  {
    name: "ph-bell-simple-z"
  },
  {
    name: "ph-bell-simple"
  },
  {
    name: "ph-bell-slash"
  },
  {
    name: "ph-bell-z"
  },
  {
    name: "ph-bell"
  },
  {
    name: "ph-bezier-curve"
  },
  {
    name: "ph-bicycle"
  },
  {
    name: "ph-binoculars"
  },
  {
    name: "ph-bird"
  },
  {
    name: "ph-bluetooth-connected"
  },
  {
    name: "ph-bluetooth-slash"
  },
  {
    name: "ph-bluetooth-x"
  },
  {
    name: "ph-bluetooth"
  },
  {
    name: "ph-boat"
  },
  {
    name: "ph-bone"
  },
  {
    name: "ph-book-bookmark"
  },
  {
    name: "ph-book-open-text"
  },
  {
    name: "ph-book-open"
  },
  {
    name: "ph-book"
  },
  {
    name: "ph-bookmark-simple"
  },
  {
    name: "ph-bookmark"
  },
  {
    name: "ph-bookmarks-simple"
  },
  {
    name: "ph-bookmarks"
  },
  {
    name: "ph-books"
  },
  {
    name: "ph-boot"
  },
  {
    name: "ph-bounding-box"
  },
  {
    name: "ph-bowl-food"
  },
  {
    name: "ph-brackets-angle"
  },
  {
    name: "ph-brackets-curly"
  },
  {
    name: "ph-brackets-round"
  },
  {
    name: "ph-brackets-square"
  },
  {
    name: "ph-brain"
  },
  {
    name: "ph-brandy"
  },
  {
    name: "ph-bridge"
  },
  {
    name: "ph-briefcase-metal"
  },
  {
    name: "ph-briefcase"
  },
  {
    name: "ph-broadcast"
  },
  {
    name: "ph-broom"
  },
  {
    name: "ph-browser"
  },
  {
    name: "ph-browsers"
  },
  {
    name: "ph-bug-beetle"
  },
  {
    name: "ph-bug-droid"
  },
  {
    name: "ph-bug"
  },
  {
    name: "ph-buildings"
  },
  {
    name: "ph-bus"
  },
  {
    name: "ph-butterfly"
  },
  {
    name: "ph-cactus"
  },
  {
    name: "ph-cake"
  },
  {
    name: "ph-calculator"
  },
  {
    name: "ph-calendar-blank"
  },
  {
    name: "ph-calendar-check"
  },
  {
    name: "ph-calendar-plus"
  },
  {
    name: "ph-calendar-x"
  },
  {
    name: "ph-calendar"
  },
  {
    name: "ph-call-bell"
  },
  {
    name: "ph-camera-plus"
  },
  {
    name: "ph-camera-rotate"
  },
  {
    name: "ph-camera-slash"
  },
  {
    name: "ph-camera"
  },
  {
    name: "ph-campfire"
  },
  {
    name: "ph-car-profile"
  },
  {
    name: "ph-car-simple"
  },
  {
    name: "ph-car"
  },
  {
    name: "ph-cardholder"
  },
  {
    name: "ph-cards"
  },
  {
    name: "ph-caret-circle-double-down"
  },
  {
    name: "ph-caret-circle-double-left"
  },
  {
    name: "ph-caret-circle-double-right"
  },
  {
    name: "ph-caret-circle-double-up"
  },
  {
    name: "ph-caret-circle-down"
  },
  {
    name: "ph-caret-circle-left"
  },
  {
    name: "ph-caret-circle-right"
  },
  {
    name: "ph-caret-circle-up-down"
  },
  {
    name: "ph-caret-circle-up"
  },
  {
    name: "ph-caret-double-down"
  },
  {
    name: "ph-caret-double-left"
  },
  {
    name: "ph-caret-double-right"
  },
  {
    name: "ph-caret-double-up"
  },
  {
    name: "ph-caret-down"
  },
  {
    name: "ph-caret-left"
  },
  {
    name: "ph-caret-right"
  },
  {
    name: "ph-caret-up-down"
  },
  {
    name: "ph-caret-up"
  },
  {
    name: "ph-carrot"
  },
  {
    name: "ph-cassette-tape"
  },
  {
    name: "ph-castle-turret"
  },
  {
    name: "ph-cat"
  },
  {
    name: "ph-cell-signal-full"
  },
  {
    name: "ph-cell-signal-high"
  },
  {
    name: "ph-cell-signal-low"
  },
  {
    name: "ph-cell-signal-medium"
  },
  {
    name: "ph-cell-signal-none"
  },
  {
    name: "ph-cell-signal-slash"
  },
  {
    name: "ph-cell-signal-x"
  },
  {
    name: "ph-certificate"
  },
  {
    name: "ph-chair"
  },
  {
    name: "ph-chalkboard-simple"
  },
  {
    name: "ph-chalkboard-teacher"
  },
  {
    name: "ph-chalkboard"
  },
  {
    name: "ph-champagne"
  },
  {
    name: "ph-charging-station"
  },
  {
    name: "ph-chart-bar-horizontal"
  },
  {
    name: "ph-chart-bar"
  },
  {
    name: "ph-chart-donut"
  },
  {
    name: "ph-chart-line-down"
  },
  {
    name: "ph-chart-line-up"
  },
  {
    name: "ph-chart-line"
  },
  {
    name: "ph-chart-pie-slice"
  },
  {
    name: "ph-chart-pie"
  },
  {
    name: "ph-chart-polar"
  },
  {
    name: "ph-chart-scatter"
  },
  {
    name: "ph-chat-centered-dots"
  },
  {
    name: "ph-chat-centered-text"
  },
  {
    name: "ph-chat-centered"
  },
  {
    name: "ph-chat-circle-dots"
  },
  {
    name: "ph-chat-circle-text"
  },
  {
    name: "ph-chat-circle"
  },
  {
    name: "ph-chat-dots"
  },
  {
    name: "ph-chat-teardrop-dots"
  },
  {
    name: "ph-chat-teardrop-text"
  },
  {
    name: "ph-chat-teardrop"
  },
  {
    name: "ph-chat-text"
  },
  {
    name: "ph-chat"
  },
  {
    name: "ph-chats-circle"
  },
  {
    name: "ph-chats-teardrop"
  },
  {
    name: "ph-chats"
  },
  {
    name: "ph-check-circle"
  },
  {
    name: "ph-check-fat"
  },
  {
    name: "ph-check-square-offset"
  },
  {
    name: "ph-check-square"
  },
  {
    name: "ph-check"
  },
  {
    name: "ph-checks"
  },
  {
    name: "ph-church"
  },
  {
    name: "ph-circle-dashed"
  },
  {
    name: "ph-circle-half-tilt"
  },
  {
    name: "ph-circle-half"
  },
  {
    name: "ph-circle-notch"
  },
  {
    name: "ph-circle"
  },
  {
    name: "ph-circles-four"
  },
  {
    name: "ph-circles-three-plus"
  },
  {
    name: "ph-circles-three"
  },
  {
    name: "ph-circuitry"
  },
  {
    name: "ph-clipboard-text"
  },
  {
    name: "ph-clipboard"
  },
  {
    name: "ph-clock-afternoon"
  },
  {
    name: "ph-clock-clockwise"
  },
  {
    name: "ph-clock-countdown"
  },
  {
    name: "ph-clock-counter-clockwise"
  },
  {
    name: "ph-clock"
  },
  {
    name: "ph-closed-captioning"
  },
  {
    name: "ph-cloud-arrow-down"
  },
  {
    name: "ph-cloud-arrow-up"
  },
  {
    name: "ph-cloud-check"
  },
  {
    name: "ph-cloud-fog"
  },
  {
    name: "ph-cloud-lightning"
  },
  {
    name: "ph-cloud-moon"
  },
  {
    name: "ph-cloud-rain"
  },
  {
    name: "ph-cloud-slash"
  },
  {
    name: "ph-cloud-snow"
  },
  {
    name: "ph-cloud-sun"
  },
  {
    name: "ph-cloud-warning"
  },
  {
    name: "ph-cloud-x"
  },
  {
    name: "ph-cloud"
  },
  {
    name: "ph-club"
  },
  {
    name: "ph-coat-hanger"
  },
  {
    name: "ph-coda-logo"
  },
  {
    name: "ph-code-block"
  },
  {
    name: "ph-code-simple"
  },
  {
    name: "ph-code"
  },
  {
    name: "ph-codepen-logo"
  },
  {
    name: "ph-codesandbox-logo"
  },
  {
    name: "ph-coffee"
  },
  {
    name: "ph-coin-vertical"
  },
  {
    name: "ph-coin"
  },
  {
    name: "ph-coins"
  },
  {
    name: "ph-columns"
  },
  {
    name: "ph-command"
  },
  {
    name: "ph-compass-tool"
  },
  {
    name: "ph-compass"
  },
  {
    name: "ph-computer-tower"
  },
  {
    name: "ph-confetti"
  },
  {
    name: "ph-contactless-payment"
  },
  {
    name: "ph-control"
  },
  {
    name: "ph-cookie"
  },
  {
    name: "ph-cooking-pot"
  },
  {
    name: "ph-copy-simple"
  },
  {
    name: "ph-copy"
  },
  {
    name: "ph-copyleft"
  },
  {
    name: "ph-copyright"
  },
  {
    name: "ph-corners-in"
  },
  {
    name: "ph-corners-out"
  },
  {
    name: "ph-couch"
  },
  {
    name: "ph-cpu"
  },
  {
    name: "ph-credit-card"
  },
  {
    name: "ph-crop"
  },
  {
    name: "ph-cross"
  },
  {
    name: "ph-crosshair-simple"
  },
  {
    name: "ph-crosshair"
  },
  {
    name: "ph-crown-simple"
  },
  {
    name: "ph-crown"
  },
  {
    name: "ph-cube-focus"
  },
  {
    name: "ph-cube-transparent"
  },
  {
    name: "ph-cube"
  },
  {
    name: "ph-currency-btc"
  },
  {
    name: "ph-currency-circle-dollar"
  },
  {
    name: "ph-currency-cny"
  },
  {
    name: "ph-currency-dollar-simple"
  },
  {
    name: "ph-currency-dollar"
  },
  {
    name: "ph-currency-eth"
  },
  {
    name: "ph-currency-eur"
  },
  {
    name: "ph-currency-gbp"
  },
  {
    name: "ph-currency-inr"
  },
  {
    name: "ph-currency-jpy"
  },
  {
    name: "ph-currency-krw"
  },
  {
    name: "ph-currency-kzt"
  },
  {
    name: "ph-currency-ngn"
  },
  {
    name: "ph-currency-rub"
  },
  {
    name: "ph-cursor-click"
  },
  {
    name: "ph-cursor-text"
  },
  {
    name: "ph-cursor"
  },
  {
    name: "ph-cylinder"
  },
  {
    name: "ph-database"
  },
  {
    name: "ph-desktop-tower"
  },
  {
    name: "ph-desktop"
  },
  {
    name: "ph-detective"
  },
  {
    name: "ph-dev-to-logo"
  },
  {
    name: "ph-device-mobile-camera"
  },
  {
    name: "ph-device-mobile-speaker"
  },
  {
    name: "ph-device-mobile"
  },
  {
    name: "ph-device-tablet-camera"
  },
  {
    name: "ph-device-tablet-speaker"
  },
  {
    name: "ph-device-tablet"
  },
  {
    name: "ph-devices"
  },
  {
    name: "ph-diamond"
  },
  {
    name: "ph-diamonds-four"
  },
  {
    name: "ph-dice-five"
  },
  {
    name: "ph-dice-four"
  },
  {
    name: "ph-dice-one"
  },
  {
    name: "ph-dice-six"
  },
  {
    name: "ph-dice-three"
  },
  {
    name: "ph-dice-two"
  },
  {
    name: "ph-disc"
  },
  {
    name: "ph-discord-logo"
  },
  {
    name: "ph-divide"
  },
  {
    name: "ph-dna"
  },
  {
    name: "ph-dog"
  },
  {
    name: "ph-door-open"
  },
  {
    name: "ph-door"
  },
  {
    name: "ph-dot-outline"
  },
  {
    name: "ph-dot"
  },
  {
    name: "ph-dots-nine"
  },
  {
    name: "ph-dots-six-vertical"
  },
  {
    name: "ph-dots-six"
  },
  {
    name: "ph-dots-three-circle-vertical"
  },
  {
    name: "ph-dots-three-circle"
  },
  {
    name: "ph-dots-three-outline-vertical"
  },
  {
    name: "ph-dots-three-outline"
  },
  {
    name: "ph-dots-three-vertical"
  },
  {
    name: "ph-dots-three"
  },
  {
    name: "ph-download-simple"
  },
  {
    name: "ph-download"
  },
  {
    name: "ph-dress"
  },
  {
    name: "ph-dribbble-logo"
  },
  {
    name: "ph-drop-half-bottom"
  },
  {
    name: "ph-drop-half"
  },
  {
    name: "ph-drop"
  },
  {
    name: "ph-dropbox-logo"
  },
  {
    name: "ph-ear-slash"
  },
  {
    name: "ph-ear"
  },
  {
    name: "ph-egg-crack"
  },
  {
    name: "ph-egg"
  },
  {
    name: "ph-eject-simple"
  },
  {
    name: "ph-eject"
  },
  {
    name: "ph-elevator"
  },
  {
    name: "ph-engine"
  },
  {
    name: "ph-envelope-open"
  },
  {
    name: "ph-envelope-simple-open"
  },
  {
    name: "ph-envelope-simple"
  },
  {
    name: "ph-envelope"
  },
  {
    name: "ph-equalizer"
  },
  {
    name: "ph-equals"
  },
  {
    name: "ph-eraser"
  },
  {
    name: "ph-escalator-down"
  },
  {
    name: "ph-escalator-up"
  },
  {
    name: "ph-exam"
  },
  {
    name: "ph-exclude-square"
  },
  {
    name: "ph-exclude"
  },
  {
    name: "ph-export"
  },
  {
    name: "ph-eye-closed"
  },
  {
    name: "ph-eye-slash"
  },
  {
    name: "ph-eye"
  },
  {
    name: "ph-eyedropper-sample"
  },
  {
    name: "ph-eyedropper"
  },
  {
    name: "ph-eyeglasses"
  },
  {
    name: "ph-face-mask"
  },
  {
    name: "ph-facebook-logo"
  },
  {
    name: "ph-factory"
  },
  {
    name: "ph-faders-horizontal"
  },
  {
    name: "ph-faders"
  },
  {
    name: "ph-fan"
  },
  {
    name: "ph-fast-forward-circle"
  },
  {
    name: "ph-fast-forward"
  },
  {
    name: "ph-feather"
  },
  {
    name: "ph-figma-logo"
  },
  {
    name: "ph-file-archive"
  },
  {
    name: "ph-file-arrow-down"
  },
  {
    name: "ph-file-arrow-up"
  },
  {
    name: "ph-file-audio"
  },
  {
    name: "ph-file-cloud"
  },
  {
    name: "ph-file-code"
  },
  {
    name: "ph-file-css"
  },
  {
    name: "ph-file-csv"
  },
  {
    name: "ph-file-dashed"
  },
  {
    name: "ph-file-doc"
  },
  {
    name: "ph-file-html"
  },
  {
    name: "ph-file-image"
  },
  {
    name: "ph-file-jpg"
  },
  {
    name: "ph-file-js"
  },
  {
    name: "ph-file-jsx"
  },
  {
    name: "ph-file-lock"
  },
  {
    name: "ph-file-magnifying-glass"
  },
  {
    name: "ph-file-minus"
  },
  {
    name: "ph-file-pdf"
  },
  {
    name: "ph-file-plus"
  },
  {
    name: "ph-file-png"
  },
  {
    name: "ph-file-ppt"
  },
  {
    name: "ph-file-rs"
  },
  {
    name: "ph-file-sql"
  },
  {
    name: "ph-file-svg"
  },
  {
    name: "ph-file-text"
  },
  {
    name: "ph-file-ts"
  },
  {
    name: "ph-file-tsx"
  },
  {
    name: "ph-file-video"
  },
  {
    name: "ph-file-vue"
  },
  {
    name: "ph-file-x"
  },
  {
    name: "ph-file-xls"
  },
  {
    name: "ph-file-zip"
  },
  {
    name: "ph-file"
  },
  {
    name: "ph-files"
  },
  {
    name: "ph-film-reel"
  },
  {
    name: "ph-film-script"
  },
  {
    name: "ph-film-slate"
  },
  {
    name: "ph-film-strip"
  },
  {
    name: "ph-fingerprint-simple"
  },
  {
    name: "ph-fingerprint"
  },
  {
    name: "ph-finn-the-human"
  },
  {
    name: "ph-fire-extinguisher"
  },
  {
    name: "ph-fire-simple"
  },
  {
    name: "ph-fire"
  },
  {
    name: "ph-first-aid-kit"
  },
  {
    name: "ph-first-aid"
  },
  {
    name: "ph-fish-simple"
  },
  {
    name: "ph-fish"
  },
  {
    name: "ph-flag-banner"
  },
  {
    name: "ph-flag-checkered"
  },
  {
    name: "ph-flag-pennant"
  },
  {
    name: "ph-flag"
  },
  {
    name: "ph-flame"
  },
  {
    name: "ph-flashlight"
  },
  {
    name: "ph-flask"
  },
  {
    name: "ph-floppy-disk-back"
  },
  {
    name: "ph-floppy-disk"
  },
  {
    name: "ph-flow-arrow"
  },
  {
    name: "ph-flower-lotus"
  },
  {
    name: "ph-flower-tulip"
  },
  {
    name: "ph-flower"
  },
  {
    name: "ph-flying-saucer"
  },
  {
    name: "ph-folder-dashed"
  },
  {
    name: "ph-folder-lock"
  },
  {
    name: "ph-folder-minus"
  },
  {
    name: "ph-folder-notch-minus"
  },
  {
    name: "ph-folder-notch-open"
  },
  {
    name: "ph-folder-notch-plus"
  },
  {
    name: "ph-folder-notch"
  },
  {
    name: "ph-folder-open"
  },
  {
    name: "ph-folder-plus"
  },
  {
    name: "ph-folder-simple-dashed"
  },
  {
    name: "ph-folder-simple-lock"
  },
  {
    name: "ph-folder-simple-minus"
  },
  {
    name: "ph-folder-simple-plus"
  },
  {
    name: "ph-folder-simple-star"
  },
  {
    name: "ph-folder-simple-user"
  },
  {
    name: "ph-folder-simple"
  },
  {
    name: "ph-folder-star"
  },
  {
    name: "ph-folder-user"
  },
  {
    name: "ph-folder"
  },
  {
    name: "ph-folders"
  },
  {
    name: "ph-football"
  },
  {
    name: "ph-footprints"
  },
  {
    name: "ph-fork-knife"
  },
  {
    name: "ph-frame-corners"
  },
  {
    name: "ph-framer-logo"
  },
  {
    name: "ph-function"
  },
  {
    name: "ph-funnel-simple"
  },
  {
    name: "ph-funnel"
  },
  {
    name: "ph-game-controller"
  },
  {
    name: "ph-garage"
  },
  {
    name: "ph-gas-can"
  },
  {
    name: "ph-gas-pump"
  },
  {
    name: "ph-gauge"
  },
  {
    name: "ph-gavel"
  },
  {
    name: "ph-gear-fine"
  },
  {
    name: "ph-gear-six"
  },
  {
    name: "ph-gear"
  },
  {
    name: "ph-gender-female"
  },
  {
    name: "ph-gender-intersex"
  },
  {
    name: "ph-gender-male"
  },
  {
    name: "ph-gender-neuter"
  },
  {
    name: "ph-gender-nonbinary"
  },
  {
    name: "ph-gender-transgender"
  },
  {
    name: "ph-ghost"
  },
  {
    name: "ph-gif"
  },
  {
    name: "ph-gift"
  },
  {
    name: "ph-git-branch"
  },
  {
    name: "ph-git-commit"
  },
  {
    name: "ph-git-diff"
  },
  {
    name: "ph-git-fork"
  },
  {
    name: "ph-git-merge"
  },
  {
    name: "ph-git-pull-request"
  },
  {
    name: "ph-github-logo"
  },
  {
    name: "ph-gitlab-logo-simple"
  },
  {
    name: "ph-gitlab-logo"
  },
  {
    name: "ph-globe-hemisphere-east"
  },
  {
    name: "ph-globe-hemisphere-west"
  },
  {
    name: "ph-globe-simple"
  },
  {
    name: "ph-globe-stand"
  },
  {
    name: "ph-globe"
  },
  {
    name: "ph-goggles"
  },
  {
    name: "ph-goodreads-logo"
  },
  {
    name: "ph-google-cardboard-logo"
  },
  {
    name: "ph-google-chrome-logo"
  },
  {
    name: "ph-google-drive-logo"
  },
  {
    name: "ph-google-logo"
  },
  {
    name: "ph-google-photos-logo"
  },
  {
    name: "ph-google-play-logo"
  },
  {
    name: "ph-google-podcasts-logo"
  },
  {
    name: "ph-gradient"
  },
  {
    name: "ph-graduation-cap"
  },
  {
    name: "ph-grains-slash"
  },
  {
    name: "ph-grains"
  },
  {
    name: "ph-graph"
  },
  {
    name: "ph-grid-four"
  },
  {
    name: "ph-grid-nine"
  },
  {
    name: "ph-guitar"
  },
  {
    name: "ph-hamburger"
  },
  {
    name: "ph-hammer"
  },
  {
    name: "ph-hand-coins"
  },
  {
    name: "ph-hand-eye"
  },
  {
    name: "ph-hand-fist"
  },
  {
    name: "ph-hand-grabbing"
  },
  {
    name: "ph-hand-heart"
  },
  {
    name: "ph-hand-palm"
  },
  {
    name: "ph-hand-pointing"
  },
  {
    name: "ph-hand-soap"
  },
  {
    name: "ph-hand-swipe-left"
  },
  {
    name: "ph-hand-swipe-right"
  },
  {
    name: "ph-hand-tap"
  },
  {
    name: "ph-hand-waving"
  },
  {
    name: "ph-hand"
  },
  {
    name: "ph-handbag-simple"
  },
  {
    name: "ph-handbag"
  },
  {
    name: "ph-hands-clapping"
  },
  {
    name: "ph-hands-praying"
  },
  {
    name: "ph-handshake"
  },
  {
    name: "ph-hard-drive"
  },
  {
    name: "ph-hard-drives"
  },
  {
    name: "ph-hash-straight"
  },
  {
    name: "ph-hash"
  },
  {
    name: "ph-headlights"
  },
  {
    name: "ph-headphones"
  },
  {
    name: "ph-headset"
  },
  {
    name: "ph-heart-break"
  },
  {
    name: "ph-heart-half"
  },
  {
    name: "ph-heart-straight-break"
  },
  {
    name: "ph-heart-straight"
  },
  {
    name: "ph-heart"
  },
  {
    name: "ph-heartbeat"
  },
  {
    name: "ph-hexagon"
  },
  {
    name: "ph-high-heel"
  },
  {
    name: "ph-highlighter-circle"
  },
  {
    name: "ph-hoodie"
  },
  {
    name: "ph-horse"
  },
  {
    name: "ph-hourglass-high"
  },
  {
    name: "ph-hourglass-low"
  },
  {
    name: "ph-hourglass-medium"
  },
  {
    name: "ph-hourglass-simple-high"
  },
  {
    name: "ph-hourglass-simple-low"
  },
  {
    name: "ph-hourglass-simple-medium"
  },
  {
    name: "ph-hourglass-simple"
  },
  {
    name: "ph-hourglass"
  },
  {
    name: "ph-house-line"
  },
  {
    name: "ph-house-simple"
  },
  {
    name: "ph-house"
  },
  {
    name: "ph-ice-cream"
  },
  {
    name: "ph-identification-badge"
  },
  {
    name: "ph-identification-card"
  },
  {
    name: "ph-image-square"
  },
  {
    name: "ph-image"
  },
  {
    name: "ph-images-square"
  },
  {
    name: "ph-images"
  },
  {
    name: "ph-infinity"
  },
  {
    name: "ph-info"
  },
  {
    name: "ph-instagram-logo"
  },
  {
    name: "ph-intersect-square"
  },
  {
    name: "ph-intersect-three"
  },
  {
    name: "ph-intersect"
  },
  {
    name: "ph-jeep"
  },
  {
    name: "ph-kanban"
  },
  {
    name: "ph-key-return"
  },
  {
    name: "ph-key"
  },
  {
    name: "ph-keyboard"
  },
  {
    name: "ph-keyhole"
  },
  {
    name: "ph-knife"
  },
  {
    name: "ph-ladder-simple"
  },
  {
    name: "ph-ladder"
  },
  {
    name: "ph-lamp"
  },
  {
    name: "ph-laptop"
  },
  {
    name: "ph-layout"
  },
  {
    name: "ph-leaf"
  },
  {
    name: "ph-lifebuoy"
  },
  {
    name: "ph-lightbulb-filament"
  },
  {
    name: "ph-lightbulb"
  },
  {
    name: "ph-lighthouse"
  },
  {
    name: "ph-lightning-a"
  },
  {
    name: "ph-lightning-slash"
  },
  {
    name: "ph-lightning"
  },
  {
    name: "ph-line-segment"
  },
  {
    name: "ph-line-segments"
  },
  {
    name: "ph-link-break"
  },
  {
    name: "ph-link-simple-break"
  },
  {
    name: "ph-link-simple-horizontal-break"
  },
  {
    name: "ph-link-simple-horizontal"
  },
  {
    name: "ph-link-simple"
  },
  {
    name: "ph-link"
  },
  {
    name: "ph-linkedin-logo"
  },
  {
    name: "ph-linux-logo"
  },
  {
    name: "ph-list-bullets"
  },
  {
    name: "ph-list-checks"
  },
  {
    name: "ph-list-dashes"
  },
  {
    name: "ph-list-magnifying-glass"
  },
  {
    name: "ph-list-numbers"
  },
  {
    name: "ph-list-plus"
  },
  {
    name: "ph-list"
  },
  {
    name: "ph-lock-key-open"
  },
  {
    name: "ph-lock-key"
  },
  {
    name: "ph-lock-laminated-open"
  },
  {
    name: "ph-lock-laminated"
  },
  {
    name: "ph-lock-open"
  },
  {
    name: "ph-lock-simple-open"
  },
  {
    name: "ph-lock-simple"
  },
  {
    name: "ph-lock"
  },
  {
    name: "ph-lockers"
  },
  {
    name: "ph-magic-wand"
  },
  {
    name: "ph-magnet-straight"
  },
  {
    name: "ph-magnet"
  },
  {
    name: "ph-magnifying-glass-minus"
  },
  {
    name: "ph-magnifying-glass-plus"
  },
  {
    name: "ph-magnifying-glass"
  },
  {
    name: "ph-map-pin-line"
  },
  {
    name: "ph-map-pin"
  },
  {
    name: "ph-map-trifold"
  },
  {
    name: "ph-marker-circle"
  },
  {
    name: "ph-martini"
  },
  {
    name: "ph-mask-happy"
  },
  {
    name: "ph-mask-sad"
  },
  {
    name: "ph-math-operations"
  },
  {
    name: "ph-medal-military"
  },
  {
    name: "ph-medal"
  },
  {
    name: "ph-medium-logo"
  },
  {
    name: "ph-megaphone-simple"
  },
  {
    name: "ph-megaphone"
  },
  {
    name: "ph-messenger-logo"
  },
  {
    name: "ph-meta-logo"
  },
  {
    name: "ph-metronome"
  },
  {
    name: "ph-microphone-slash"
  },
  {
    name: "ph-microphone-stage"
  },
  {
    name: "ph-microphone"
  },
  {
    name: "ph-microsoft-excel-logo"
  },
  {
    name: "ph-microsoft-outlook-logo"
  },
  {
    name: "ph-microsoft-powerpoint-logo"
  },
  {
    name: "ph-microsoft-teams-logo"
  },
  {
    name: "ph-microsoft-word-logo"
  },
  {
    name: "ph-minus-circle"
  },
  {
    name: "ph-minus-square"
  },
  {
    name: "ph-minus"
  },
  {
    name: "ph-money"
  },
  {
    name: "ph-monitor-play"
  },
  {
    name: "ph-monitor"
  },
  {
    name: "ph-moon-stars"
  },
  {
    name: "ph-moon"
  },
  {
    name: "ph-moped-front"
  },
  {
    name: "ph-moped"
  },
  {
    name: "ph-mosque"
  },
  {
    name: "ph-motorcycle"
  },
  {
    name: "ph-mountains"
  },
  {
    name: "ph-mouse-simple"
  },
  {
    name: "ph-mouse"
  },
  {
    name: "ph-music-note-simple"
  },
  {
    name: "ph-music-note"
  },
  {
    name: "ph-music-notes-plus"
  },
  {
    name: "ph-music-notes-simple"
  },
  {
    name: "ph-music-notes"
  },
  {
    name: "ph-navigation-arrow"
  },
  {
    name: "ph-needle"
  },
  {
    name: "ph-newspaper-clipping"
  },
  {
    name: "ph-newspaper"
  },
  {
    name: "ph-notches"
  },
  {
    name: "ph-note-blank"
  },
  {
    name: "ph-note-pencil"
  },
  {
    name: "ph-note"
  },
  {
    name: "ph-notebook"
  },
  {
    name: "ph-notepad"
  },
  {
    name: "ph-notification"
  },
  {
    name: "ph-notion-logo"
  },
  {
    name: "ph-number-circle-eight"
  },
  {
    name: "ph-number-circle-five"
  },
  {
    name: "ph-number-circle-four"
  },
  {
    name: "ph-number-circle-nine"
  },
  {
    name: "ph-number-circle-one"
  },
  {
    name: "ph-number-circle-seven"
  },
  {
    name: "ph-number-circle-six"
  },
  {
    name: "ph-number-circle-three"
  },
  {
    name: "ph-number-circle-two"
  },
  {
    name: "ph-number-circle-zero"
  },
  {
    name: "ph-number-eight"
  },
  {
    name: "ph-number-five"
  },
  {
    name: "ph-number-four"
  },
  {
    name: "ph-number-nine"
  },
  {
    name: "ph-number-one"
  },
  {
    name: "ph-number-seven"
  },
  {
    name: "ph-number-six"
  },
  {
    name: "ph-number-square-eight"
  },
  {
    name: "ph-number-square-five"
  },
  {
    name: "ph-number-square-four"
  },
  {
    name: "ph-number-square-nine"
  },
  {
    name: "ph-number-square-one"
  },
  {
    name: "ph-number-square-seven"
  },
  {
    name: "ph-number-square-six"
  },
  {
    name: "ph-number-square-three"
  },
  {
    name: "ph-number-square-two"
  },
  {
    name: "ph-number-square-zero"
  },
  {
    name: "ph-number-three"
  },
  {
    name: "ph-number-two"
  },
  {
    name: "ph-number-zero"
  },
  {
    name: "ph-nut"
  },
  {
    name: "ph-ny-times-logo"
  },
  {
    name: "ph-octagon"
  },
  {
    name: "ph-office-chair"
  },
  {
    name: "ph-option"
  },
  {
    name: "ph-orange-slice"
  },
  {
    name: "ph-package"
  },
  {
    name: "ph-paint-brush-broad"
  },
  {
    name: "ph-paint-brush-household"
  },
  {
    name: "ph-paint-brush"
  },
  {
    name: "ph-paint-bucket"
  },
  {
    name: "ph-paint-roller"
  },
  {
    name: "ph-palette"
  },
  {
    name: "ph-pants"
  },
  {
    name: "ph-paper-plane-right"
  },
  {
    name: "ph-paper-plane-tilt"
  },
  {
    name: "ph-paper-plane"
  },
  {
    name: "ph-paperclip-horizontal"
  },
  {
    name: "ph-paperclip"
  },
  {
    name: "ph-parachute"
  },
  {
    name: "ph-paragraph"
  },
  {
    name: "ph-parallelogram"
  },
  {
    name: "ph-park"
  },
  {
    name: "ph-password"
  },
  {
    name: "ph-path"
  },
  {
    name: "ph-patreon-logo"
  },
  {
    name: "ph-pause-circle"
  },
  {
    name: "ph-pause"
  },
  {
    name: "ph-paw-print"
  },
  {
    name: "ph-paypal-logo"
  },
  {
    name: "ph-peace"
  },
  {
    name: "ph-pen-nib-straight"
  },
  {
    name: "ph-pen-nib"
  },
  {
    name: "ph-pen"
  },
  {
    name: "ph-pencil-circle"
  },
  {
    name: "ph-pencil-line"
  },
  {
    name: "ph-pencil-simple-line"
  },
  {
    name: "ph-pencil-simple-slash"
  },
  {
    name: "ph-pencil-simple"
  },
  {
    name: "ph-pencil-slash"
  },
  {
    name: "ph-pencil"
  },
  {
    name: "ph-pentagram"
  },
  {
    name: "ph-pepper"
  },
  {
    name: "ph-percent"
  },
  {
    name: "ph-person-arms-spread"
  },
  {
    name: "ph-person-simple-bike"
  },
  {
    name: "ph-person-simple-run"
  },
  {
    name: "ph-person-simple-throw"
  },
  {
    name: "ph-person-simple-walk"
  },
  {
    name: "ph-person-simple"
  },
  {
    name: "ph-person"
  },
  {
    name: "ph-perspective"
  },
  {
    name: "ph-phone-call"
  },
  {
    name: "ph-phone-disconnect"
  },
  {
    name: "ph-phone-incoming"
  },
  {
    name: "ph-phone-outgoing"
  },
  {
    name: "ph-phone-plus"
  },
  {
    name: "ph-phone-slash"
  },
  {
    name: "ph-phone-x"
  },
  {
    name: "ph-phone"
  },
  {
    name: "ph-phosphor-logo"
  },
  {
    name: "ph-pi"
  },
  {
    name: "ph-piano-keys"
  },
  {
    name: "ph-picture-in-picture"
  },
  {
    name: "ph-piggy-bank"
  },
  {
    name: "ph-pill"
  },
  {
    name: "ph-pinterest-logo"
  },
  {
    name: "ph-pinwheel"
  },
  {
    name: "ph-pizza"
  },
  {
    name: "ph-placeholder"
  },
  {
    name: "ph-planet"
  },
  {
    name: "ph-plant"
  },
  {
    name: "ph-play-circle"
  },
  {
    name: "ph-play-pause"
  },
  {
    name: "ph-play"
  },
  {
    name: "ph-playlist"
  },
  {
    name: "ph-plug-charging"
  },
  {
    name: "ph-plug"
  },
  {
    name: "ph-plugs-connected"
  },
  {
    name: "ph-plugs"
  },
  {
    name: "ph-plus-circle"
  },
  {
    name: "ph-plus-minus"
  },
  {
    name: "ph-plus-square"
  },
  {
    name: "ph-plus"
  },
  {
    name: "ph-poker-chip"
  },
  {
    name: "ph-police-car"
  },
  {
    name: "ph-polygon"
  },
  {
    name: "ph-popcorn"
  },
  {
    name: "ph-potted-plant"
  },
  {
    name: "ph-power"
  },
  {
    name: "ph-prescription"
  },
  {
    name: "ph-presentation-chart"
  },
  {
    name: "ph-presentation"
  },
  {
    name: "ph-printer"
  },
  {
    name: "ph-prohibit-inset"
  },
  {
    name: "ph-prohibit"
  },
  {
    name: "ph-projector-screen-chart"
  },
  {
    name: "ph-projector-screen"
  },
  {
    name: "ph-pulse"
  },
  {
    name: "ph-push-pin-simple-slash"
  },
  {
    name: "ph-push-pin-simple"
  },
  {
    name: "ph-push-pin-slash"
  },
  {
    name: "ph-push-pin"
  },
  {
    name: "ph-puzzle-piece"
  },
  {
    name: "ph-qr-code"
  },
  {
    name: "ph-question"
  },
  {
    name: "ph-queue"
  },
  {
    name: "ph-quotes"
  },
  {
    name: "ph-radical"
  },
  {
    name: "ph-radio-button"
  },
  {
    name: "ph-radio"
  },
  {
    name: "ph-radioactive"
  },
  {
    name: "ph-rainbow-cloud"
  },
  {
    name: "ph-rainbow"
  },
  {
    name: "ph-read-cv-logo"
  },
  {
    name: "ph-receipt-x"
  },
  {
    name: "ph-receipt"
  },
  {
    name: "ph-record"
  },
  {
    name: "ph-rectangle"
  },
  {
    name: "ph-recycle"
  },
  {
    name: "ph-reddit-logo"
  },
  {
    name: "ph-repeat-once"
  },
  {
    name: "ph-repeat"
  },
  {
    name: "ph-rewind-circle"
  },
  {
    name: "ph-rewind"
  },
  {
    name: "ph-road-horizon"
  },
  {
    name: "ph-robot"
  },
  {
    name: "ph-rocket-launch"
  },
  {
    name: "ph-rocket"
  },
  {
    name: "ph-rows"
  },
  {
    name: "ph-rss-simple"
  },
  {
    name: "ph-rss"
  },
  {
    name: "ph-rug"
  },
  {
    name: "ph-ruler"
  },
  {
    name: "ph-scales"
  },
  {
    name: "ph-scan"
  },
  {
    name: "ph-scissors"
  },
  {
    name: "ph-scooter"
  },
  {
    name: "ph-screencast"
  },
  {
    name: "ph-scribble-loop"
  },
  {
    name: "ph-scroll"
  },
  {
    name: "ph-seal-check"
  },
  {
    name: "ph-seal-question"
  },
  {
    name: "ph-seal-warning"
  },
  {
    name: "ph-seal"
  },
  {
    name: "ph-selection-all"
  },
  {
    name: "ph-selection-background"
  },
  {
    name: "ph-selection-foreground"
  },
  {
    name: "ph-selection-inverse"
  },
  {
    name: "ph-selection-plus"
  },
  {
    name: "ph-selection-slash"
  },
  {
    name: "ph-selection"
  },
  {
    name: "ph-shapes"
  },
  {
    name: "ph-share-fat"
  },
  {
    name: "ph-share-network"
  },
  {
    name: "ph-share"
  },
  {
    name: "ph-shield-check"
  },
  {
    name: "ph-shield-checkered"
  },
  {
    name: "ph-shield-chevron"
  },
  {
    name: "ph-shield-plus"
  },
  {
    name: "ph-shield-slash"
  },
  {
    name: "ph-shield-star"
  },
  {
    name: "ph-shield-warning"
  },
  {
    name: "ph-shield"
  },
  {
    name: "ph-shirt-folded"
  },
  {
    name: "ph-shooting-star"
  },
  {
    name: "ph-shopping-bag-open"
  },
  {
    name: "ph-shopping-bag"
  },
  {
    name: "ph-shopping-cart-simple"
  },
  {
    name: "ph-shopping-cart"
  },
  {
    name: "ph-shower"
  },
  {
    name: "ph-shrimp"
  },
  {
    name: "ph-shuffle-angular"
  },
  {
    name: "ph-shuffle-simple"
  },
  {
    name: "ph-shuffle"
  },
  {
    name: "ph-sidebar-simple"
  },
  {
    name: "ph-sidebar"
  },
  {
    name: "ph-sigma"
  },
  {
    name: "ph-sign-in"
  },
  {
    name: "ph-sign-out"
  },
  {
    name: "ph-signature"
  },
  {
    name: "ph-signpost"
  },
  {
    name: "ph-sim-card"
  },
  {
    name: "ph-siren"
  },
  {
    name: "ph-sketch-logo"
  },
  {
    name: "ph-skip-back-circle"
  },
  {
    name: "ph-skip-back"
  },
  {
    name: "ph-skip-forward-circle"
  },
  {
    name: "ph-skip-forward"
  },
  {
    name: "ph-skull"
  },
  {
    name: "ph-slack-logo"
  },
  {
    name: "ph-sliders-horizontal"
  },
  {
    name: "ph-sliders"
  },
  {
    name: "ph-slideshow"
  },
  {
    name: "ph-smiley-angry"
  },
  {
    name: "ph-smiley-blank"
  },
  {
    name: "ph-smiley-meh"
  },
  {
    name: "ph-smiley-nervous"
  },
  {
    name: "ph-smiley-sad"
  },
  {
    name: "ph-smiley-sticker"
  },
  {
    name: "ph-smiley-wink"
  },
  {
    name: "ph-smiley-x-eyes"
  },
  {
    name: "ph-smiley"
  },
  {
    name: "ph-snapchat-logo"
  },
  {
    name: "ph-sneaker-move"
  },
  {
    name: "ph-sneaker"
  },
  {
    name: "ph-snowflake"
  },
  {
    name: "ph-soccer-ball"
  },
  {
    name: "ph-sort-ascending"
  },
  {
    name: "ph-sort-descending"
  },
  {
    name: "ph-soundcloud-logo"
  },
  {
    name: "ph-spade"
  },
  {
    name: "ph-sparkle"
  },
  {
    name: "ph-speaker-hifi"
  },
  {
    name: "ph-speaker-high"
  },
  {
    name: "ph-speaker-low"
  },
  {
    name: "ph-speaker-none"
  },
  {
    name: "ph-speaker-simple-high"
  },
  {
    name: "ph-speaker-simple-low"
  },
  {
    name: "ph-speaker-simple-none"
  },
  {
    name: "ph-speaker-simple-slash"
  },
  {
    name: "ph-speaker-simple-x"
  },
  {
    name: "ph-speaker-slash"
  },
  {
    name: "ph-speaker-x"
  },
  {
    name: "ph-spinner-gap"
  },
  {
    name: "ph-spinner"
  },
  {
    name: "ph-spiral"
  },
  {
    name: "ph-split-horizontal"
  },
  {
    name: "ph-split-vertical"
  },
  {
    name: "ph-spotify-logo"
  },
  {
    name: "ph-square-half-bottom"
  },
  {
    name: "ph-square-half"
  },
  {
    name: "ph-square-logo"
  },
  {
    name: "ph-square-split-horizontal"
  },
  {
    name: "ph-square-split-vertical"
  },
  {
    name: "ph-square"
  },
  {
    name: "ph-squares-four"
  },
  {
    name: "ph-stack-overflow-logo"
  },
  {
    name: "ph-stack-simple"
  },
  {
    name: "ph-stack"
  },
  {
    name: "ph-stairs"
  },
  {
    name: "ph-stamp"
  },
  {
    name: "ph-star-and-crescent"
  },
  {
    name: "ph-star-four"
  },
  {
    name: "ph-star-half"
  },
  {
    name: "ph-star-of-david"
  },
  {
    name: "ph-star"
  },
  {
    name: "ph-steering-wheel"
  },
  {
    name: "ph-steps"
  },
  {
    name: "ph-stethoscope"
  },
  {
    name: "ph-sticker"
  },
  {
    name: "ph-stool"
  },
  {
    name: "ph-stop-circle"
  },
  {
    name: "ph-stop"
  },
  {
    name: "ph-storefront"
  },
  {
    name: "ph-strategy"
  },
  {
    name: "ph-stripe-logo"
  },
  {
    name: "ph-student"
  },
  {
    name: "ph-subtitles"
  },
  {
    name: "ph-subtract-square"
  },
  {
    name: "ph-subtract"
  },
  {
    name: "ph-suitcase-rolling"
  },
  {
    name: "ph-suitcase-simple"
  },
  {
    name: "ph-suitcase"
  },
  {
    name: "ph-sun-dim"
  },
  {
    name: "ph-sun-horizon"
  },
  {
    name: "ph-sun"
  },
  {
    name: "ph-sunglasses"
  },
  {
    name: "ph-swap"
  },
  {
    name: "ph-swatches"
  },
  {
    name: "ph-swimming-pool"
  },
  {
    name: "ph-sword"
  },
  {
    name: "ph-synagogue"
  },
  {
    name: "ph-syringe"
  },
  {
    name: "ph-t-shirt"
  },
  {
    name: "ph-table"
  },
  {
    name: "ph-tabs"
  },
  {
    name: "ph-tag-chevron"
  },
  {
    name: "ph-tag-simple"
  },
  {
    name: "ph-tag"
  },
  {
    name: "ph-target"
  },
  {
    name: "ph-taxi"
  },
  {
    name: "ph-telegram-logo"
  },
  {
    name: "ph-television-simple"
  },
  {
    name: "ph-television"
  },
  {
    name: "ph-tennis-ball"
  },
  {
    name: "ph-tent"
  },
  {
    name: "ph-terminal-window"
  },
  {
    name: "ph-terminal"
  },
  {
    name: "ph-test-tube"
  },
  {
    name: "ph-text-a-underline"
  },
  {
    name: "ph-text-aa"
  },
  {
    name: "ph-text-align-center"
  },
  {
    name: "ph-text-align-justify"
  },
  {
    name: "ph-text-align-left"
  },
  {
    name: "ph-text-align-right"
  },
  {
    name: "ph-text-b"
  },
  {
    name: "ph-text-columns"
  },
  {
    name: "ph-text-h-five"
  },
  {
    name: "ph-text-h-four"
  },
  {
    name: "ph-text-h-one"
  },
  {
    name: "ph-text-h-six"
  },
  {
    name: "ph-text-h-three"
  },
  {
    name: "ph-text-h-two"
  },
  {
    name: "ph-text-h"
  },
  {
    name: "ph-text-indent"
  },
  {
    name: "ph-text-italic"
  },
  {
    name: "ph-text-outdent"
  },
  {
    name: "ph-text-strikethrough"
  },
  {
    name: "ph-text-t"
  },
  {
    name: "ph-text-underline"
  },
  {
    name: "ph-textbox"
  },
  {
    name: "ph-thermometer-cold"
  },
  {
    name: "ph-thermometer-hot"
  },
  {
    name: "ph-thermometer-simple"
  },
  {
    name: "ph-thermometer"
  },
  {
    name: "ph-thumbs-down"
  },
  {
    name: "ph-thumbs-up"
  },
  {
    name: "ph-ticket"
  },
  {
    name: "ph-tidal-logo"
  },
  {
    name: "ph-tiktok-logo"
  },
  {
    name: "ph-timer"
  },
  {
    name: "ph-tipi"
  },
  {
    name: "ph-toggle-left"
  },
  {
    name: "ph-toggle-right"
  },
  {
    name: "ph-toilet-paper"
  },
  {
    name: "ph-toilet"
  },
  {
    name: "ph-toolbox"
  },
  {
    name: "ph-tooth"
  },
  {
    name: "ph-tote-simple"
  },
  {
    name: "ph-tote"
  },
  {
    name: "ph-trademark-registered"
  },
  {
    name: "ph-trademark"
  },
  {
    name: "ph-traffic-cone"
  },
  {
    name: "ph-traffic-sign"
  },
  {
    name: "ph-traffic-signal"
  },
  {
    name: "ph-train-regional"
  },
  {
    name: "ph-train-simple"
  },
  {
    name: "ph-train"
  },
  {
    name: "ph-tram"
  },
  {
    name: "ph-translate"
  },
  {
    name: "ph-trash-simple"
  },
  {
    name: "ph-trash"
  },
  {
    name: "ph-tray"
  },
  {
    name: "ph-tree-evergreen"
  },
  {
    name: "ph-tree-palm"
  },
  {
    name: "ph-tree-structure"
  },
  {
    name: "ph-tree"
  },
  {
    name: "ph-trend-down"
  },
  {
    name: "ph-trend-up"
  },
  {
    name: "ph-triangle"
  },
  {
    name: "ph-trophy"
  },
  {
    name: "ph-truck"
  },
  {
    name: "ph-twitch-logo"
  },
  {
    name: "ph-twitter-logo"
  },
  {
    name: "ph-umbrella-simple"
  },
  {
    name: "ph-umbrella"
  },
  {
    name: "ph-unite-square"
  },
  {
    name: "ph-unite"
  },
  {
    name: "ph-upload-simple"
  },
  {
    name: "ph-upload"
  },
  {
    name: "ph-usb"
  },
  {
    name: "ph-user-circle-gear"
  },
  {
    name: "ph-user-circle-minus"
  },
  {
    name: "ph-user-circle-plus"
  },
  {
    name: "ph-user-circle"
  },
  {
    name: "ph-user-focus"
  },
  {
    name: "ph-user-gear"
  },
  {
    name: "ph-user-list"
  },
  {
    name: "ph-user-minus"
  },
  {
    name: "ph-user-plus"
  },
  {
    name: "ph-user-rectangle"
  },
  {
    name: "ph-user-square"
  },
  {
    name: "ph-user-switch"
  },
  {
    name: "ph-user"
  },
  {
    name: "ph-users-four"
  },
  {
    name: "ph-users-three"
  },
  {
    name: "ph-users"
  },
  {
    name: "ph-van"
  },
  {
    name: "ph-vault"
  },
  {
    name: "ph-vibrate"
  },
  {
    name: "ph-video-camera-slash"
  },
  {
    name: "ph-video-camera"
  },
  {
    name: "ph-video"
  },
  {
    name: "ph-vignette"
  },
  {
    name: "ph-vinyl-record"
  },
  {
    name: "ph-virtual-reality"
  },
  {
    name: "ph-virus"
  },
  {
    name: "ph-voicemail"
  },
  {
    name: "ph-volleyball"
  },
  {
    name: "ph-wall"
  },
  {
    name: "ph-wallet"
  },
  {
    name: "ph-warehouse"
  },
  {
    name: "ph-warning-circle"
  },
  {
    name: "ph-warning-diamond"
  },
  {
    name: "ph-warning-octagon"
  },
  {
    name: "ph-warning"
  },
  {
    name: "ph-watch"
  },
  {
    name: "ph-wave-sawtooth"
  },
  {
    name: "ph-wave-sine"
  },
  {
    name: "ph-wave-square"
  },
  {
    name: "ph-wave-triangle"
  },
  {
    name: "ph-waveform"
  },
  {
    name: "ph-waves"
  },
  {
    name: "ph-webcam-slash"
  },
  {
    name: "ph-webcam"
  },
  {
    name: "ph-webhooks-logo"
  },
  {
    name: "ph-wechat-logo"
  },
  {
    name: "ph-whatsapp-logo"
  },
  {
    name: "ph-wheelchair-motion"
  },
  {
    name: "ph-wheelchair"
  },
  {
    name: "ph-wifi-high"
  },
  {
    name: "ph-wifi-low"
  },
  {
    name: "ph-wifi-medium"
  },
  {
    name: "ph-wifi-none"
  },
  {
    name: "ph-wifi-slash"
  },
  {
    name: "ph-wifi-x"
  },
  {
    name: "ph-wind"
  },
  {
    name: "ph-windows-logo"
  },
  {
    name: "ph-wine"
  },
  {
    name: "ph-wrench"
  },
  {
    name: "ph-x-circle"
  },
  {
    name: "ph-x-square"
  },
  {
    name: "ph-x"
  },
  {
    name: "ph-yin-yang"
  },
  {
    name: "ph-youtube-logo"
  }
];
