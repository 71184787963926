import {SingleCoverLetterThemeColor} from "@app/shared/interfaces";

export const coverLetter2Themes: SingleCoverLetterThemeColor = {
  theme1: {
    id: 1,

    fontColor: '#303030',
    emptyImgColor: '#4B57DC',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#4B57DC',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#2A3958',
      fontColor: '#ffffff',
      borderColor: '#2A3958',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A3958',
    demoColor2: '#4B57DC',

    linkColor: '#4B57DC'
  }, // template 1 color
  theme2: {
    id: 2,

    fontColor: '#303030',
    emptyImgColor: '#69748B',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#69748B',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#69748B',
      fontColor: '#ffffff',
      borderColor: '#69748B',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#69748B'
  },
  theme3: {
    id: 3,

    fontColor: '#303030',
    emptyImgColor: '#647982',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#647982',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#647982',
      fontColor: '#ffffff',
      borderColor: '#647982',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,

    fontColor: '#303030',
    emptyImgColor: '#808B8F',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#808B8F',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#808B8F',
      fontColor: '#ffffff',
      borderColor: '#808B8F',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#808B8F'
  },
  theme5: {
    id: 5,

    fontColor: '#303030',
    emptyImgColor: '#828293',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#828293',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#828293',
      fontColor: '#ffffff',
      borderColor: '#828293',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#505061',
    demoColor2: '#828293',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,

    fontColor: '#303030',
    emptyImgColor: '#A49393',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#A49393',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#A49393',
      fontColor: '#ffffff',
      borderColor: '#A49393',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#A49393'
  }, // template 5 color
  theme7: {
    id: 7,

    fontColor: '#303030',
    emptyImgColor: '#43779F',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#43779F',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#43779F',
      fontColor: '#ffffff',
      borderColor: '#43779F',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1B4F77',
    demoColor2: '#43779F',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,

    fontColor: '#303030',
    emptyImgColor: '#2983BA',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#2983BA',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#2983BA',
      fontColor: '#ffffff',
      borderColor: '#2983BA',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#2983BA'
  },
  theme9: {
    id: 9,

    fontColor: '#303030',
    emptyImgColor: '#6C6EA5',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#6C6EA5',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#6C6EA5',
      fontColor: '#ffffff',
      borderColor: '#6C6EA5',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#6C6EA5'
  },
  theme10: {
    id: 10,

    fontColor: '#303030',
    emptyImgColor: '#5A79C7',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#5A79C7',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#5A79C7',
      fontColor: '#ffffff',
      borderColor: '#5A79C7',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#39559A',
    demoColor2: '#5A79C7',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,

    fontColor: '#303030',
    emptyImgColor: '#516D92',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#516D92',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#516D92',
      fontColor: '#ffffff',
      borderColor: '#516D92',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1F3B60',
    demoColor2: '#516D92',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,

    fontColor: '#303030',
    emptyImgColor: '#3C7680',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#3C7680',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#3C7680',
      fontColor: '#ffffff',
      borderColor: '#3C7680',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#0A444E',
    demoColor2: '#3C7680',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,

    fontColor: '#303030',
    emptyImgColor: '#527F7A',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#527F7A',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#527F7A',
      fontColor: '#ffffff',
      borderColor: '#527F7A',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#527F7A'
  },
  theme14: {
    id: 14,

    fontColor: '#303030',
    emptyImgColor: '#519097',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#519097',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#519097',
      fontColor: '#ffffff',
      borderColor: '#519097',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#29686F',
    demoColor2: '#519097',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,

    fontColor: '#303030',
    emptyImgColor: '#728686',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#728686',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#728686',
      fontColor: '#ffffff',
      borderColor: '#728686',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#405454',
    demoColor2: '#728686',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,

    fontColor: '#303030',
    emptyImgColor: '#7E8A7B',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#7E8A7B',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#7E8A7B',
      fontColor: '#ffffff',
      borderColor: '#7E8A7B',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B',

    linkColor: '#7E8A7B'
  },
  theme17: {
    id: 17,

    fontColor: '#303030',
    emptyImgColor: '#ACCEC8',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#2B2E35',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ACCEC8',
      fontColor: '#303030',
      borderColor: '#ACCEC8',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2B2E35',
    demoColor2: '#ACCEC8',

    linkColor: '#45736D'
  },  // template 2 color
  theme18: {
    id: 18,

    fontColor: '#303030',
    emptyImgColor: '#908071',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#908071',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#908071',
      fontColor: '#ffffff',
      borderColor: '#908071',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5E4E3F',
    demoColor2: '#908071',

    linkColor: '#908071'
  },
  theme19: {
    id: 19,

    fontColor: '#303030',
    emptyImgColor: '#8E6A64',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#8E6A64',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#8E6A64',
      fontColor: '#ffffff',
      borderColor: '#8E6A64',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#66423C',
    demoColor2: '#8E6A64',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,

    fontColor: '#303030',
    emptyImgColor: '#7F536B',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#7F536B',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#7F536B',
      fontColor: '#ffffff',
      borderColor: '#7F536B',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#7F536B'
  },
  theme21: {
    id: 21,

    fontColor: '#303030',
    emptyImgColor: '#626262',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#626262',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#626262',
      fontColor: '#ffffff',
      borderColor: '#626262',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#626262',

    linkColor: '#626262'
  },  // black one, do not copy from this one
  theme22: {
    id: 22,

    fontColor: '#303030',
    emptyImgColor: '#BE6A9A',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#BE6A9A',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#BE6A9A',
      fontColor: '#ffffff',
      borderColor: '#BE6A9A',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C3868',
    demoColor2: '#BE6A9A',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,

    fontColor: '#303030',
    emptyImgColor: '#9D6C88',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#9D6C88',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#9D6C88',
      fontColor: '#ffffff',
      borderColor: '#9D6C88',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#9D6C88'
  },
  theme24: {
    id: 24,

    fontColor: '#303030',
    emptyImgColor: '#E0589E',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#E0589E',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#E0589E',
      fontColor: '#ffffff',
      borderColor: '#E0589E',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#B05CAC',
    demoColor2: '#E0589E',

    linkColor: '#E0589E'
  }, // template 4 color
  theme25: {
    id: 25,

    fontColor: '#303030',
    emptyImgColor: '#8C6194',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#8C6194',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#8C6194',
      fontColor: '#ffffff',
      borderColor: '#8C6194',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4C3551',
    demoColor2: '#8C6194',

    linkColor: '#8C6194'
  }, // template 3 color
  theme26: {
    id: 26,

    fontColor: '#303030',
    emptyImgColor: '#9B89BD',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#9B89BD',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#9B89BD',
      fontColor: '#ffffff',
      borderColor: '#9B89BD',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#736195',
    demoColor2: '#9B89BD',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,

    fontColor: '#303030',
    emptyImgColor: '#995D93',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#995D93',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#995D93',
      fontColor: '#ffffff',
      borderColor: '#995D93',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#672B61',
    demoColor2: '#995D93',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,

    fontColor: '#303030',
    emptyImgColor: '#8A6B9E',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#8A6B9E',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#8A6B9E',
      fontColor: '#ffffff',
      borderColor: '#8A6B9E',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#58396C',
    demoColor2: '#8A6B9E',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,

    fontColor: '#303030',
    emptyImgColor: '#65758D',

    topPartBgColor: '#fff',

    mainTitleFontColor: 'inherit',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: 'inherit',
    subTitleFontEditModeColor: '#65758D',
    subTitleBGColor: 'transparent',

    dateColor: '#979797',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#65758D',
      fontColor: '#ffffff',
      borderColor: '#65758D',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: '#fff',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3D4D65',
    demoColor2: '#65758D',

    linkColor: '#65758D'
  },
}
