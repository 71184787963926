import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter3: CoverLetter = {
  templateId: 3,
  forTemplate: 3,
  templateTopPartType: 4,
  ...cloneObject(coverLetterCommonConfigs)
};

