import {Directive, ElementRef, inject, Input} from '@angular/core';

@Directive({
  selector: '[sfElPositionDirective]',
  exportAs: 'sfElPositionDirective',
  standalone: true
})
export class ElPositionDirective {
  private elRef = inject(ElementRef);
  @Input() public sfElPositionDirective: HTMLElement | null = null;
  @Input() public distance = 100;
  @Input() public distanceTop = 0;

  public calculate(): void {
    const el: HTMLElement = this.elRef.nativeElement;

    const elTop = Math.round(el.getBoundingClientRect().top);

    const elBottom = Math.round(el.getBoundingClientRect().bottom);
    let comparedElTop = window.innerHeight;

    if (this.sfElPositionDirective) {
      comparedElTop = this.sfElPositionDirective.getBoundingClientRect().top;
    }

    const bottomState = (elBottom + this.distance) >= comparedElTop;
    const topState = elTop <= this.distanceTop;

    el.classList.remove('element-open-top');

    if (bottomState && !topState) {
      el.classList.add('element-open-top');
    }
  }

}
