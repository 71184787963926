@if (appStateService.user?.emailVerified) {
  @if (appStateService.user?.referAndEarn?.expired && !appStateService.user?.referAndEarn?.eligible) {
    <div class="coupon-wrapper">
      <h3>Refer a friend and become a premium user for a month.</h3>

      <div class="coupon-desc">
        Receive one month free for each friend who signs up and becomes a premium user. <br>
        You can activate your free premium plan whenever you want.
      </div>

      <div class="coupon-id" (click)="copyCoupon()">
        {{ appStateService.user?.referAndEarn?.coupon }}

        @if (couponCopiedState) {
          <i class="ph ph-check"></i>
        } @else {
          <i
            class="ph ph-copy-simple"
             [nz-tooltip]="'tooltips.copy_clipboard' | translate"
            [nzTooltipMouseEnterDelay]="1"
          ></i>
        }
      </div>
    </div>
  }
}

