// Generated by CoffeeScript 1.12.7
(function () {
  var XMLNode,
    XMLProcessingInstruction,
    extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    hasProp = {}.hasOwnProperty;
  XMLNode = require('./XMLNode');
  module.exports = XMLProcessingInstruction = function (superClass) {
    extend(XMLProcessingInstruction, superClass);
    function XMLProcessingInstruction(parent, target, value) {
      XMLProcessingInstruction.__super__.constructor.call(this, parent);
      if (target == null) {
        throw new Error("Missing instruction target. " + this.debugInfo());
      }
      this.target = this.stringify.insTarget(target);
      if (value) {
        this.value = this.stringify.insValue(value);
      }
    }
    XMLProcessingInstruction.prototype.clone = function () {
      return Object.create(this);
    };
    XMLProcessingInstruction.prototype.toString = function (options) {
      return this.options.writer.set(options).processingInstruction(this);
    };
    return XMLProcessingInstruction;
  }(XMLNode);
}).call(this);