// Generated by CoffeeScript 1.12.7
(function () {
  var XMLAttribute;
  module.exports = XMLAttribute = function () {
    function XMLAttribute(parent, name, value) {
      this.options = parent.options;
      this.stringify = parent.stringify;
      this.parent = parent;
      if (name == null) {
        throw new Error("Missing attribute name. " + this.debugInfo(name));
      }
      if (value == null) {
        throw new Error("Missing attribute value. " + this.debugInfo(name));
      }
      this.name = this.stringify.attName(name);
      this.value = this.stringify.attValue(value);
    }
    XMLAttribute.prototype.clone = function () {
      return Object.create(this);
    };
    XMLAttribute.prototype.toString = function (options) {
      return this.options.writer.set(options).attribute(this);
    };
    XMLAttribute.prototype.debugInfo = function (name) {
      name = name || this.name;
      if (name == null) {
        return "parent: <" + this.parent.name + ">";
      } else {
        return "attribute: {" + name + "}, parent: <" + this.parent.name + ">";
      }
    };
    return XMLAttribute;
  }();
}).call(this);