"use strict";

module.exports = function (Promise, PromiseArray, debug) {
  var PromiseInspection = Promise.PromiseInspection;
  var util = require("./util");
  function SettledPromiseArray(values) {
    this.constructor$(values);
  }
  util.inherits(SettledPromiseArray, PromiseArray);
  SettledPromiseArray.prototype._promiseResolved = function (index, inspection) {
    this._values[index] = inspection;
    var totalResolved = ++this._totalResolved;
    if (totalResolved >= this._length) {
      this._resolve(this._values);
      return true;
    }
    return false;
  };
  SettledPromiseArray.prototype._promiseFulfilled = function (value, index) {
    var ret = new PromiseInspection();
    ret._bitField = 33554432;
    ret._settledValueField = value;
    return this._promiseResolved(index, ret);
  };
  SettledPromiseArray.prototype._promiseRejected = function (reason, index) {
    var ret = new PromiseInspection();
    ret._bitField = 16777216;
    ret._settledValueField = reason;
    return this._promiseResolved(index, ret);
  };
  Promise.settle = function (promises) {
    debug.deprecated(".settle()", ".reflect()");
    return new SettledPromiseArray(promises).promise();
  };
  Promise.prototype.settle = function () {
    return Promise.settle(this);
  };
};