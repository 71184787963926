import {SingleTemplateThemeColor} from "@app/shared/interfaces";


/** This one has background: white sidebar */
export const template4Themes: SingleTemplateThemeColor = {
  theme1: {
    id: 1,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#4B57DC',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#2A3958',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2A3958',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#4B57DC',
    countryColor: '#4B57DC',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#4B57DC',

      border: {
        borderLeftColor: '#4B57DC',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#2A3958',
      sectionSubtitleEditModeFontColor: '#2A3958',

      topPartBgColor: '#fff',

      dateColor: '#4B57DC',
      countryColor: '#4B57DC',

      linkColor: '#4B57DC',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4B57DC',
        borderColor: '#4B57DC',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2A3958',
        borderColor: '#2A3958',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4B57DC',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      }
    },
    emptySection: {
      bgColor: 'rgba(75,87,220, .2)',
      btnBgColor: '#4B57DC',
      btnBorderColor: '#4B57DC'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A3958',
    demoColor2: '#4B57DC',

    linkColor: '#4B57DC'
  }, // template 1 color
  theme2: {
    id: 2,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#69748B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#374259',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#374259',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#374259',
    countryColor: '#374259',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#69748B',

      border: {
        borderLeftColor: '#69748B',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#374259',
      sectionSubtitleEditModeFontColor: '#374259',

      topPartBgColor: '#fff',

      dateColor: '#374259',
      countryColor: '#374259',

      linkColor: '#69748B',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#69748B',
        borderColor: '#69748B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#374259',
        borderColor: '#374259',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#69748B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#69748B',
          borderColor: '#69748B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#69748B',
          borderColor: '#69748B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#69748B',
        borderColor: '#69748B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#69748B',
        borderColor: '#69748B',
      }
    },
    emptySection: {
      bgColor: 'rgba(105,116,139, .2)',
      btnBgColor: '#69748B',
      btnBorderColor: '#69748B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#69748B'
  },
  theme3: {
    id: 3,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#647982',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#324750',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#324750',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#324750',
    countryColor: '#324750',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#647982',

      border: {
        borderLeftColor: '#647982',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#324750',
      sectionSubtitleEditModeFontColor: '#324750',

      topPartBgColor: '#fff',

      dateColor: '#324750',
      countryColor: '#324750',

      linkColor: '#647982',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#647982',
        borderColor: '#647982',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#324750',
        borderColor: '#324750',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#647982',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#647982',
        borderColor: '#647982',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#647982',
        borderColor: '#647982',
      }
    },
    emptySection: {
      bgColor: 'rgba(100,121,130, .2)',
      btnBgColor: '#647982',
      btnBorderColor: '#647982'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#808B8F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#4E595D',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#4E595D',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#4E595D',
    countryColor: '#4E595D',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#808B8F',

      border: {
        borderLeftColor: '#808B8F',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#4E595D',
      sectionSubtitleEditModeFontColor: '#4E595D',

      topPartBgColor: '#fff',

      dateColor: '#4E595D',
      countryColor: '#4E595D',

      linkColor: '#808B8F',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#808B8F',
        borderColor: '#808B8F',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4E595D',
        borderColor: '#4E595D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#808B8F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#808B8F',
          borderColor: '#808B8F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#808B8F',
          borderColor: '#808B8F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#808B8F',
        borderColor: '#808B8F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#808B8F',
        borderColor: '#808B8F',
      }
    },
    emptySection: {
      bgColor: 'rgba(128,139,143, .2)',
      btnBgColor: '#808B8F',
      btnBorderColor: '#808B8F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#808B8F'
  },
  theme5: {
    id: 5,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#828293',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#505061',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#505061',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#505061',
    countryColor: '#505061',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#828293',

      border: {
        borderLeftColor: '#828293',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#505061',
      sectionSubtitleEditModeFontColor: '#505061',

      topPartBgColor: '#fff',

      dateColor: '#505061',
      countryColor: '#505061',

      linkColor: '#828293',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#828293',
        borderColor: '#828293',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#505061',
        borderColor: '#505061',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#828293',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#828293',
        borderColor: '#828293',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#828293',
        borderColor: '#828293',
      }
    },
    emptySection: {
      bgColor: 'rgba(130,130,147, .2)',
      btnBgColor: '#828293',
      btnBorderColor: '#828293'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#505061',
    demoColor2: '#828293',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#A49393',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#5B5054',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#5B5054',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#5B5054',
    countryColor: '#5B5054',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#A49393',

      border: {
        borderLeftColor: '#A49393',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#5B5054',
      sectionSubtitleEditModeFontColor: '#5B5054',

      topPartBgColor: '#fff',

      dateColor: '#5B5054',
      countryColor: '#5B5054',

      linkColor: '#A49393',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#A49393',
        borderColor: '#A49393',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5B5054',
        borderColor: '#5B5054',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#A49393',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#A49393',
          borderColor: '#A49393',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#A49393',
          borderColor: '#A49393',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#A49393',
        borderColor: '#A49393',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#A49393',
        borderColor: '#A49393',
      }
    },
    emptySection: {
      bgColor: 'rgba(164,147,147, .2)',
      btnBgColor: '#A49393',
      btnBorderColor: '#A49393'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#A49393'
  }, // template 5 color
  theme7: {
    id: 7,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#43779F',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#1B4F77',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#1B4F77',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#1B4F77',
    countryColor: '#1B4F77',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#43779F',

      border: {
        borderLeftColor: '#43779F',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#1B4F77',
      sectionSubtitleEditModeFontColor: '#1B4F77',

      topPartBgColor: '#fff',

      dateColor: '#1B4F77',
      countryColor: '#1B4F77',

      linkColor: '#43779F',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#43779F',
        borderColor: '#43779F',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#1B4F77',
        borderColor: '#1B4F77',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#43779F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      }
    },
    emptySection: {
      bgColor: 'rgba(67,119,159, .2)',
      btnBgColor: '#43779F',
      btnBorderColor: '#43779F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1B4F77',
    demoColor2: '#43779F',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#2983BA',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#005188',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#005188',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#005188',
    countryColor: '#005188',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#2983BA',

      border: {
        borderLeftColor: '#2983BA',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#005188',
      sectionSubtitleEditModeFontColor: '#005188',

      topPartBgColor: '#fff',

      dateColor: '#005188',
      countryColor: '#005188',

      linkColor: '#2983BA',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#2983BA',
        borderColor: '#2983BA',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#005188',
        borderColor: '#005188',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2983BA',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2983BA',
          borderColor: '#2983BA',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2983BA',
          borderColor: '#2983BA',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#2983BA',
        borderColor: '#2983BA',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#2983BA',
        borderColor: '#2983BA',
      }
    },
    emptySection: {
      bgColor: 'rgba(41,131,186, .2)',
      btnBgColor: '#2983BA',
      btnBorderColor: '#2983BA'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#2983BA'
  },
  theme9: {
    id: 9,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#6C6EA5',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#3A3C73',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#3A3C73',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#3A3C73',
    countryColor: '#3A3C73',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#6C6EA5',

      border: {
        borderLeftColor: '#6C6EA5',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#3A3C73',
      sectionSubtitleEditModeFontColor: '#3A3C73',

      topPartBgColor: '#fff',

      dateColor: '#3A3C73',
      countryColor: '#3A3C73',

      linkColor: '#6C6EA5',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3A3C73',
        borderColor: '#3A3C73',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#6C6EA5',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#6C6EA5',
          borderColor: '#6C6EA5',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#6C6EA5',
          borderColor: '#6C6EA5',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#6C6EA5',
        borderColor: '#6C6EA5',
      }
    },
    emptySection: {
      bgColor: 'rgba(108,110,165, .2)',
      btnBgColor: '#6C6EA5',
      btnBorderColor: '#6C6EA5'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#6C6EA5'
  },
  theme10: {
    id: 10,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5A79C7',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#39559A',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#39559A',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#39559A',
    countryColor: '#39559A',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#5A79C7',

      border: {
        borderLeftColor: '#5A79C7',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#39559A',
      sectionSubtitleEditModeFontColor: '#39559A',

      topPartBgColor: '#fff',

      dateColor: '#39559A',
      countryColor: '#39559A',

      linkColor: '#5A79C7',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#5A79C7',
        borderColor: '#5A79C7',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#39559A',
        borderColor: '#39559A',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5A79C7',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      }
    },
    emptySection: {
      bgColor: 'rgba(90,121,199, .2)',
      btnBgColor: '#5A79C7',
      btnBorderColor: '#5A79C7'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#39559A',
    demoColor2: '#5A79C7',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#516D92',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#1F3B60',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#1F3B60',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#1F3B60',
    countryColor: '#1F3B60',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#516D92',

      border: {
        borderLeftColor: '#516D92',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#1F3B60',
      sectionSubtitleEditModeFontColor: '#1F3B60',

      topPartBgColor: '#fff',

      dateColor: '#1F3B60',
      countryColor: '#1F3B60',

      linkColor: '#516D92',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#516D92',
        borderColor: '#516D92',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#1F3B60',
        borderColor: '#1F3B60',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#516D92',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      }
    },
    emptySection: {
      bgColor: 'rgba(81,109,146, .2)',
      btnBgColor: '#516D92',
      btnBorderColor: '#516D92'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1F3B60',
    demoColor2: '#516D92',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3C7680',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#0A444E',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#0A444E',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#0A444E',
    countryColor: '#0A444E',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#3C7680',

      border: {
        borderLeftColor: '#3C7680',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#0A444E',
      sectionSubtitleEditModeFontColor: '#0A444E',

      topPartBgColor: '#fff',

      dateColor: '#0A444E',
      countryColor: '#0A444E',

      linkColor: '#3C7680',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#3C7680',
        borderColor: '#3C7680',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#0A444E',
        borderColor: '#0A444E',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3C7680',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      }
    },
    emptySection: {
      bgColor: 'rgba(60,118,128, .2)',
      btnBgColor: '#3C7680',
      btnBorderColor: '#3C7680'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#0A444E',
    demoColor2: '#3C7680',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#527F7A',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#2A5752',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2A5752',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#2A5752',
    countryColor: '#2A5752',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#527F7A',

      border: {
        borderLeftColor: '#527F7A',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#2A5752',
      sectionSubtitleEditModeFontColor: '#2A5752',

      topPartBgColor: '#fff',

      dateColor: '#2A5752',
      countryColor: '#2A5752',

      linkColor: '#527F7A',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#527F7A',
        borderColor: '#527F7A',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2A5752',
        borderColor: '#2A5752',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#527F7A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#527F7A',
          borderColor: '#527F7A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#527F7A',
          borderColor: '#527F7A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#527F7A',
        borderColor: '#527F7A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#527F7A',
        borderColor: '#527F7A',
      }
    },
    emptySection: {
      bgColor: 'rgba(82,127,122, .2)',
      btnBgColor: '#527F7A',
      btnBorderColor: '#527F7A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#527F7A'
  },
  theme14: {
    id: 14,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#519097',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#29686F',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#29686F',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#29686F',
    countryColor: '#29686F',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#519097',

      border: {
        borderLeftColor: '#519097',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#29686F',
      sectionSubtitleEditModeFontColor: '#29686F',

      topPartBgColor: '#fff',

      dateColor: '#29686F',
      countryColor: '#29686F',

      linkColor: '#519097',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#519097',
        borderColor: '#519097',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#29686F',
        borderColor: '#29686F',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#519097',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#519097',
        borderColor: '#519097',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#519097',
        borderColor: '#519097',
      }
    },
    emptySection: {
      bgColor: 'rgba(81,144,151, .2)',
      btnBgColor: '#519097',
      btnBorderColor: '#519097'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#29686F',
    demoColor2: '#519097',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#728686',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#405454',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#405454',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#405454',
    countryColor: '#405454',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#728686',

      border: {
        borderLeftColor: '#728686',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#405454',
      sectionSubtitleEditModeFontColor: '#405454',

      topPartBgColor: '#fff',

      dateColor: '#405454',
      countryColor: '#405454',

      linkColor: '#728686',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#728686',
        borderColor: '#728686',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#405454',
        borderColor: '#405454',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#728686',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#728686',
        borderColor: '#728686',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#728686',
        borderColor: '#728686',
      }
    },
    emptySection: {
      bgColor: 'rgba(114,134,134, .2)',
      btnBgColor: '#728686',
      btnBorderColor: '#728686'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#405454',
    demoColor2: '#728686',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#7E8A7B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#606C5D',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#606C5D',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#606C5D',
    countryColor: '#606C5D',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#7E8A7B',

      border: {
        borderLeftColor: '#7E8A7B',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#606C5D',
      sectionSubtitleEditModeFontColor: '#606C5D',

      topPartBgColor: '#fff',

      dateColor: '#606C5D',
      countryColor: '#606C5D',

      linkColor: '#7E8A7B',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#606C5D',
        borderColor: '#606C5D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#7E8A7B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#7E8A7B',
          borderColor: '#7E8A7B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#7E8A7B',
          borderColor: '#7E8A7B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#7E8A7B',
        borderColor: '#7E8A7B',
      }
    },
    emptySection: {
      bgColor: 'rgba(126,138,123, .2)',
      btnBgColor: '#7E8A7B',
      btnBorderColor: '#7E8A7B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B',

    linkColor: '#7E8A7B'
  },
  theme17: {
    id: 17,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#ACCEC8',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#2B2E35',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#2B2E35',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#2B2E35',
    countryColor: '#2B2E35',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#ACCEC8',

      border: {
        borderLeftColor: '#ACCEC8',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#2B2E35',
      sectionSubtitleEditModeFontColor: '#2B2E35',

      topPartBgColor: '#fff',

      dateColor: '#2B2E35',
      countryColor: '#2B2E35',

      linkColor: '#ACCEC8',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2B2E35',
        borderColor: '#2B2E35',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#ACCEC8',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#ACCEC8',
          borderColor: '#ACCEC8',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#ACCEC8',
          borderColor: '#ACCEC8',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#ACCEC8',
        borderColor: '#ACCEC8',
      }
    },
    emptySection: {
      bgColor: 'rgba(172,206,200, .2)',
      btnBgColor: '#ACCEC8',
      btnBorderColor: '#ACCEC8'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2B2E35',
    demoColor2: '#ACCEC8',

    linkColor: '#ACCEC8'
  },  // template 2 color
  theme18: {
    id: 18,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#908071',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#5E4E3F',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#5E4E3F',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#5E4E3F',
    countryColor: '#5E4E3F',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#908071',

      border: {
        borderLeftColor: '#908071',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#5E4E3F',
      sectionSubtitleEditModeFontColor: '#5E4E3F',

      topPartBgColor: '#fff',

      dateColor: '#5E4E3F',
      countryColor: '#5E4E3F',

      linkColor: '#908071',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#908071',
        borderColor: '#908071',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5E4E3F',
        borderColor: '#5E4E3F',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#908071',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#908071',
        borderColor: '#908071',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#908071',
        borderColor: '#908071',
      }
    },
    emptySection: {
      bgColor: 'rgba(144,128,113, .2)',
      btnBgColor: '#908071',
      btnBorderColor: '#908071'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5E4E3F',
    demoColor2: '#908071',

    linkColor: '#908071'
  },
  theme19: {
    id: 19,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8E6A64',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#66423C',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#66423C',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#66423C',
    countryColor: '#66423C',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8E6A64',

      border: {
        borderLeftColor: '#8E6A64',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#66423C',
      sectionSubtitleEditModeFontColor: '#66423C',

      topPartBgColor: '#fff',

      dateColor: '#66423C',
      countryColor: '#66423C',

      linkColor: '#8E6A64',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8E6A64',
        borderColor: '#8E6A64',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#66423C',
        borderColor: '#66423C',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8E6A64',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      }
    },
    emptySection: {
      bgColor: 'rgba(142,106,100, .2)',
      btnBgColor: '#8E6A64',
      btnBorderColor: '#8E6A64'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#66423C',
    demoColor2: '#8E6A64',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#7F536B',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#572B43',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#572B43',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#572B43',
    countryColor: '#572B43',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#7F536B',

      border: {
        borderLeftColor: '#7F536B',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#572B43',
      sectionSubtitleEditModeFontColor: '#572B43',

      topPartBgColor: '#fff',

      dateColor: '#572B43',
      countryColor: '#572B43',

      linkColor: '#7F536B',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#7F536B',
        borderColor: '#7F536B',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#572B43',
        borderColor: '#572B43',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#7F536B',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#7F536B',
          borderColor: '#7F536B',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#7F536B',
          borderColor: '#7F536B',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#7F536B',
        borderColor: '#7F536B',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#7F536B',
        borderColor: '#7F536B',
      }
    },
    emptySection: {
      bgColor: 'rgba(127,83,107, .2)',
      btnBgColor: '#7F536B',
      btnBorderColor: '#7F536B'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#7F536B'
  },
  theme21: {
    id: 21,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#626262',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#303030',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#303030',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#303030',
    countryColor: '#303030',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#626262',

      border: {
        borderLeftColor: '#626262',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#303030',
      sectionSubtitleEditModeFontColor: '#303030',

      topPartBgColor: '#fff',

      dateColor: '#303030',
      countryColor: '#303030',

      linkColor: '#626262',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#626262',
        borderColor: '#626262',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#303030',
        borderColor: '#303030',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#626262',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#626262',
        borderColor: '#626262',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#626262',
        borderColor: '#626262',
      }
    },
    emptySection: {
      bgColor: 'rgba(98,98,98, .2)',
      btnBgColor: '#626262',
      btnBorderColor: '#626262'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#626262',

    linkColor: '#626262'
  },  // black one, do not copy from this one
  theme22: {
    id: 22,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#BE6A9A',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#8C3868',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#8C3868',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#8C3868',
    countryColor: '#8C3868',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#BE6A9A',

      border: {
        borderLeftColor: '#BE6A9A',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8C3868',
      sectionSubtitleEditModeFontColor: '#8C3868',

      topPartBgColor: '#fff',

      dateColor: '#8C3868',
      countryColor: '#8C3868',

      linkColor: '#BE6A9A',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8C3868',
        borderColor: '#8C3868',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#BE6A9A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      }
    },
    emptySection: {
      bgColor: 'rgba(190,106,154, .2)',
      btnBgColor: '#BE6A9A',
      btnBorderColor: '#BE6A9A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C3868',
    demoColor2: '#BE6A9A',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#9D6C88',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#6B3A56',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#6B3A56',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#6B3A56',
    countryColor: '#6B3A56',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#9D6C88',

      border: {
        borderLeftColor: '#9D6C88',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#6B3A56',
      sectionSubtitleEditModeFontColor: '#6B3A56',

      topPartBgColor: '#fff',

      dateColor: '#6B3A56',
      countryColor: '#6B3A56',

      linkColor: '#9D6C88',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9D6C88',
        borderColor: '#9D6C88',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#6B3A56',
        borderColor: '#6B3A56',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#9D6C88',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#9D6C88',
          borderColor: '#9D6C88',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#9D6C88',
          borderColor: '#9D6C88',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9D6C88',
        borderColor: '#9D6C88',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#9D6C88',
        borderColor: '#9D6C88',
      }
    },
    emptySection: {
      bgColor: 'rgba(157,108,136, .2)',
      btnBgColor: '#9D6C88',
      btnBorderColor: '#9D6C88'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#9D6C88'
  },
  theme24: {
    id: 24,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#E0589E',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#E0589E',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#E0589E',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#B05CAC',
    countryColor: '#B05CAC',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#E0589E',

      border: {
        borderLeftColor: '#E0589E',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8C6194',
      sectionSubtitleEditModeFontColor: '#8C6194',

      topPartBgColor: '#fff',

      dateColor: '#B05CAC',
      countryColor: '#B05CAC',

      linkColor: '#8C6194',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8C6194',
        borderColor: '#8C6194',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4C3551',
        borderColor: '#4C3551',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8C6194',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      }
    },
    emptySection: {
      bgColor: 'rgb(231,204,237)',
      btnBgColor: '#E0589E',
      btnBorderColor: '#E0589E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#B05CAC',
    demoColor2: '#E0589E',

    linkColor: '#B05CAC'
  },
  theme25: {
    id: 25,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8C6194',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#4C3551',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#4C3551',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#4C3551',
    countryColor: '#4C3551',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8C6194',

      border: {
        borderLeftColor: '#8C6194',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#4C3551',
      sectionSubtitleEditModeFontColor: '#4C3551',

      topPartBgColor: '#fff',

      dateColor: '#4C3551',
      countryColor: '#4C3551',

      linkColor: '#8C6194',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8C6194',
        borderColor: '#8C6194',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4C3551',
        borderColor: '#4C3551',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8C6194',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      }
    },
    emptySection: {
      bgColor: 'rgba(140,97,148, .2)',
      btnBgColor: '#8C6194',
      btnBorderColor: '#8C6194'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4C3551',
    demoColor2: '#8C6194',

    linkColor: '#8C6194'
  }, // template 3 color
  theme26: {
    id: 26,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#9B89BD',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#736195',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#736195',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#736195',
    countryColor: '#736195',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#9B89BD',

      border: {
        borderLeftColor: '#9B89BD',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#736195',
      sectionSubtitleEditModeFontColor: '#736195',

      topPartBgColor: '#fff',

      dateColor: '#736195',
      countryColor: '#736195',

      linkColor: '#9B89BD',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9B89BD',
        borderColor: '#9B89BD',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#736195',
        borderColor: '#736195',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#9B89BD',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      }
    },
    emptySection: {
      bgColor: 'rgba(155,137,189, .2)',
      btnBgColor: '#9B89BD',
      btnBorderColor: '#9B89BD'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#736195',
    demoColor2: '#9B89BD',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#995D93',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#672B61',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#672B61',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#672B61',
    countryColor: '#672B61',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#995D93',

      border: {
        borderLeftColor: '#995D93',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#672B61',
      sectionSubtitleEditModeFontColor: '#672B61',

      topPartBgColor: '#fff',

      dateColor: '#672B61',
      countryColor: '#672B61',

      linkColor: '#995D93',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#995D93',
        borderColor: '#995D93',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#672B61',
        borderColor: '#672B61',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#995D93',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      }
    },
    emptySection: {
      bgColor: 'rgba(153,93,147, .2)',
      btnBgColor: '#995D93',
      btnBorderColor: '#995D93'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#672B61',
    demoColor2: '#995D93',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8A6B9E',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#58396C',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#58396C',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#58396C',
    countryColor: '#58396C',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8A6B9E',

      border: {
        borderLeftColor: '#8A6B9E',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#58396C',
      sectionSubtitleEditModeFontColor: '#58396C',

      topPartBgColor: '#fff',

      dateColor: '#58396C',
      countryColor: '#58396C',

      linkColor: '#8A6B9E',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#58396C',
        borderColor: '#58396C',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8A6B9E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      }
    },
    emptySection: {
      bgColor: 'rgba(138,107,158, .2)',
      btnBgColor: '#8A6B9E',
      btnBorderColor: '#8A6B9E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#58396C',
    demoColor2: '#8A6B9E',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#65758D',

    topPartBgColor: '#fff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#fff',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#3D4D65',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#FFF',
    sectionMainTitleFontEditModeColor: '#303030',
    sectionMainTitleBgColor: '#3D4D65',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#303030',

    dateColor: '#3D4D65',
    countryColor: '#3D4D65',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#65758D',

      border: {
        borderLeftColor: '#65758D',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#303030',
      sectionMainTitleFontEditModeColor: '#303030',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#3D4D65',
      sectionSubtitleEditModeFontColor: '#3D4D65',

      topPartBgColor: '#fff',

      dateColor: '#3D4D65',
      countryColor: '#3D4D65',

      linkColor: '#65758D',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#65758D',
        borderColor: '#65758D',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3D4D65',
        borderColor: '#3D4D65',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#65758D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      }
    },
    emptySection: {
      bgColor: 'rgba(101,117,141, .2)',
      btnBgColor: '#65758D',
      btnBorderColor: '#65758D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3D4D65',
    demoColor2: '#65758D',

    linkColor: '#65758D'
  },
};
