// Generated by CoffeeScript 1.12.7
(function () {
  var XMLDTDAttList,
    XMLDTDElement,
    XMLDTDEntity,
    XMLDTDNotation,
    XMLDocType,
    XMLNode,
    isObject,
    extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    hasProp = {}.hasOwnProperty;
  isObject = require('./Utility').isObject;
  XMLNode = require('./XMLNode');
  XMLDTDAttList = require('./XMLDTDAttList');
  XMLDTDEntity = require('./XMLDTDEntity');
  XMLDTDElement = require('./XMLDTDElement');
  XMLDTDNotation = require('./XMLDTDNotation');
  module.exports = XMLDocType = function (superClass) {
    extend(XMLDocType, superClass);
    function XMLDocType(parent, pubID, sysID) {
      var ref, ref1;
      XMLDocType.__super__.constructor.call(this, parent);
      this.name = "!DOCTYPE";
      this.documentObject = parent;
      if (isObject(pubID)) {
        ref = pubID, pubID = ref.pubID, sysID = ref.sysID;
      }
      if (sysID == null) {
        ref1 = [pubID, sysID], sysID = ref1[0], pubID = ref1[1];
      }
      if (pubID != null) {
        this.pubID = this.stringify.dtdPubID(pubID);
      }
      if (sysID != null) {
        this.sysID = this.stringify.dtdSysID(sysID);
      }
    }
    XMLDocType.prototype.element = function (name, value) {
      var child;
      child = new XMLDTDElement(this, name, value);
      this.children.push(child);
      return this;
    };
    XMLDocType.prototype.attList = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
      var child;
      child = new XMLDTDAttList(this, elementName, attributeName, attributeType, defaultValueType, defaultValue);
      this.children.push(child);
      return this;
    };
    XMLDocType.prototype.entity = function (name, value) {
      var child;
      child = new XMLDTDEntity(this, false, name, value);
      this.children.push(child);
      return this;
    };
    XMLDocType.prototype.pEntity = function (name, value) {
      var child;
      child = new XMLDTDEntity(this, true, name, value);
      this.children.push(child);
      return this;
    };
    XMLDocType.prototype.notation = function (name, value) {
      var child;
      child = new XMLDTDNotation(this, name, value);
      this.children.push(child);
      return this;
    };
    XMLDocType.prototype.toString = function (options) {
      return this.options.writer.set(options).docType(this);
    };
    XMLDocType.prototype.ele = function (name, value) {
      return this.element(name, value);
    };
    XMLDocType.prototype.att = function (elementName, attributeName, attributeType, defaultValueType, defaultValue) {
      return this.attList(elementName, attributeName, attributeType, defaultValueType, defaultValue);
    };
    XMLDocType.prototype.ent = function (name, value) {
      return this.entity(name, value);
    };
    XMLDocType.prototype.pent = function (name, value) {
      return this.pEntity(name, value);
    };
    XMLDocType.prototype.not = function (name, value) {
      return this.notation(name, value);
    };
    XMLDocType.prototype.up = function () {
      return this.root() || this.documentObject;
    };
    return XMLDocType;
  }(XMLNode);
}).call(this);