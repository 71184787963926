<div class="user-delete-main-container feedback-cnt">
  <form [formGroup]="feedbackForm" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    <div class="delete-part-container">

      <div class="top-part">
        <h2>We'd welcome your feedback!</h2>
        <p>Your feedback would be appreciated to improve our product.</p>
      </div>

      <div class="mt-20">Please rate us:</div>
      <nz-rate formControlName="stars"></nz-rate>

      <nz-divider></nz-divider>

      <div class="sf-input-wr mb-20" *ngIf="!appStateService.user">
        <input
          class="sf-input"
          type="text"
          placeholder="Email"
          formControlName="email"
          sfTrim
          [class.error]="form.submitted && feedbackForm.controls['email'].errors">
        <div class="sf-err-wr" *ngIf="form.submitted && feedbackForm.controls['email']?.errors as error">
          <div class="sf-input-err" *ngIf="error['required']">
            <i class="ph ph-warning"></i>
            Email is required
          </div>
          <div class="sf-input-err" *ngIf="error['email']">
            <i class="ph ph-warning"></i>
            Email is invalid
          </div>
        </div>
      </div>

      <div class="textarea-part">
        <p>Is there anything else you’d like to share with the team?</p>
        <textarea
          class="sf-textarea"
          [autofocus]="false"
          placeholder="We read all of these messages, one by one. Seriously."
          formControlName="feedback"
        ></textarea>
      </div>

      <div>
        <div class="clearfix mt-18">
          <nz-upload
            nzListType="picture"
            nzAccept="image/*, application/pdf, .txt, .csv"
            [nzMultiple]="true"
            [(nzFileList)]="fileList"
            [nzShowButton]="fileList.length < 4"
            [nzLimit]="4"
            [nzBeforeUpload]="beforeUpload">
            <button nz-button type="button" class="file-upload-btn">
              <span nz-icon nzType="upload"></span>
              Click or Drag to Upload
            </button>
          </nz-upload>
        </div>
      </div>

      <div
        class="sf-info-banner error ph ph-x-circle mt-30"
        *ngIf="form.submitted && feedbackForm?.errors?.['requiredAtLeastOne']">
        <div>
          <div>Error</div>
          <p>Please rate us or write your feedback.</p>
        </div>
      </div>

      <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="fileSizeError">
        <div>
          <div>Error</div>
          <p>Upload file size limit is 5MB.</p>
        </div>
      </div>

      <nz-divider></nz-divider>

      <div class="buttons-part">
        <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="onCloseModal()">Cancel</button>
        <button
          type="submit" class="sf-btn-primary small w-80" [class.loading]="btnLoaderState">
          Save
        </button>
      </div>

    </div>
  </form>

</div>

