import {FAQGroup} from "@app/shared/interfaces";

export const FAQ_OBJ: FAQGroup = {
  homePage: {
    title: 'FAQ.homePage.title',
    items: [
      {
        title: 'FAQ.homePage.is_free_title',
        info: 'FAQ.homePage.is_free_desc',
      },
      {
        title: 'FAQ.homePage.ATS_title',
        info: 'FAQ.homePage.ATS_desc',
      },
      {
        title: 'FAQ.homePage.payment_one_time_title',
        info: 'FAQ.homePage.payment_one_time_desc',
      },
      {
        title: 'FAQ.homePage.download_format_title',
        info: 'FAQ.homePage.download_format_desc',
      },
      {
        title: 'FAQ.homePage.share_resume_title',
        info: 'FAQ.homePage.share_resume_desc',
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'FAQ.homePage.remove_branding_title',
        info: 'FAQ.homePage.remove_branding_desc',
      },
      {
        title: 'FAQ.homePage.plan_expires_title',
        info: 'FAQ.homePage.plan_expires_desc',
      },
    ],
  },
  cvTemplates: {
    title: 'FAQ.resumeTemplates.title',
    items: [
      {
        title: 'FAQ.homePage.is_free_title',
        info: 'FAQ.homePage.is_free_desc',
      },
      {
        title: 'FAQ.homePage.ATS_title',
        info: 'FAQ.homePage.ATS_desc',
      },
      {
        title: 'FAQ.homePage.payment_one_time_title',
        info: 'FAQ.homePage.payment_one_time_desc',
      },
      {
        title: 'FAQ.homePage.download_format_title',
        info: 'FAQ.homePage.download_format_desc',
      },
      {
        title: 'FAQ.homePage.share_resume_title',
        info: 'FAQ.homePage.share_resume_desc',
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'FAQ.homePage.remove_branding_title',
        info: 'FAQ.homePage.remove_branding_desc',
      },
      {
        title: 'FAQ.homePage.plan_expires_title',
        info: 'FAQ.homePage.plan_expires_desc',
      },
    ],
  },
  coverLetter: {
    title: 'FAQ.coverLetter.title',
    items: [
      {
        title: 'FAQ.coverLetter.right_cover_letter_title',
        info: 'FAQ.coverLetter.right_cover_letter_desc',
      },
      {
        title: 'FAQ.homePage.payment_one_time_title',
        info: 'FAQ.homePage.payment_one_time_title',
      },
      {
        title: 'FAQ.homePage.download_format_title',
        info: 'FAQ.homePage.download_format_desc',
      },
      {
        title: 'FAQ.coverLetter.share_cover_title',
        info: 'FAQ.coverLetter.share_cover_desc',
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'FAQ.homePage.plan_expires_title',
        info: 'FAQ.homePage.plan_expires_desc',
      },
    ]
  },
  accountSubscription: {
    title: 'FAQ.homePage.title',
    items: [
      {
        title: 'FAQ.homePage.is_free_title',
        info: 'FAQ.homePage.is_free_desc',
      },
      {
        title: 'FAQ.homePage.ATS_title',
        info: 'FAQ.homePage.ATS_desc',
      },
      {
        title: 'FAQ.homePage.payment_one_time_title',
        info: 'FAQ.homePage.payment_one_time_desc',
      },
      {
        title: 'FAQ.homePage.download_format_title',
        info: 'FAQ.homePage.download_format_desc',
      },
      {
        title: 'FAQ.homePage.share_resume_title',
        info: 'FAQ.homePage.share_resume_desc',
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'FAQ.homePage.remove_branding_title',
        info: 'FAQ.homePage.remove_branding_desc',
      },
      {
        title: 'FAQ.homePage.plan_expires_title',
        info: 'FAQ.homePage.plan_expires_desc',
      },
    ],
  },

  resumeTemplates: {
    title: 'FAQ.resumeTemplates.title',
    items: [
      {
        title: 'Are selfcv templates free?',
        info: 'Yes, all selfcv templates are free, but there’s also an option to upgrade to get access to premium features.'
      },
      {
        title: 'Are the templates ATS-compatible?',
        info: 'Yes, all the templates are ATS-compatible, so any template you build using selfcv will be made ATS-compliant automatically, and there’s no need to take any additional actions'
      },
      {
        title: 'Who are the templates made by?',
        info: 'The templates are made by our professional designers based on industry best practices and with Applicant Tracking Systems in mind. Our goal is to create templates that will make your job application stand out, maximizing your chances of getting hired.'
      },
      {
        title: 'How many pages do the templates have?',
        info: 'By default, our templates are one-page documents, but you can always expand and adjust the length based on your needs.'
      },
    ]
  },
}
