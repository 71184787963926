exports.readStylesXml = readStylesXml;
exports.Styles = Styles;
exports.defaultStyles = new Styles({}, {});
function Styles(paragraphStyles, characterStyles, tableStyles, numberingStyles) {
  return {
    findParagraphStyleById: function (styleId) {
      return paragraphStyles[styleId];
    },
    findCharacterStyleById: function (styleId) {
      return characterStyles[styleId];
    },
    findTableStyleById: function (styleId) {
      return tableStyles[styleId];
    },
    findNumberingStyleById: function (styleId) {
      return numberingStyles[styleId];
    }
  };
}
Styles.EMPTY = new Styles({}, {}, {}, {});
function readStylesXml(root) {
  var paragraphStyles = {};
  var characterStyles = {};
  var tableStyles = {};
  var numberingStyles = {};
  var styles = {
    "paragraph": paragraphStyles,
    "character": characterStyles,
    "table": tableStyles
  };
  root.getElementsByTagName("w:style").forEach(function (styleElement) {
    var style = readStyleElement(styleElement);
    if (style.type === "numbering") {
      numberingStyles[style.styleId] = readNumberingStyleElement(styleElement);
    } else {
      var styleSet = styles[style.type];
      if (styleSet) {
        styleSet[style.styleId] = style;
      }
    }
  });
  return new Styles(paragraphStyles, characterStyles, tableStyles, numberingStyles);
}
function readStyleElement(styleElement) {
  var type = styleElement.attributes["w:type"];
  var styleId = styleElement.attributes["w:styleId"];
  var name = styleName(styleElement);
  return {
    type: type,
    styleId: styleId,
    name: name
  };
}
function styleName(styleElement) {
  var nameElement = styleElement.first("w:name");
  return nameElement ? nameElement.attributes["w:val"] : null;
}
function readNumberingStyleElement(styleElement) {
  var numId = styleElement.firstOrEmpty("w:pPr").firstOrEmpty("w:numPr").firstOrEmpty("w:numId").attributes["w:val"];
  return {
    numId: numId
  };
}