"use strict";

var es5 = require("./es5");
var canEvaluate = typeof navigator == "undefined";
var errorObj = {
  e: {}
};
var tryCatchTarget;
var globalObject = typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : this !== undefined ? this : null;
function tryCatcher() {
  try {
    var target = tryCatchTarget;
    tryCatchTarget = null;
    return target.apply(this, arguments);
  } catch (e) {
    errorObj.e = e;
    return errorObj;
  }
}
function tryCatch(fn) {
  tryCatchTarget = fn;
  return tryCatcher;
}
var inherits = function (Child, Parent) {
  var hasProp = {}.hasOwnProperty;
  function T() {
    this.constructor = Child;
    this.constructor$ = Parent;
    for (var propertyName in Parent.prototype) {
      if (hasProp.call(Parent.prototype, propertyName) && propertyName.charAt(propertyName.length - 1) !== "$") {
        this[propertyName + "$"] = Parent.prototype[propertyName];
      }
    }
  }
  T.prototype = Parent.prototype;
  Child.prototype = new T();
  return Child.prototype;
};
function isPrimitive(val) {
  return val == null || val === true || val === false || typeof val === "string" || typeof val === "number";
}
function isObject(value) {
  return typeof value === "function" || typeof value === "object" && value !== null;
}
function maybeWrapAsError(maybeError) {
  if (!isPrimitive(maybeError)) return maybeError;
  return new Error(safeToString(maybeError));
}
function withAppended(target, appendee) {
  var len = target.length;
  var ret = new Array(len + 1);
  var i;
  for (i = 0; i < len; ++i) {
    ret[i] = target[i];
  }
  ret[i] = appendee;
  return ret;
}
function getDataPropertyOrDefault(obj, key, defaultValue) {
  if (es5.isES5) {
    var desc = Object.getOwnPropertyDescriptor(obj, key);
    if (desc != null) {
      return desc.get == null && desc.set == null ? desc.value : defaultValue;
    }
  } else {
    return {}.hasOwnProperty.call(obj, key) ? obj[key] : undefined;
  }
}
function notEnumerableProp(obj, name, value) {
  if (isPrimitive(obj)) return obj;
  var descriptor = {
    value: value,
    configurable: true,
    enumerable: false,
    writable: true
  };
  es5.defineProperty(obj, name, descriptor);
  return obj;
}
function thrower(r) {
  throw r;
}
var inheritedDataKeys = function () {
  var excludedPrototypes = [Array.prototype, Object.prototype, Function.prototype];
  var isExcludedProto = function (val) {
    for (var i = 0; i < excludedPrototypes.length; ++i) {
      if (excludedPrototypes[i] === val) {
        return true;
      }
    }
    return false;
  };
  if (es5.isES5) {
    var getKeys = Object.getOwnPropertyNames;
    return function (obj) {
      var ret = [];
      var visitedKeys = Object.create(null);
      while (obj != null && !isExcludedProto(obj)) {
        var keys;
        try {
          keys = getKeys(obj);
        } catch (e) {
          return ret;
        }
        for (var i = 0; i < keys.length; ++i) {
          var key = keys[i];
          if (visitedKeys[key]) continue;
          visitedKeys[key] = true;
          var desc = Object.getOwnPropertyDescriptor(obj, key);
          if (desc != null && desc.get == null && desc.set == null) {
            ret.push(key);
          }
        }
        obj = es5.getPrototypeOf(obj);
      }
      return ret;
    };
  } else {
    var hasProp = {}.hasOwnProperty;
    return function (obj) {
      if (isExcludedProto(obj)) return [];
      var ret = [];

      /*jshint forin:false */
      enumeration: for (var key in obj) {
        if (hasProp.call(obj, key)) {
          ret.push(key);
        } else {
          for (var i = 0; i < excludedPrototypes.length; ++i) {
            if (hasProp.call(excludedPrototypes[i], key)) {
              continue enumeration;
            }
          }
          ret.push(key);
        }
      }
      return ret;
    };
  }
}();
var thisAssignmentPattern = /this\s*\.\s*\S+\s*=/;
function isClass(fn) {
  try {
    if (typeof fn === "function") {
      var keys = es5.names(fn.prototype);
      var hasMethods = es5.isES5 && keys.length > 1;
      var hasMethodsOtherThanConstructor = keys.length > 0 && !(keys.length === 1 && keys[0] === "constructor");
      var hasThisAssignmentAndStaticMethods = thisAssignmentPattern.test(fn + "") && es5.names(fn).length > 0;
      if (hasMethods || hasMethodsOtherThanConstructor || hasThisAssignmentAndStaticMethods) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}
function toFastProperties(obj) {
  /*jshint -W027,-W055,-W031*/
  function FakeConstructor() {}
  FakeConstructor.prototype = obj;
  var l = 8;
  while (l--) new FakeConstructor();
  return obj;
  eval(obj);
}
var rident = /^[a-z$_][a-z$_0-9]*$/i;
function isIdentifier(str) {
  return rident.test(str);
}
function filledRange(count, prefix, suffix) {
  var ret = new Array(count);
  for (var i = 0; i < count; ++i) {
    ret[i] = prefix + i + suffix;
  }
  return ret;
}
function safeToString(obj) {
  try {
    return obj + "";
  } catch (e) {
    return "[no string representation]";
  }
}
function isError(obj) {
  return obj !== null && typeof obj === "object" && typeof obj.message === "string" && typeof obj.name === "string";
}
function markAsOriginatingFromRejection(e) {
  try {
    notEnumerableProp(e, "isOperational", true);
  } catch (ignore) {}
}
function originatesFromRejection(e) {
  if (e == null) return false;
  return e instanceof Error["__BluebirdErrorTypes__"].OperationalError || e["isOperational"] === true;
}
function canAttachTrace(obj) {
  return isError(obj) && es5.propertyIsWritable(obj, "stack");
}
var ensureErrorObject = function () {
  if (!("stack" in new Error())) {
    return function (value) {
      if (canAttachTrace(value)) return value;
      try {
        throw new Error(safeToString(value));
      } catch (err) {
        return err;
      }
    };
  } else {
    return function (value) {
      if (canAttachTrace(value)) return value;
      return new Error(safeToString(value));
    };
  }
}();
function classString(obj) {
  return {}.toString.call(obj);
}
function copyDescriptors(from, to, filter) {
  var keys = es5.names(from);
  for (var i = 0; i < keys.length; ++i) {
    var key = keys[i];
    if (filter(key)) {
      try {
        es5.defineProperty(to, key, es5.getDescriptor(from, key));
      } catch (ignore) {}
    }
  }
}
var asArray = function (v) {
  if (es5.isArray(v)) {
    return v;
  }
  return null;
};
if (typeof Symbol !== "undefined" && Symbol.iterator) {
  var ArrayFrom = typeof Array.from === "function" ? function (v) {
    return Array.from(v);
  } : function (v) {
    var ret = [];
    var it = v[Symbol.iterator]();
    var itResult;
    while (!(itResult = it.next()).done) {
      ret.push(itResult.value);
    }
    return ret;
  };
  asArray = function (v) {
    if (es5.isArray(v)) {
      return v;
    } else if (v != null && typeof v[Symbol.iterator] === "function") {
      return ArrayFrom(v);
    }
    return null;
  };
}
var isNode = typeof process !== "undefined" && classString(process).toLowerCase() === "[object process]";
var hasEnvVariables = typeof process !== "undefined" && typeof process.env !== "undefined";
function env(key) {
  return hasEnvVariables ? process.env[key] : undefined;
}
function getNativePromise() {
  if (typeof Promise === "function") {
    try {
      var promise = new Promise(function () {});
      if ({}.toString.call(promise) === "[object Promise]") {
        return Promise;
      }
    } catch (e) {}
  }
}
function domainBind(self, cb) {
  return self.bind(cb);
}
var ret = {
  isClass: isClass,
  isIdentifier: isIdentifier,
  inheritedDataKeys: inheritedDataKeys,
  getDataPropertyOrDefault: getDataPropertyOrDefault,
  thrower: thrower,
  isArray: es5.isArray,
  asArray: asArray,
  notEnumerableProp: notEnumerableProp,
  isPrimitive: isPrimitive,
  isObject: isObject,
  isError: isError,
  canEvaluate: canEvaluate,
  errorObj: errorObj,
  tryCatch: tryCatch,
  inherits: inherits,
  withAppended: withAppended,
  maybeWrapAsError: maybeWrapAsError,
  toFastProperties: toFastProperties,
  filledRange: filledRange,
  toString: safeToString,
  canAttachTrace: canAttachTrace,
  ensureErrorObject: ensureErrorObject,
  originatesFromRejection: originatesFromRejection,
  markAsOriginatingFromRejection: markAsOriginatingFromRejection,
  classString: classString,
  copyDescriptors: copyDescriptors,
  hasDevTools: typeof chrome !== "undefined" && chrome && typeof chrome.loadTimes === "function",
  isNode: isNode,
  hasEnvVariables: hasEnvVariables,
  env: env,
  global: globalObject,
  getNativePromise: getNativePromise,
  domainBind: domainBind
};
ret.isRecentNode = ret.isNode && function () {
  var version = process.versions.node.split(".").map(Number);
  return version[0] === 0 && version[1] > 10 || version[0] > 0;
}();
if (ret.isNode) ret.toFastProperties(process);
try {
  throw new Error();
} catch (e) {
  ret.lastLineError = e;
}
module.exports = ret;