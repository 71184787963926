import {Renderer2} from "@angular/core";

export const updateCanonicalURL = (url: string, document: Document, renderer: Renderer2) => {
  const canonicalURL = url;
  const head = document.getElementsByTagName('head')[0];

  // Remove any existing canonical tags to avoid duplication
  const existingLink: HTMLLinkElement | null = head.querySelector(`link[rel='canonical']`);
  if (existingLink) {
    renderer.removeChild(head, existingLink);
  }

  // Create the new canonical tag
  const link: HTMLLinkElement = renderer.createElement('link');
  renderer.setAttribute(link, 'rel', 'canonical');
  renderer.setAttribute(link, 'href', canonicalURL);

  // Append the new canonical tag to the head
  renderer.appendChild(head, link);
}
