var _ = require("underscore");
var promises = require("./promises");
var Html = require("./html");
exports.imgElement = imgElement;
function imgElement(func) {
  return function (element, messages) {
    return promises.when(func(element)).then(function (result) {
      var attributes = {};
      if (element.altText) {
        attributes.alt = element.altText;
      }
      _.extend(attributes, result);
      return [Html.freshElement("img", attributes)];
    });
  };
}

// Undocumented, but retained for backwards-compatibility with 0.3.x
exports.inline = exports.imgElement;
exports.dataUri = imgElement(function (element) {
  return element.readAsBase64String().then(function (imageBuffer) {
    return {
      src: "data:" + element.contentType + ";base64," + imageBuffer
    };
  });
});