"use strict";

var es5 = require("./es5");
var Objectfreeze = es5.freeze;
var util = require("./util");
var inherits = util.inherits;
var notEnumerableProp = util.notEnumerableProp;
function subError(nameProperty, defaultMessage) {
  function SubError(message) {
    if (!(this instanceof SubError)) return new SubError(message);
    notEnumerableProp(this, "message", typeof message === "string" ? message : defaultMessage);
    notEnumerableProp(this, "name", nameProperty);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      Error.call(this);
    }
  }
  inherits(SubError, Error);
  return SubError;
}
var _TypeError, _RangeError;
var Warning = subError("Warning", "warning");
var CancellationError = subError("CancellationError", "cancellation error");
var TimeoutError = subError("TimeoutError", "timeout error");
var AggregateError = subError("AggregateError", "aggregate error");
try {
  _TypeError = TypeError;
  _RangeError = RangeError;
} catch (e) {
  _TypeError = subError("TypeError", "type error");
  _RangeError = subError("RangeError", "range error");
}
var methods = ("join pop push shift unshift slice filter forEach some " + "every map indexOf lastIndexOf reduce reduceRight sort reverse").split(" ");
for (var i = 0; i < methods.length; ++i) {
  if (typeof Array.prototype[methods[i]] === "function") {
    AggregateError.prototype[methods[i]] = Array.prototype[methods[i]];
  }
}
es5.defineProperty(AggregateError.prototype, "length", {
  value: 0,
  configurable: false,
  writable: true,
  enumerable: true
});
AggregateError.prototype["isOperational"] = true;
var level = 0;
AggregateError.prototype.toString = function () {
  var indent = Array(level * 4 + 1).join(" ");
  var ret = "\n" + indent + "AggregateError of:" + "\n";
  level++;
  indent = Array(level * 4 + 1).join(" ");
  for (var i = 0; i < this.length; ++i) {
    var str = this[i] === this ? "[Circular AggregateError]" : this[i] + "";
    var lines = str.split("\n");
    for (var j = 0; j < lines.length; ++j) {
      lines[j] = indent + lines[j];
    }
    str = lines.join("\n");
    ret += str + "\n";
  }
  level--;
  return ret;
};
function OperationalError(message) {
  if (!(this instanceof OperationalError)) return new OperationalError(message);
  notEnumerableProp(this, "name", "OperationalError");
  notEnumerableProp(this, "message", message);
  this.cause = message;
  this["isOperational"] = true;
  if (message instanceof Error) {
    notEnumerableProp(this, "message", message.message);
    notEnumerableProp(this, "stack", message.stack);
  } else if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
}
inherits(OperationalError, Error);
var errorTypes = Error["__BluebirdErrorTypes__"];
if (!errorTypes) {
  errorTypes = Objectfreeze({
    CancellationError: CancellationError,
    TimeoutError: TimeoutError,
    OperationalError: OperationalError,
    RejectionError: OperationalError,
    AggregateError: AggregateError
  });
  es5.defineProperty(Error, "__BluebirdErrorTypes__", {
    value: errorTypes,
    writable: false,
    enumerable: false,
    configurable: false
  });
}
module.exports = {
  Error: Error,
  TypeError: _TypeError,
  RangeError: _RangeError,
  CancellationError: errorTypes.CancellationError,
  OperationalError: errorTypes.OperationalError,
  TimeoutError: errorTypes.TimeoutError,
  AggregateError: errorTypes.AggregateError,
  Warning: Warning
};