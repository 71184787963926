import {environment} from '@env';
import {Params} from '@angular/router';
import mixpanel from 'mixpanel-browser';
import {Injectable} from '@angular/core';
import {User} from "@app/shared/interfaces";

declare let gtag: Function;

// declare let fbq: Function;
// declare let twq: Function;
// declare let _cio: Params;
// declare let Appcues: Params;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {
  }

  public track(event: string, params: Params = {}): void {
    this.trackMixpanel(event, params);
    this.trackGoogle(event, params);
  }

  public trackMixpanel(eventName: string, params: Params = {}): void {
    if (environment.env === 'production' && eventName) {
      mixpanel.track(eventName, {...params});
    }
  }

  public identifyMixpanelUser(user: User) {
    if (environment.env === 'production') {
      mixpanel.identify(user.id)

      mixpanel.people.set({
        '$name': user.fullName,
        '$email': user.email,
        'plan': user.plan,
        'passwordLess': user.passwordLess,
        'USER_ID': user.id,
        'Is Email Verified': user.emailVerified
        // Add anything else about the user here
      });
    }
  }

  public trackGoogle(eventName: string, params: Params = {}): void {
    if (environment.env === 'production' && eventName) {
      gtag('event', eventName, {eventValue: params});
    }
  }

  /*public track(event: string, params: Params = {}): void {
    // Adding timeout so that events go in the right order
    setTimeout(() => {
      this.trackGoogle(event, params);
      this.trackMixpanel(event, params);
      this.trackCustomerIO(event, params);
      this.trackAppcues(event, params);
    }, 200);
  }

  public trackFacebook(eventName: string, params: Params = {}): void {
    // console.log('facebook ********************************************* -> ', eventName, params);
    if (environment.production && eventName && fbq) {
      fbq('trackCustom', eventName, {...params});
    }
  }

  public trackTwitter(eventName: string): void {
    // console.log('twitter ********************************************* -> ', eventName);
    if (environment.production && eventName && twq) {
      twq('track', eventName);
    }
  }

  public trackLinkedinConversion(conversionId: number): void {
    // console.log('linkedin ********************************************* -> ', conversionId);
    if (environment.production && conversionId && window.lintrk) {
      window.lintrk('track', {conversion_id: conversionId});
    }
  }

  public trackRedditConversion(conversionId: string): void {
    console.log('reddit ********************************************* -> ', conversionId);
    if (environment.production && conversionId && window.rdt) {
      window.rdt('track', conversionId);
    }
  }

  public trackGoogleAdsConversion(conversionId: string): void {
    // console.log('Google ********************************************* -> ', conversionId);
    if (environment.production && conversionId && gtag) {
      gtag('event', 'conversion', {'send_to': conversionId});
    }
  }

  public trackCustomerIO(eventName: string, params: Params = {}): void {
    // console.log('customer.io ********************************************* -> ', eventName, params);
    if (environment.production && eventName && _cio.track) {
      _cio.track(this.toSnakeCase(eventName), this.jsonKeysToSnakeCase({...params}));
    }
  }

  public trackAppcues(eventName: string, params: Params = {}): void {
    // console.log('appcues ********************************************* -> ', eventName, params);
    if (environment.production && eventName && Appcues.track) {
      Appcues.track(eventName, {...params});
    }
  }

  public registerMixpanelProperties(params: Params = {}): void {
    if (environment.production) {
      mixpanel.register({...params});
    }
  }

  public identifyCustomerIOUser(userId: string, name: string, email: string, createdAt: string, isEmailVerified: boolean) {
    if (environment.production && userId) {

      const unixTime = dateToTimestamp(createdAt);

      if (!_cio.identify) {
        return;
      }

      const userData = {
        // Required attributes
        id: userId,
        email,
        created_at: unixTime,
        name,
        is_email_verified: isEmailVerified
      };

      const utm_source = getCookie('utm_source');
      const utm_medium = getCookie('utm_medium');
      const utm_campaign = getCookie('utm_campaign');
      const utm_term = getCookie('utm_term');
      const utm_content = getCookie('utm_content');

      if(utm_source && utm_medium && utm_campaign) {
        userData['utm_source'] = utm_source;
        userData['utm_medium'] = utm_medium;
        userData['utm_campaign'] = utm_campaign;
        userData['utm_term'] = utm_term;
        userData['utm_content'] = utm_content;
      }

      _cio.identify(userData);
    }
  }

  public identifyAppcuesUser(userId: string, name: string, email: string, createdAt: string, isEmailVerified: boolean): void {
    if (environment.production && userId) {

      const unixTime = dateToTimestamp(createdAt);

      if (!Appcues.identify) {
        return;
      }

      Appcues.identify(
        userId,
        {
          createdAt: unixTime, // Unix timestamp of user signup date
          accountId: userId,
          firstName: name,
          email,
          isEmailVerified
        }
      );

    }
  }

  public setMixpanelUserProperties(params: Params = {}): void {
    if (environment.production && mixpanel) {
      mixpanel.people.set({...params});
    }
  }

  public setCustomerIOUserProperties(userId: string, params: Params = {}): void {
    if (environment.production && _cio.identify) {
      const props = this.jsonKeysToSnakeCase({...params});
      props.id = userId;

      _cio.identify(props);
    }
  }

  private toSnakeCase(value: string): string {
    return value.split(' ').join('_').toLowerCase();
  }

  /!** This is a simple/one level solution *!/
  private jsonKeysToSnakeCase(value: any): any {
    const newValue = {};
    Object.keys(value).forEach((key: string) => {
      newValue[this.toSnakeCase(key)] = value[key];
    });
    return newValue;
  }*/

}
