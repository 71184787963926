import {Pipe, PipeTransform} from '@angular/core';
import {TemplateSectionTypes} from "@app/shared/enums";
import {TemplatePage, TemplateSection, TemplateSectionItem, TemplateSettingsThemeColor} from "@app/shared/interfaces";

interface DefaultItem {
  keyName: string; name: string;
}

interface Defaults {
 [TemplateSectionTypes.WORK_EXPERIENCE]: DefaultItem[];
 [TemplateSectionTypes.EDUCATION]: DefaultItem[];
 [TemplateSectionTypes.TEACHING]: DefaultItem[];
 [TemplateSectionTypes.VOLUNTEER]: DefaultItem[];
 [TemplateSectionTypes.AWARDS]: DefaultItem[];
 [TemplateSectionTypes.CONFERENCES_AND_COURSES]: DefaultItem[];
 [TemplateSectionTypes.ACHIEVEMENTS]: DefaultItem[];
 [TemplateSectionTypes.ORGANIZATIONS]: DefaultItem[];
 // [TemplateSectionTypes.REFERENCES]: DefaultItem[];
 [TemplateSectionTypes.CERTIFICATES]: DefaultItem[];
}

@Pipe({
  name: 'tmThemeType',
  standalone: true
})
export class TmThemeTypePipe implements PipeTransform {
  transform(theme: TemplateSettingsThemeColor) {
    return theme;
  }
}

@Pipe({
  name: 'pageType',
  standalone: true
})
export class PageTypePipe implements PipeTransform {
  transform(page: TemplatePage) {
    return page;
  }
}

@Pipe({
  name: 'sectionType',
  standalone: true
})
export class SectionTypePipe implements PipeTransform {
  transform(section: TemplateSection) {
    return section;
  }
}

@Pipe({
  name: 'workExpSectionSt',
  standalone: true
})
export class WorkExpSectionStPipe implements PipeTransform {

  private defaults: Defaults = {
    [TemplateSectionTypes.WORK_EXPERIENCE]: [
      {keyName: 'title', name: 'Show Title'},
      {keyName: 'subTitle', name: 'Show Company Name'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
      {keyName: 'address', name: 'Show Location'},
      {keyName: 'achievements', name: 'Show Achievements'},
    ],
    [TemplateSectionTypes.EDUCATION]: [
      {keyName: 'title', name: 'Show Study Program'},
      {keyName: 'subTitle', name: 'Show School or University'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
      {keyName: 'address', name: 'Show Location'},
      {keyName: 'achievements', name: 'Show Courses'},
    ],
    [TemplateSectionTypes.TEACHING]: [
      {keyName: 'title', name: 'Show Name of the Class'},
      {keyName: 'subTitle', name: 'Show Place of Education'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
      {keyName: 'address', name: 'Show Location'},
      {keyName: 'achievements', name: 'Show Courses'},
    ],
    [TemplateSectionTypes.VOLUNTEER]: [
      {keyName: 'title', name: 'Show Title'},
      {keyName: 'subTitle', name: 'Show Organization'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
      {keyName: 'address', name: 'Show Location'},
      {keyName: 'achievements', name: 'Show Achievements'},
    ],
    [TemplateSectionTypes.AWARDS]: [
      {keyName: 'title', name: 'Show Award Name'},
      {keyName: 'subTitle', name: 'Show Name of the institution'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
    ],
    [TemplateSectionTypes.CONFERENCES_AND_COURSES]: [
      {keyName: 'title', name: 'Show Course Name'},
      {keyName: 'subTitle', name: 'Show Subtitle'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
      {keyName: 'achievements', name: 'Show Achievements'},
    ],
    [TemplateSectionTypes.ACHIEVEMENTS]: [
      {keyName: 'title', name: 'Show Achievement Name'},
      {keyName: 'subTitle', name: 'Show Subtitle'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
    ],
    [TemplateSectionTypes.ORGANIZATIONS]: [
      {keyName: 'title', name: 'Show Organization Name'},
      {keyName: 'subTitle', name: 'Show Organization Subtitle'},
      {keyName: 'desc', name: 'Show Role'},
      {keyName: 'date', name: 'Show Period'},
    ],
    /*[TemplateSectionTypes.REFERENCES]: [
      {keyName: 'title', name: 'Show Name of Position'},
      {keyName: 'desc', name: 'Show Reference'},
      {keyName: 'email', name: 'Show Email'},
      {keyName: 'phone', name: 'Show Phone'},
    ],*/
    [TemplateSectionTypes.CERTIFICATES]: [
      {keyName: 'title', name: 'Show Certificate Name'},
      {keyName: 'subTitle', name: 'Show Subtitle'},
      {keyName: 'desc', name: 'Show Description'},
      {keyName: 'date', name: 'Show Period'},
    ],
  }

  transform(workExp: TemplateSectionItem) {
    console.log('workExp', workExp);
    return this.defaults[workExp.type as keyof Defaults];
  }

}
